import React, { useState, useContext, useEffect } from "react";
import Datatable from "../../components/Table/Datatable";
import UserModelForm from "./UserModelForm";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { PageLoader } from '../../Context/PageLoader';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { confirmAlert } from 'react-confirm-alert';
import { get, remove, put } from '../../api'
import { GET_USERS_LIST, DELETE_USER, RESET_PASSWORD, USER_STATUS, GET_MANAGER_LIST, SEND_INVITATION } from '../../api/endpoints'
import { checkPermission, concatUrlWithId } from '../../utils/commonUtils';
import { UserPermissionStore } from '../../Store/UserPermission';

export default function UserListComponent({ managerId = "" }) {

    const history = useNavigate();
    const location = useLocation();
    const { loading, setLoading } = useContext(PageLoader);
    const [UserList, setUserList] = useState('');
    /* Upload Modal  */
    const [show, setShow] = useState(false);
    const [mode, setMode] = useState('add');
    const [editId, setEditId] = useState('add');
    const [userPermissions, setUserPermissions] = useState([]);
    const { checkAndUpdatePermissions } = UserPermissionStore();
    const [isInviteManager, SetIsInviteManager] = useState(false);
    const [isManagerList, setIsManagerList] = useState(false);

    const handleClose = () => {
        fetchUserList();
        setShow(false);
    }
    const handleShow = (type, isManager) => {
        SetIsInviteManager(isManager);
        setShow(true);
        setMode(type)
    }
    const handleAdd = (isManagerInvite) => {
        handleShow(mode, isManagerInvite);
    }

    const handleEdit = (id) => {
        history(`/user-info/${id}`);
    }

    const onUploadSuccess = () => {
        SetIsInviteManager(false)
        handleClose()
        fetchUserList();
    }
    const notify = (message, type) => toast(message, { type });

    const fetchUserList = async (tempUserPerm = []) => {
        try {
            let userPerm = userPermissions.length ? userPermissions : tempUserPerm;
            let tempIsManagerView = (checkPermission(userPerm, 'isAdmin') || (checkPermission(userPerm, 'isManager') && checkPermission(userPerm, 'show-manager-list'))) && !managerId;
            let fetchURL = tempIsManagerView ? GET_MANAGER_LIST : GET_USERS_LIST
            setIsManagerList(tempIsManagerView);
            const usersList =  managerId ? await get(fetchURL, { managerId: managerId }) : await get(fetchURL);
            if (usersList) {
                setUserList(usersList);
            }
        } catch (error) {
            console.log('Error while throughing:: ', error);
        } finally {
            setLoading(false);
        }
    }

    const handleOnClickAction = (type, id, name, status) => {

        switch (type) {
            case 'view':
                handleViewClick(id,);
                break;
            case 'edit':
                handleEdit(id);
                break;
            case 'delete':
                deleteUser(id, name);
                break;
            case 'resetPassword':
                reSetUserPassword(id);
                break;
            case 'sendInvite':
                handleSendInvitation(id);
                break;
            case 'statusUpdate':
                updateStatus(id, name, status);
                break;
            default:
                break;
        }
    }

    const handleViewClick = (userId) => {
        history(`/user-info/${userId}`);
    };

    const authCheckAndUpdate = async () => {
        let tempPermission = await checkAndUpdatePermissions();
        setUserPermissions(tempPermission)
        if (checkPermission(tempPermission, 'isAdmin') || checkPermission(tempPermission, 'isManager')) {
            fetchUserList(tempPermission);
        } else {
            history('/portfolio-summary')
        }
    };

    const handleDeleteUser = async (userID, investment_name) => {
        try {
            setLoading(true)
            const deleteUser = await remove(concatUrlWithId(DELETE_USER, userID));
            fetchUserList();
            setLoading(false)
            notify(`successfully deleted`, 'success')
        } catch (error) {
            console.log('Error while deleting the error : ', error)
        }
    };

    const handleUserStatus = async (userID, user_name) => {
        try {
            setLoading(true)
            const userStatus = await put(concatUrlWithId(USER_STATUS, userID));
            fetchUserList();
            setLoading(false)
            notify(`${user_name} ${userStatus.message}`, 'success')
        } catch (error) {
            console.log('Error while deleting the error : ', error)
        }

    };

    const handleInvitation = async (userID, type) => {
        try {
            setLoading(true)
            let tempURL,tempMessage; 
            if(type === 'invite'){
                tempURL = SEND_INVITATION;
                tempMessage = 'The invitation link has been sent successfully.' ; 
            }else{
                tempURL = RESET_PASSWORD;
                tempMessage = 'The password reset link has been sent successfully.' ; 
            }
            const sendInvitation = await get(concatUrlWithId(tempURL, userID));
            // fetchUserList();
            setLoading(false)
            notify(tempMessage, 'success')
        } catch (error) {
            console.log('Error while deleting the error : ', error)
            setLoading(false)
            notify('Something went wrong!', 'warning')
        }

    };

    const deleteUser = (investment_id, investment_name) => {
        confirmAlert({
            title: 'Delete user',
            message: `Are you sure you want to delete the ${investment_name}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleDeleteUser(investment_id, investment_name)
                },
                {
                    label: 'No',
                }
            ]
        });
    }
    const updateStatus = (investment_id, investment_name, status) => {
        confirmAlert({
            title: 'User Status',
            message: `Are you sure you want to ${status == true ? 'deactivate' : 'activate'} the  ${investment_name}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleUserStatus(investment_id, investment_name)
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    const handleSendInvitation = (user_id) => {
        confirmAlert({
            title: 'Resend Invitation',
            message: `Are you sure you want to resend the invitation?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleInvitation(user_id, 'invite')
                },
                {
                    label: 'No',
                }
            ]
        });
    };
    const reSetUserPassword = (user_id) => {
        confirmAlert({
            title: 'Reset Password',
            message: `Are you sure you want to reset the password ?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleInvitation(user_id, 'reset')
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    useEffect(() => {
        setLoading(true)
        // window.scrollTo(0, 0);
        authCheckAndUpdate();
    }, [location.state]);

    const columns = [
        { dataField: 'id', text: 'ID', csvExport: false, hidden: true },
        {
            dataField: 'username', text: 'User Name', sort: true, isViewField: true, handleOnClick: handleOnClickAction, onClickParams: ['id', 'type'],
            headerWidth: '160px', url: '/user-info', action: true
        },
        { dataField: 'email', text: 'Email', sort: true },
        // { dataField: 'manager', text: 'Role', sort: true, formatter: (cell) => cell ? 'User' : 'Manager', hidden: !checkPermission(userPermissions, 'isAdmin') },
        // { dataField: 'manager_name', text: 'Manager Name', sort: true, hidden: !checkPermission(userPermissions, 'isAdmin') },
        { dataField: 'phone', text: 'Phone No', sort: true },
        { dataField: 'createdAt', text: 'Created On', isDateAndTimeField: true, sort: true },
        { dataField: 'last_login_time', text: 'Last Login', isDateAndTimeField: true, sort: true },
        { dataField: 'status', text: 'Status', sort: true, isStatusField: true },
    ];

    let tempAction = { dataField: 'Action', text: 'Action', csvExport: false, standardAction: true, tooltipSuffixText: "User", handleOnClick: handleOnClickAction, onClickParams: ['id', 'username', 'status'], disableArchive: true, disableDelete: true, statusAction: true, resetPassword: true, sendInvite: true };

    columns.push(tempAction);

    return (
        <div className="main-content introduction-farm">
            <div className="content-wraper-area">
                <div className="dashboard-area">
                    <div className="container-fluid">
                        <div className="row" style={{ alignItems: 'flex-end', alignItems: 'center' }} >
                        </div>
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title" style={{ marginBottom: '5px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <h4 style={{ flex: 'auto' }}>{isManagerList ? 'Subscribers' : 'User List' }</h4>
                                            <button type="button" onClick={() => handleAdd(false)} className="btn btn-primary mb-2 me-2"><i className="icon_plus"></i> {isManagerList ? 'Invite Subscriber' : 'Add User'}</button>
                                        </div>
                                    </div>
                                    <div >
                                        <UserModelForm show={show} mode={mode} editId={editId} handleClose={handleClose} onSuccess={onUploadSuccess} isInviteManager={isManagerList} managerID={managerId} headerName={'User'} />
                                        {!loading && <Datatable data={UserList || []} columns={columns} csvFileName={'User'} isAdminOrManager={true} hideStandardAction={true} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}