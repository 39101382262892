import React, { useState, useEffect, useContext } from 'react';
import { PageLoader } from '../Context/PageLoader'; 
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ReactComponent as LOGO } from './WMS.svg';
import logo from './WMS.png';
import { authVerify } from '../api';
import { LOGIN_USER } from '../api/endpoints';
import { UserPermissionStore } from '../Store/UserPermission';

function Login() {

  const [Invalidpass, setInvalidpass] = useState('');
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
  const { loading, setLoading } = useContext(PageLoader);
  const { checkAndUpdatePermissions } = UserPermissionStore();
  const history = useNavigate();
useEffect(()=>{
  setLoading(false)
  const token = localStorage.getItem('authToken')
  if(token){
    history('/portfolio-summary');
  }
 
},[])

const handelClick = () => {
  history('/forget-password');
}

  return (<div className="main-content- h-100vh">
    <Formik
      initialValues={{ username: '', password: '' }}
      validate={(values) => {
        const errors = {};
        if (!values.username) {
          errors.username = 'Username is required!';
        } 
        if (!values.password) {
          errors.password = 'Password is required!';
        }

        return errors;
      }}
      onSubmit={async (values) => {
        setIsSubmitButtonDisabled(true);
        setLoading(true);
        await new Promise((r) => setTimeout(r, 500));
        try {
          // setLoading(true)
          // Your form submission logic
          const response = await authVerify(LOGIN_USER, {
            username: values.username,
            password: values.password,
          });
        //  setLoading(false)
          if (response.status === 200) {
            const authToken = {
              token: response.data.data.token,
              refreshToken: response.data.data.refreshToken,
            };
            localStorage.setItem('authToken', JSON.stringify(authToken));
            localStorage.setItem('profileImageUrl', JSON.stringify(response.data?.data?.valid_user?.profileImagePath || ''));
            await  checkAndUpdatePermissions();
            history('/portfolio-summary');
          } else {
           setInvalidpass(response.response.data.error);
          }
          setIsSubmitButtonDisabled(false);
          setLoading(false);
        } catch (error) {
          setIsSubmitButtonDisabled(false);
          setLoading(false);

          setInvalidpass('Invalid Username or Password');
          console.error('API Error:', error);
        }
      }}
    >
      <div className="container h-100">
        <div className="row h-100 align-items-center justify-content-center">
          <div className="col-sm-10 col-md-7 col-lg-5">
            <div className="middle-box">
              <div className="card-body">
                <div className="log-header-area card p-4 mb-4 text-center">
                  {/* <LOGO /> */}
                  <img src={logo} className="App-logo" alt="logo" />
                </div>

                <div className="card">
                  <div className="card-body p-4">
                    <Form>

                      <div className="mb-3">
                        <label className="form-label" htmlFor="formrow-stock-code-input">Username</label>
                        <Field className="form-control" type="text" id="formrow-stock-code-input" name="username" />
                        <ErrorMessage className="text-danger" name="username" component="div" />
                      </div>

                      <div className="mb-3">
                        <label className="form-label" htmlFor="formrow-stock-code-input">Password</label>
                        <Field className="form-control" type="password" id="formrow-stock-code-input" name="password" />
                        <ErrorMessage className="text-danger" name="password" component="div" />
                      </div>

                      {/** Display custom error message */}
                      <div className="form-group mb-3">
                        <div className="text-danger">
                          {Invalidpass}
                          <ErrorMessage className="text-danger" name="status" />
                        </div>
                      </div>

                      <div className="form-group mb-3">
                        <button className="btn btn-primary btn-lg w-100" type="submit" disabled={isSubmitButtonDisabled}>
                          Sign In
                        </button>
                      </div>
                    </Form>
                    <a href='#' onClick={handelClick}>Forget Password</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Formik>
  </div>
  )
}
export default Login;