import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { get, post } from '../api';
import { GET_REMINDER_LIST, UPDATE_REMINDER_STATUS } from '../api/endpoints';
import { TitleTrim, checkPermission, notify } from '../utils/commonUtils';
import moment from 'moment/moment';
import { DocumentTypeStore, UserPermissionStore } from '../Store/UserPermission';
import ReminderTaskModel from './Reminder/ReminderTaskModel';

const Notifications = ({ props, handlePopup, reminderState }) => {

    const [reminderList, setReminderList] = useState([]);
    const history = useNavigate();
    const { checkAndUpdateDocumentTypes } = DocumentTypeStore();
    const [documentOptions, setDocumentOptions] = useState([]);
    const [userPermissions, setUserPermissions] = useState([]);
    const { checkAndUpdatePermissions } = UserPermissionStore();
    const [showReminderTask, setShowReminderTask] = useState(false);
    const [entity_type, setEntityType] = useState('');
    const [investment_cat_id, setCategoryID] = useState('');


    const real_estate_master = process.env.REACT_APP_INVESTMENT_REAL_ESTATE_MASTER_ID;
    const stock_master = process.env.REACT_APP_INVESTMENT_STOCK_MASTER_ID;
    const mineral_asset_master = process.env.REACT_APP_INVESTMENT_MINERAL_ASSET_MASTER_ID;
    const personal_asset_master = process.env.REACT_APP_PERSONAL_ASSETS_MASTER_ID;
    const real_estate_holding_master = process.env.REACT_APP_REAL_ESTATE_HOLDINGS_DETAILS_MASTER_ID;

    const authCheckAndUpdate = async () => {
        let tempPermission = await checkAndUpdatePermissions();
        setUserPermissions(tempPermission || []);
    };
    const fetchData = async () => {
        try {
            const notifications = await post(GET_REMINDER_LIST);
            setReminderList(notifications?.data?.reminderList);

        } catch (error) {
            notify('error', error)
        }

    }

    const updateStatus = async (status, id) => {
        try {
            let payload = {
                "id": id,
                "reminder_status": status
            }
            // const notificationStatus = await post(UPDATE_REMINDER_STATUS, payload);

            // if (notificationStatus.status == true) {
            //     // notify('success', notificationStatus.message);
            //     fetchData();

            // }

        } catch (error) {

        }
    }

    const updateDocumentOptions = (returnVal = false) => {
        checkAndUpdateDocumentTypes().then(documentTypesList => {
            const documentKeyValues = documentTypesList;
            setDocumentOptions(documentKeyValues);
            if (returnVal) return documentKeyValues;
        });
    };

    useEffect(() => {
        updateDocumentOptions();
        authCheckAndUpdate();
    }, [])
    useEffect(() => {
        fetchData();
    }, [history]);

    useEffect(() => {
        reminderState(reminderList.length > 0);
    }, [reminderList]);

    // useEffect(() => {
    //     const handleStorageChange = (event) => {
    //         console.log('Coming -- inside the value :: ', event.key)
    //       if (event.key === 'updateNotification') {
    //         fetchData();
    //       }
    //     };

    //     // Add event listener for storage changes
    //     window.addEventListener('storage', handleStorageChange);

    //     // Cleanup event listener on component unmount
    //     return () => {
    //     //   window.removeEventListener('storage', handleStorageChange);
    //     };
    //   }, []);

    const getRecodeDate = (record_id, category_id, entity_type, reminder_id) => {

        switch (category_id) {
            case real_estate_master:
                setCategoryID(category_id);
                setEntityType('');
                setShowReminderTask(true); 
                handlePopup(false);
                break;

            case stock_master:
                setCategoryID(category_id);
                setEntityType('');
                setShowReminderTask(true); 
                handlePopup(false);
                break;

            case mineral_asset_master:
                setCategoryID(category_id);
                setEntityType('');
                setShowReminderTask(true); 
                handlePopup(false);
                break;

            case personal_asset_master:
                setCategoryID(category_id);
                setEntityType('');
                setShowReminderTask(true); 
                handlePopup(false);
                break;

            case real_estate_holding_master:
                setCategoryID(category_id);
                setEntityType('');
                setShowReminderTask(true); 
                handlePopup(false);
                break;

            default:
                if (entity_type == 'operating_business') {
                    setCategoryID('');
                    setEntityType('operating_business');
                    setShowReminderTask(true); 
                    handlePopup(false);
                } else {
                    setCategoryID('');
                    setEntityType('estate_planning');
                    setShowReminderTask(true); 
                    handlePopup(false);
                }
                break;
        }
    }

    const markAsDone = (id) => {
        updateStatus('completed', id);

    };

    const getCategory = (id, type) => {
        switch (id) {
            case real_estate_master:
                return "Private Equity";
            case stock_master:
                return "Liquid Assets";
            case mineral_asset_master:
                return "Royalty Income";
            case personal_asset_master:
                return "Personal Asset";
            case real_estate_holding_master:
                return "Real Estate Holding";
            default:
                if (type == 'operating_business') {
                    return "Operating Business";
                } else if (type == 'estate_planning') {
                    return "Estate Planning";
                }
        }
    };

    const findLabelByValue = (doc_options, doc_value) => {
        const document = doc_options?.length ? doc_options.find(document => document.value === doc_value) : "";
        return document ? document.label : null;
    };

    const getDocumentLabel = (cat_id, doc_id) => {
        let getCategoryKey = getCategory(cat_id);
        let tempDocOptions = []
        if (documentOptions) {
            tempDocOptions = documentOptions;
        } else {
            tempDocOptions = updateDocumentOptions();
        }

        let getlabel = findLabelByValue(tempDocOptions[getCategoryKey] || [], doc_id)
        return getlabel ? ' - ' + getlabel : '';
    }

    const handleClose = () => setShowReminderTask(false);
    const handelListUpdate = () => fetchData(); 


    return (
        <div className="top-notifications-area">
            <div className="notifications-heading">
                <div className="heading-title">
                    <h6>Reminders</h6>
                </div>
                {/* <div className="board-badge">
                    <a href="#">{reminderList.length}</a>
                </div> */}
            </div>
            <hr></hr>


            <div className="notifications-box box-layout" id="notificationsBox">
                {reminderList.map((notification, index) => (

                    <a href="#" className='dropdown-item' style={{ backgroundColor: notification?.reminder_status === 'open' ? 'rgba(166, 175, 192, 0.15)' : 'rgb(6 81 221 / 15%)' }}
                        onClick={() => getRecodeDate(notification.record_id, notification?.investment_cat_id, notification?.entity_type, notification?.id)}
                        key={index}>
                        {notification.reminder_category ? notification.reminder_category == 'record' ? <i className="zmdi zmdi-storage"></i> : <i className="zmdi zmdi-file-text"></i> : ''}
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: '9' }}>

                                <span className='m-8'>{getCategory(notification?.investment_cat_id, notification?.entity_type)}</span>
                                <p>{notification.reminder_category ? notification.reminder_category == 'document' ? 'Document' + getDocumentLabel(notification?.investment_cat_id, notification?.document_type) : '' : ''}</p>
                            </div>
                            <div style={{ flex: '9', margin: 'auto', textAlign: 'right' }} >{notification?.count && notification?.count > 0 ? notification?.count : ''}  </div>
                        </div>
                    </a>
                ))}

            </div>
            <ReminderTaskModel show={showReminderTask} handleClose={handleClose} entityType={entity_type} investmentCatID={investment_cat_id} handelListUpdate={handelListUpdate} />
        </div>
    );
};

export default Notifications;
