import React, { useContext, useEffect, useRef, useState } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { formatDateAndTime, addThousandSeparator, formatCurrency } from "../../utils/commonUtils";
import { ActionIcon } from "../../utils/commonUtils";
import Select from 'react-select';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import { PageLoader } from "../../Context/PageLoader";



const PrintableTable = React.forwardRef(({ data, printColumns, headerName, tableKeyType, showHeaderTotal, headerObjValue }, ref) => (
    <div id={tableKeyType} style={{ padding: '10px', minWidth: '100%', maxWidth: '100%' }} ref={ref} className="print-table-wrapper only-visible-in-print">
        <div className="card-title" style={{ marginBottom: '5px' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <h4 style={{ flex: 'auto' }}>{headerName}</h4>
            </div>
        </div>
        {showHeaderTotal && <div className="row mt-3">
            <div className="col-sm-12 col-md-4"><div className="dt-buttons">
                <span style={{ fontWeight: 'bold', color: '#636161', fontSize: '16px' }} id='net-worth-title'>Total Assets ($): {headerObjValue.totalAssets}</span>
            </div>
            </div>
            <div className="col-sm-12 col-md-4"><div className="dt-buttons">
                <span style={{ fontWeight: 'bold', color: '#636161', fontSize: '16px' }} id='net-worth-title'>Total Liabilities ($): {headerObjValue.totalLiabilities}</span>
            </div>
            </div>
            <div className="col-sm-12 col-md-4"><div className="dt-buttons">
                <span style={{ fontWeight: 'bold', color: '#636161', fontSize: '16px' }} id='net-worth-title'>Net Worth ($): {headerObjValue.totalNetWorth}</span>
            </div>
            </div>
        </div>}
        <div>
            <BootstrapTable
                keyField='id'
                data={data}
                columns={printColumns}
                wrapperClasses={"dataTables_wrapper dt-bootstrap5 no-footer table-responsive"}
                classes={"print-table table-striped table-bordered "}
                noDataIndication={() => (<div style={{ textAlign: 'center', padding: '20px' }}>No data available</div>)}
            />
        </div>

    </div>
));


// Printable component that includes the text and the table
const Datatable = ({ data, columns, tableWidth = "100%", csvFileName, disableSearch, disableCsv, headerName = '', filterOptions = null, hideStandardAction = false,
    handleFilterChange, isAdminOrManager = false, isReminderAdminOrManager = false, actionPermissionPrefix = "", selectedFilter = null, permissionsArr = null,
    hideArchive = false, enablePrint = false, FreezeColumn = false, viewPrintMode = false, showHeaderTotal = false, isReminderList = false,
    headerObjValue = {}, tableKeyType = 'stock', onTableComplete = () => { },
    handleTableChange = () => { } }) => {
    const notify = (message, type) => toast(message, { type });
    const { loading, setLoading } = useContext(PageLoader);

    function priceFormatter(column, colIndex, { sortElement, filterElement }) {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    {filterElement}
                    {column.text}
                </div>
                {sortElement}
            </div>
        );
    }

    const customSortCaret = (order, column) => {
        // if (!order) return (<span>&#8693;</span>); // Use Unicode for double arrow
        // else 
        if (order === 'asc') return (<span>&#8593;</span>); // Unicode for up arrow
        else if (order === 'desc') return (<span>&#8595;</span>); // Unicode for down arrow
        else if (column.order === 'asc') return (<span>&#8593;</span>);
        return null;
    };

    const defaultColumnOptions = {
        // Define default options for columns
        dataField: '',
        text: '',
        sort: false,
        sortCaret: customSortCaret,
        headerFormatter: priceFormatter,
        headerClasses: 'stock-list-header-style',
        headerStyle: {},
        formatter: null,
        csvFormatter: null,
        style: {},
        hidden: false,
        url: '',
    };

    const formatPerformance = (number) => {
        const performanceNumber = parseFloat(number);
        if (!isNaN(performanceNumber)) {
            if (performanceNumber == 0) {
                return <span > {performanceNumber.toFixed(2)} %</span>;
            } else if (performanceNumber > 0.001) {
                return <span className="positive-performance">+{performanceNumber.toFixed(2)} %</span>;
            } else {
                return <span className='text-danger'>{performanceNumber.toFixed(2)} %</span>;
            }
        } else {
            return <span>_ _</span>;
        }
    };

    const formatPerformanceCsv = (number) => {
        const performanceNumber = parseFloat(number);
        return isNaN(performanceNumber) ? '_ _' : `${performanceNumber.toFixed(2)} %`;
    };

    const formatStatus = (status) => {
        if (!isNaN(status)) {
            if (status == 2) {
                return <span className="badge text-bg-danger">Deactivate</span>;
            } else if (status == 1) {
                return <span className="badge text-bg-primary">Active</span>;
            } else if (status == 0) {
                return <span className="badge bg-info ">Invited</span>;
            } else {
                return <span className="badge text-bg-primary">--</span>;
            }
        } else {
            return <span>_ _</span>;
        }
    };


    const formatStatusCsv = (number) => {
        const performanceNumber = parseFloat(number);
        return isNaN(performanceNumber) ? '_ _' : `${performanceNumber.toFixed(2)} %`;
    };

    const [tableColumns, setTableColumns] = useState([]);
    const [seletedFilterValue, setSeletedFilterValue] = useState();
    const [tableLoader, setTableLoader] = useState(true);
    const [printColumns, setPrintColumns] = useState([])
    const [printMode, setPrintMode] = useState(false);
    const printTableRef = useRef();
    const printBtnRef = useRef();


    function getLabelValue(labelFor) {
        try {
            const labelElement = document.querySelector(`label[for="formrow-${labelFor}-input"]`);
            if (labelElement) {
                const labelValue = labelElement.innerText;
                return labelValue
            } else {
                console.error(`Label for "${labelFor}" not found.`);
                return convertToTitleCase(labelFor)
            }
        } catch (error) {
            return convertToTitleCase(labelFor)
        }
    }

    function convertToTitleCase(str) {
        // Split the string into words based on underscores
        let words = str.split('_');

        // Capitalize the first letter of each word and join them back together
        let titleCaseStr = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

        return titleCaseStr;
    }

    const checkActionPermission = (perm, checkValue) => {
        let result = false
        if (isAdminOrManager) {
            result = true;
        } else {
            result = perm?.length ? perm.includes(checkValue) : false;
        }
        return result;
    };



    const handleViewClick = (type, investmentId, categoryId) => {
        const real_estate_master = process.env.REACT_APP_INVESTMENT_REAL_ESTATE_MASTER_ID;
        const stock_master = process.env.REACT_APP_INVESTMENT_STOCK_MASTER_ID;
        const mineral_asset_master = process.env.REACT_APP_INVESTMENT_MINERAL_ASSET_MASTER_ID;
        const personal_asset_master = process.env.REACT_APP_PERSONAL_ASSETS_MASTER_ID;
        const real_estate_holding_master = process.env.REACT_APP_REAL_ESTATE_HOLDINGS_DETAILS_MASTER_ID;
        if (categoryId === real_estate_master) {
            return `/view-private-equity/${investmentId}`;
        } else if (categoryId === stock_master) {
            return `/view-liquid-asset/${investmentId}`;
        } else if (categoryId === mineral_asset_master) {
            return `/view-royalty-income/${investmentId}`;
        } else if (categoryId === personal_asset_master) {
            return `/view-personal-assets/${investmentId}`;
        } else if (categoryId === real_estate_holding_master) {
            return `/view-real-estate-holdings/${investmentId}`;
        } else if (categoryId === 'Operating business') {
            return `/view-operating-business/${investmentId}`;
        } else {
            return '#'; // Fallback URL if no match is found
        }
    };

    useEffect(() => {
        if (enablePrint) {
            if (printBtnRef.current) {
                printBtnRef.current.handlePrint();
                setLoading(false)
            }
        };
    }, [enablePrint])

    useEffect(() => {

        if (columns.length) {
            let tempCol = []
            let tempPrintCol = []
            columns.forEach((col) => {
                let generateColConfig = { ...defaultColumnOptions, ...col };
                let printGenerateColConfig = { ...defaultColumnOptions, ...col };
                // Apply date formatting if the column represents a date field
                if (col.isDateField) {
                    generateColConfig["formatter"] = (cell) => formatDateAndTime(cell, "MM/DD/YYYY");
                    generateColConfig["csvFormatter"] = (cell) => formatDateAndTime(cell, "MM/DD/YYYY");
                    printGenerateColConfig["formatter"] = (cell) => formatDateAndTime(cell, "MM/DD/YYYY");
                    printGenerateColConfig["csvFormatter"] = (cell) => formatDateAndTime(cell, "MM/DD/YYYY");
                }
                // Apply currency formatting if the column represents an amount field
                if (col.isAmountField) {
                    generateColConfig["formatter"] = (cell) => formatCurrency(cell);
                    generateColConfig["csvFormatter"] = (cell) => addThousandSeparator(cell);
                    printGenerateColConfig["formatter"] = (cell) => formatCurrency(cell);
                    printGenerateColConfig["csvFormatter"] = (cell) => addThousandSeparator(cell);
                }
                // Apply performance formatting if the column represents a performance field
                if (col.isPerformanceField) {
                    generateColConfig["formatter"] = (cell) => formatPerformance(cell);
                    generateColConfig["csvFormatter"] = (cell) => formatPerformanceCsv(cell);
                    printGenerateColConfig["formatter"] = (cell) => formatPerformance(cell);
                    printGenerateColConfig["csvFormatter"] = (cell) => formatPerformanceCsv(cell);
                }
                // Apply Status formatting if the column represents a performance field
                if (col.isStatusField) {
                    generateColConfig["formatter"] = (cell) => formatStatus(cell);
                    generateColConfig["csvFormatter"] = (cell) => formatStatusCsv(cell);
                    printGenerateColConfig["formatter"] = (cell) => formatStatus(cell);
                    printGenerateColConfig["csvFormatter"] = (cell) => formatStatusCsv(cell);
                }

                if (col.isDateAndTimeField) {
                    generateColConfig["formatter"] = (cell) => formatDateAndTime(cell, "MM/DD/YYYY hh:mm a");
                    generateColConfig["csvFormatter"] = (cell) => formatDateAndTime(cell, "MM/DD/YYYY hh:mm a");
                    printGenerateColConfig["formatter"] = (cell) => formatDateAndTime(cell, "MM/DD/YYYY hh:mm a");
                    printGenerateColConfig["csvFormatter"] = (cell) => formatDateAndTime(cell, "MM/DD/YYYY hh:mm a");
                }

                if (col.isFieldValue) {
                    generateColConfig["formatter"] = (cell) => getLabelValue(cell);
                    printGenerateColConfig["formatter"] = (cell) => getLabelValue(cell);
                }

                if (col.isUserNameField) {
                    generateColConfig["formatter"] = (cell) => cell.replaceAll('-', " ");
                    printGenerateColConfig["formatter"] = (cell) => cell.replaceAll('-', " ");
                }
                // Set the header width if provided
                if (col.headerWidth) {
                    generateColConfig["headerStyle"] = { ...generateColConfig["headerStyle"], width: col.headerWidth }
                    printGenerateColConfig["headerStyle"] = { ...generateColConfig["headerStyle"] }
                    printGenerateColConfig["rowStyle"] = { ...generateColConfig["rowStyle"] }
                }
                if (col.hMinWidth) {
                    generateColConfig["headerStyle"] = { ...generateColConfig["headerStyle"], minWidth: col.hMinWidth }
                }
                if (col.hMaxWidth) {
                    generateColConfig["headerStyle"] = { ...generateColConfig["headerStyle"], maxWidth: col.hMaxWidth }
                }
                // Apply word wrap and padding styles if wordWarp is true
                if (col.wordWarp) {
                    generateColConfig["style"] = { ...generateColConfig["style"], maxWidth: col.headerWidth || '180px', minWidth: col.headerMinWidth || '', overflowWrap: 'break-word', padding: '8px' }
                    printGenerateColConfig["style"] = { ...generateColConfig["style"], maxWidth: col.headerWidth || '180px', minWidth: col.headerMinWidth || '', overflowWrap: 'break-word', padding: '8px' }
                }

                if (col.wordWarpDot) {
                    printGenerateColConfig["style"] = { ...generateColConfig["style"], maxWidth: col.headerWidth || '190px', minWidth: col.headerMinWidth || '', overflowWrap: 'break-word', padding: '8px' }
                    generateColConfig["style"] = { ...generateColConfig["style"], maxWidth: col.headerWidth || '190px', minWidth: col.headerMinWidth || '', padding: '8px', whiteSpace: 'nowrap', overflowWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }

                }

                if (col.isEmailField) {
                    generateColConfig["formatter"] = (cell, row) => {
                        const emails = cell.split(',');
                        if (emails.length <= 2) {
                            return <>
                                {emails.length ? emails.map((email, index) => {
                                    if (email.length > 30) {
                                        return <> <OverlayTrigger
                                            // key={`emailfield-${email}`}
                                            placement="top"
                                            overlay={
                                                <Tooltip id={`tooltip-custom`}>
                                                    <div>{email}</div>
                                                </Tooltip>
                                            }
                                        >
                                            <span>{email.substring(0, (col.maxCharLength || 25))} ...  {(index < emails.length - 1 ? ', ' : '')} </span>

                                        </OverlayTrigger>
                                            <br />
                                        </>
                                    } else {
                                        return email ? email + (index < emails.length - 1 ? ', ' : '') : ''
                                    }
                                }) : ''}
                            </>;
                        } else {
                            const displayEmails = emails.slice(0, 2).join(', ');
                            const remainingEmails = emails.slice(2).join(', ');
                            const remainingCount = emails.length - 2;
                            return (
                                <>
                                    {/* <span> {displayEmails} </span> */}
                                    <span>
                                        {emails.slice(0, 2).length && emails.slice(0, 2).map((email, index) => {
                                            if (email.length > 30) {
                                                return <> <OverlayTrigger
                                                    // key={`emailfield-${email}`}
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id={`tooltip-custom`}>
                                                            <div>{email}</div>
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span>{email.substring(0, (col.maxCharLength || 25))} ...  {(index < (emails.length - 1) ? ', ' : '')}</span>

                                                </OverlayTrigger>
                                                    <br />
                                                </>
                                            } else {
                                                return email
                                            }
                                        })}
                                    </span>
                                    <OverlayTrigger
                                        // key={`emailfield-${remainingEmails}`}
                                        placement="left"
                                        overlay={
                                            <Tooltip id={`tooltip-custom`}>
                                                <div>{remainingEmails}</div>
                                            </Tooltip>
                                        }
                                    >
                                        <span className="board-badge" >
                                            <a href="#" style={{ padding: '3px 10px' }}>+{remainingCount}</a>
                                        </span>
                                    </OverlayTrigger>
                                </>
                            );
                        }
                    };
                }

                if (col.showDotsOnLengthExceed) {
                    generateColConfig["formatter"] = (cell, row) => {
                        if (cell) {
                            if (cell.length <= (col.maxCharLength || 35)) {
                                return cell;
                            } else {
                                return (
                                    <OverlayTrigger
                                        // key={`emailfield-${cell}`}
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-custom`}>
                                                <div>{cell}</div>
                                            </Tooltip>
                                        }
                                    >
                                        <span>{cell.substring(0, (col.maxCharLength || 35))} ...</span>
                                    </OverlayTrigger>
                                );
                            }
                        }
                    };
                }

                // Apply word wrap and padding styles if wordWarp is true
                // if (col.isCheckboxField) {
                //     generateColConfig["formatter"] = checkboxFormatter;
                // }

                // Apply standard action icons if standardAction is true
                if (col.standardAction) {
                    generateColConfig["formatter"] = (cell, row) => {
                        return (
                            <div>
                                {col.setReminder
                                    // && col.reminderCount &&
                                    //   col.reminderCount.map(param => row[param]) > 0  
                                    &&
                                    <ActionIcon
                                        iconClass="bx bx-bell"
                                        tooltip={`Set/View Reminders `}
                                        onClick={() => col.handleOnClick('reminderTask', ...(col.onClickParams ? col.onClickParams.map(param => row[param]) : [cell]), (checkActionPermission(permissionsArr ? permissionsArr : row?.permission_keys, `edit-${actionPermissionPrefix}`)))}
                                        backgroundColor="#8098ad"
                                        count={col.reminderCount ? col.reminderCount.map(param => row[param]) : [cell]}
                                    />
                                }
                                {/* {col.setReminder &&
                                    <ActionIcon
                                        iconClass="zmdi zmdi-alarm-plus"
                                        tooltip={`Set/View Reminders`}
                                        onClick={() => col.handleOnClick('setreminder', ...(col.onClickParams ? col.onClickParams.map(param => row[param]) : [cell]))}
                                        backgroundColor="#359bff"
                                    />
                                } */}
                                {col.downloadDoc && <ActionIcon
                                    iconClass="zmdi zmdi-download"
                                    tooltip={`Download ${col.tooltipSuffixText || ''}`}
                                    onClick={() => col.handleOnClick('download', ...(col.onClickParams ? col.onClickParams.map(param => row[param]) : [cell]))}
                                    backgroundColor="#0652DD"
                                />}
                                {(!hideStandardAction && (!col.disableEdit || 
                                (checkActionPermission(permissionsArr ? permissionsArr : row?.permission_keys, `edit-${actionPermissionPrefix}`))
                                || (isReminderList && checkActionPermission(row?.permission_arr || [] , `edit-${row?.investment_type|| ''}`))
                                )) 
                                
                                && <ActionIcon
                                    iconClass="bx bxs-edit"
                                    tooltip={`Edit ${col.tooltipSuffixText || ''}`}
                                    onClick={() => col.handleOnClick('edit', ...(col.onClickParams ? col.onClickParams.map(param => row[param]) : [cell]), row)}
                                    backgroundColor="#58ae25"
                                />}
                                {((!hideStandardAction && !col.hideArchive) && (!col.disableArchive || (checkActionPermission(permissionsArr ? permissionsArr : row?.permission_keys, `archive-${actionPermissionPrefix}`)))) && <ActionIcon
                                    iconClass="zmdi zmdi-archive"
                                    tooltip={`Archive ${col.tooltipSuffixText || ''}`}
                                    onClick={() => col.handleOnClick('archive', ...(col.onClickParams ? col.onClickParams.map(param => row[param]) : [cell]))}
                                    backgroundColor="#74788d"
                                />}
                                {(!hideStandardAction && (!col.disableDelete || 
                                (checkActionPermission(permissionsArr ? permissionsArr : row?.permission_keys, `delete-${actionPermissionPrefix}`) === true)
                                || (!isAdminOrManager && isReminderList && checkActionPermission(row?.permission_arr || [] , `edit-${row?.investment_type|| ''}`))
                                ))  && <ActionIcon
                                    iconClass="bx bx-trash"
                                    tooltip={`Delete ${col.tooltipSuffixText || ''}`}
                                    onClick={() => col.handleOnClick('delete', ...(col.onClickParams ? col.onClickParams.map(param => row[param]) : [cell]))}
                                    backgroundColor={col.isChangeColor ? "#808080" : "#dd4a57"}
                                />}
                                {(col.sendInvite && row?.status == 0) && <ActionIcon
                                    iconClass="zmdi zmdi-email-open"
                                    tooltip={`Resend Invitation`}
                                    onClick={() => col.handleOnClick('sendInvite', ...(col.onClickParams ? col.onClickParams.map(param => row[param]) : [cell]))}
                                    backgroundColor="#2196F3"
                                />}
                                {col.resetPassword && <ActionIcon
                                    iconClass="zmdi zmdi-lock-open"
                                    tooltip={`Reset Password ${col.tooltipSuffixText || ''}`}
                                    onClick={() => col.handleOnClick('resetPassword', ...(col.onClickParams ? col.onClickParams.map(param => row[param]) : [cell]))}
                                    backgroundColor="#6fa5d3"
                                />}
                                {col.statusAction && <ActionIcon
                                    iconClass="zmdi zmdi-check-square"
                                    tooltip={`${col.tooltipSuffixText || ''} status `}
                                    onClick={() => col.handleOnClick('statusUpdate', ...(col.onClickParams ? col.onClickParams.map(param => row[param]) : [cell]))}
                                    backgroundColor="#8098ad"
                                />}
                                {col.showUnarchive && <ActionIcon
                                    iconClass="zmdi zmdi-open-in-browser"
                                    tooltip={`Unarchive ${col.tooltipSuffixText || ''}`}
                                    onClick={() => col.handleOnClick('unarchive', ...(col.onClickParams ? col.onClickParams.map(param => row[param]) : [cell]))}
                                    backgroundColor="#74788d"
                                />}
                                {col.showMarkAsDone && (isReminderAdminOrManager || row?.action_permission) && <ActionIcon
                                    iconClass="icon_box-checked"
                                    tooltip={`Complete`}
                                    onClick={() => col.handleOnClick('markAsDone', ...(col.onClickParams ? col.onClickParams.map(param => row[param]) : [cell]))}
                                    backgroundColor="#58ae25"
                                />}
                            </div>
                        )
                    }
                }


                if (col.isViewField) {
                    let tempValueField = (cell, row) => (
                        <>
                            {!col.action ? (
                                <span className='table-view-mode'
                                    onClick={() => col.handleOnClick('view', ...(col.onClickParams ? col.onClickParams.map(param => row[param]) : [cell]))}>
                                    {cell}
                                </span>
                            ) : (
                                col.url ? (
                                    <a href={`${col.url}/${col.onClickParams ? col.onClickParams.map(param => row[param]).join('/') : cell}`}>{cell}</a>
                                ) : (
                                    <a href={handleViewClick(col.type, row.id, row.investment_category)}>{cell}</a>
                                )
                            )}
                            {col.isWarningIcon && row.reminder_status && row.enable_reminder && (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`tooltip-custom`}>
                                            <div>
                                                Reminder: Please update:
                                                <ul style={{ listStyleType: 'none', padding: 0 }}>
                                                    <li style={{ textAlign: 'left', marginLeft: '-20px' }}>- Estimated Value</li>
                                                    <li style={{ textAlign: 'left', marginLeft: '-20px' }}>- Number of Employees</li>
                                                    <li style={{ textAlign: 'left', marginLeft: '-20px' }}>- Total Assets</li>
                                                    <li style={{ textAlign: 'left', marginLeft: '-20px' }}>- Total Liabilities</li>
                                                </ul>
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <i className="btn btn-circle-v2 fa fa-warning"
                                        style={{ fontSize: '12px', margin: '0px 5px', color: 'white', background: "#dd4a57", cursor: 'pointer' }}></i>
                                </OverlayTrigger>
                            )}
                        </>
                    );
                    generateColConfig["formatter"] = tempValueField;
                    printGenerateColConfig["formatter"] = tempValueField;
                }

                if (col.isPopupView) {
                    generateColConfig["formatter"] = (cell, row) => (
                        <span className='table-view-mode'
                            onClick={() => col.handleOnClick('popupView', ...(col.onClickParams ? col.onClickParams.map(param => row[param]) : [cell]), row)} > {cell}
                        </span>
                    )
                };

                tempCol.push(generateColConfig)
                tempPrintCol.push(printGenerateColConfig)
            })
            setTableColumns(tempCol)
            if (tempPrintCol.length) {
                setPrintColumns((tempPrintCol[tempPrintCol.length - 1]?.dataField === 'Action') ? tempPrintCol.slice(0, -1) : tempPrintCol);
            }
            setTableLoader(false)

            // if (viewPrintMode) {
            //     // onTableComplete()
            // }
        }
    }, [columns])

    useEffect(() => {
        if (printTableRef.current && viewPrintMode) {
            // handleTableChange();
            onTableComplete()
        }
    }, [printColumns]);
    // console.log('onTable complete value :: ', printTableRef, viewPrintMode)



    const defaultCsvFileName = csvFileName || 'Export';
    const [exportAction, setExportAction] = useState(false);
    /** Search and Export option */
    const MySearchAndExport = (props) => {
        const [searchText, setSearchText] = useState('');

        const handleChange = (e) => {
            setSearchText(e.target.value);
        };
        useEffect(() => {
            if (searchText !== '') {
                props.onSearch(searchText);
            } else if (searchText === '') {
                props.onSearch(searchText);
            }
        }, [searchText, props]);
        const handleClick = () => {
            props.onSearch(searchText);
            setExportAction(true);
        };

        const handleExportClick = () => {
            if (data.length > 0) {
                props.onExport();
            } else {
                notify('Not data found to export', 'error');
            }
        };

        return (
            <>
                <div className="row no-print">
                    <div className="col-sm-12 col-md-4"><div className="dt-buttons">
                        {/* {!disableCsv && <button className="dt-button buttons-excel buttons-html5" tabIndex="0" aria-controls="datatable-buttons" type="button" onClick={handleExportClick}><span>Csv</span></button>} */}
                    </div>
                    </div >
                    <div className="col-sm-6 col-md-4" >
                        {filterOptions && <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span htmlFor="dropdown" style={{ marginRight: '5px' }}>Category Filter: </span>
                            <Select id="dropdown" options={filterOptions}
                                defaultValue={selectedFilter ? selectedFilter : filterOptions[0]}
                                onChange={(val) => {
                                    setSeletedFilterValue({ label: val.label, value: val.value })
                                    handleFilterChange(val.value, val.label)
                                }}
                                value={selectedFilter ? selectedFilter : seletedFilterValue}
                                className="form-control-sm"
                                styles={{ control: (styles) => ({ ...styles, backgroundColor: 'white', height: '20px', border: '2px solid #ebebeb', fontSize: '14px', borderRadius: '0.5rem', fontWeight: 'normal' }) }}
                            />
                        </div>}
                    </div>
                    <div className="col-sm-6 col-md-4" >
                        {!disableSearch && <div id="datatable-buttons_filter" className="dataTables_filter">
                            <label style={{ padding: '5px 0px' }}>Search: <input type="search" className="form-control form-control-sm" placeholder="" aria-controls="datatable-buttons" onChange={handleChange} /></label>
                        </div>}
                    </div>
                </div>
                {showHeaderTotal &&

                    <div className="row mt-3">
                        {headerObjValue.map((values, index) => (
                            <div key={index} className="col-sm-12 col-md-4">
                                <div className="dt-buttons">
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            color: '#636161',
                                            fontSize: '16px',
                                        }}
                                        id="net-worth-title"
                                    >
                                        {values.label}: {formatCurrency(values.value)}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div >
                }
            </>

        );
    };

    const ShowHeaderTotalBlock = () => {
        return (
            <div className="row mt-3">
                {headerObjValue.map((values, index) => (
                    <div key={index} className="col-sm-12 col-md-4">
                        <div className="dt-buttons">
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    color: '#636161',
                                    fontSize: '16px',
                                }}
                                id="net-worth-title"
                            >
                                {values.label}: {formatCurrency(values.value)}
                            </span>
                        </div>
                    </div>
                ))}
            </div >
        )
    }

    const customTotal = (from, to, size) => (
        <span className="dataTables_info">
            Showing {from} to {to} of {size} entries
        </span>
    );

    const CustomPageButtonRenderer = ({
        page,
        active,
        disable,
        title,
        onPageChange
    }) => {
        const handleClick = (e) => {
            e.preventDefault();
            onPageChange(page);
        };

        const itemClasses = `paginate_button page-item ${active ? 'active' : ''} ${disable ? 'disabled' : ''}`;
        return (
            <li key={`${typeof page === 'number' ? page : Math.random()}`} className={itemClasses}>
                <a href="#" onClick={handleClick} className="page-link">
                    {page}
                </a>
            </li>
        );
    };

    const paginationOptions = {
        paginationSize: 5,
        pageStartIndex: 1,
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: false, // Hide the going to First and Last page button
        hideSizePerPage: true, // Hide the sizePerPage dropdown always
        hidePageListOnlyOnePage: false, // Hide the pagination list when only one page
        prePageText: <i className="bx bx-chevron-left"></i>,
        nextPageText: <i className="bx bx-chevron-right"></i>,
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        totalSize: data.length,
        sizePerPageList: [{
            text: '1', value: 50
        }, {
            text: '10', value: 20
        }, {
            text: 'All', value: data.length
        }], // A numeric array is also available. the purpose of above example is custom the text,
        pageButtonRenderer: CustomPageButtonRenderer,
    };

    const rowClasses = (row, rowIndex) => {
        return rowIndex % 2 === 0 ? 'even' : 'odd';
    };

    const handleBeforePrint = () => {
        setPrintMode(true);
    };

    const handleAfterPrint = () => {
        setPrintMode(false);
    };


    return (
        <div className='dataTables_wrapper dt-bootstrap5 no-footer responsive-view'>
            {!tableLoader ? <ToolkitProvider
                keyField='id'
                data={data}
                columns={tableColumns}
                search
                exportCSV={{
                    fileName: `${defaultCsvFileName}.csv`,
                    exportAll: exportAction ? false : true,
                    onlyExportFiltered: exportAction == false ? false : true,
                }}
            >
                {
                    props => {
                        return (<>
                            <div>
                                {!disableSearch ? <MySearchAndExport {...props.searchProps} {...props.csvProps} /> : ''}
                                {disableSearch && showHeaderTotal ? <ShowHeaderTotalBlock /> : ''}
                                <ReactToPrint
                                    ref={printBtnRef}
                                    trigger={() => <button className="d-none">Print this table</button>}
                                    documentTitle={headerName}
                                    content={() => printTableRef.current}
                                    onAfterPrint={() => handleTableChange('print', false)}
                                />
                                <div style={{ display: 'none' }}>
                                    <PrintableTable
                                        ref={printTableRef}
                                        data={data}
                                        printColumns={printColumns}
                                        headerName={headerName}
                                        tableKeyType={tableKeyType}
                                    />
                                </div>

                                {/* {viewPrintMode ? */}
                                <PrintableTable
                                    ref={printTableRef}
                                    data={data}
                                    printColumns={printColumns}
                                    headerName={headerName}
                                    showHeaderTotal={showHeaderTotal}
                                    headerObjValue={headerObjValue}
                                />
                                {/* : */}
                                <div style={{ minWidth: '100%', maxWidth: tableWidth }} className={`no-print ${FreezeColumn ? "" : ''}`}> {/*className={`no-print ${FreezeColumn ? "sticky-table-container" : ''}`}*/}
                                    <BootstrapTable
                                        keyField='id'
                                        pagination={paginationFactory(paginationOptions)}
                                        wrapperClasses={"dataTables_wrapper dt-bootstrap5 no-footer table-responsive"}
                                        classes={`"table table-striped table-bordered dt-responsive wrap custom-table-cell-wra data-table-area dataTable no-footer dtr-inline " ${FreezeColumn ? "sticky-table" : ''}`}
                                        noDataIndication={() => (<div style={{ textAlign: 'center', padding: '20px' }}>No data available</div>)}
                                        rowClasses={rowClasses}
                                        {...props.baseProps}
                                    />
                                </div>
                                {/* } */}
                            </div>
                        </>)
                    }
                }
            </ToolkitProvider> : (<div>No visible columns detected</div>)}
        </div>
    )
};

export default Datatable;