import React, { useState, useEffect, useContext } from 'react';
import Table from '../../components/Table/Datatable'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { get, post, put, remove } from '../../api/index'
import { GET_ALL_ESTATE_PLANNING, ESTATE_PLANNING_DELETE_DOCUMENT, ESTATE_PLANNING_DOCUMENT_GET_BY_ID, GET_REMINDER_TASK_LIST } from '../../api/endpoints'
import { confirmAlert } from 'react-confirm-alert';
import 'react-toastify/dist/ReactToastify.css'
import { concatUrlWithId, getFileNameFromS3Url, formatDateAndTime, notify, refreshCount, addValueLabel, checkPermission } from '../../utils/commonUtils';
import { PageLoader } from '../../Context/PageLoader';
import EstatePlanningUploader from '../../components/DocumentManager/EstatePlanningUploader';
import ReminderTabList from '../../components/Reminder/ReminderTabList';
import ReminderTab from '../../components/Reminder/ReminderTab';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { dateOptions, reminderOptionType, timeOptions, weeklyOptions } from '../../Data/ReminderOptions'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Footer from '../../components/Footer';
import { UserPermissionStore } from '../../Store/UserPermission';
import PasswordConfirmation from '../../components/Forms/PasswordConfirmation';


function EstatePlanning() {
    const { loading, setLoading } = useContext(PageLoader);
    const [viewData, setViewData] = useState({ description: '', document_type: '', document_url: '', file_versions: [], extension: '', other: '' })
    const [showViewModal, setShowViewModal] = useState(false);
    const [showReminderModel, setShowReminderModel] = useState(false);
    const [completedReminderList, setCompletedReminderList] = useState(false);
    const [selectedInvID, SetSelectedInvID] = useState('')
    const history = useNavigate();
    const [showReminderTask, setShowReminderTask] = useState(false);
    const [enablePrint, SetEnablePrint] = useState(false)
    const [getInvestmentID, setInvestmentID] = useState('');
    const [userPermissions, setUserPermissions] = useState("");
    const { checkAndUpdatePermissions } = UserPermissionStore();
    const [showPasswordModel, setShowPasswordModel] = useState(false);
    const [deleteItem, setDeleteItem] = useState({ id: '', name: '' })

    const reminderTaskPopUp = (investment_id) => {
        setInvestmentID(investment_id);
        setShowReminderTask(true);
    };

    const handelListUpdate = () => getDocuments();

    const handleShowViewModal = async (id) => {
        setLoading(true);
        const editData = await get(ESTATE_PLANNING_DOCUMENT_GET_BY_ID, { id: id });
        const getReminderData = await post(GET_REMINDER_TASK_LIST, { record_id: id, status: ['completed'] });
        if (getReminderData && getReminderData?.reminderList?.length) {
            let tempRemindervalue = getReminderData?.reminderList.map((rem) => {
                rem['reminder_name'] = rem?.reminder_data['reminder_name'];
                rem['email_label'] = rem?.reminder_data?.['email']?.length ? rem.reminder_data['email'].join(', ') : "";
                rem['occurrence_type_label'] = addValueLabel(rem.reminder_data['occurrence_type'], reminderOptionType);
                rem['occurrence_on_label'] = rem.reminder_data['occurrence_type'] == 'onetime' ? moment(rem.reminder_data['occurrence_on']).format("MM/DD/YYYY") :
                    rem['occurrence_type'] == 'yearly' ? moment(rem['occurrence_on']).format("MMM-DD") :
                        rem.reminder_data['occurrence_type'] == "daily" ? 'Every Day' :
                            addValueLabel(rem.reminder_data['occurrence_on'], rem.reminder_data['occurrence_type'] == "weekly" ? weeklyOptions : dateOptions);
                rem['occurrence_time_label'] = addValueLabel(rem.reminder_data['occurrence_time'], timeOptions);
                rem['completedBy'] = rem?.user_reminder_data?.full_name || '';
                return rem;
            });
            setCompletedReminderList(tempRemindervalue)
        } else {
            setCompletedReminderList([]);
        }
        setViewData(editData?.data)
        setViewData({
            ...editData,
            document_type: editData?.data?.get_type?.name,
            description: editData?.data?.description,
            document_url: editData?.data?.document_url,
            other: editData?.data?.other,
            extension: editData?.data?.extension,
            updatedAt: editData?.data?.updatedAt,
            updatedBy: editData?.data?.estate_planning_created_by?.full_name
        })
        setShowViewModal(true)
        setLoading(false);
    }
    const handleCloseViewModal = () => {
        setShowViewModal(false)
        setCompletedReminderList([])
    };

    const [documentList, setDocumentList] = useState([]);

    /* Upload Modal  */
    const [show, setShow] = useState(false);
    const [mode, setMode] = useState('add');
    const [editId, setEditId] = useState('add');

    const handleClose = () => setShow(false);

    const handleShow = (type = 'add') => {
        setShow(true);
        setMode(type)
    }
    const handleEdit = (id) => {
        handleShow('edit');
        setEditId(id);
    }
    const investmentId = 'sdsd'
    const revertDelete = () => {
        setDeleteItem({ id: '', name: '' })
    }
    function deleteDocument(id, Name) {
        confirmAlert({
            title: 'Delete document',
            message: `Are you sure you want to delete the Document ?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setDeleteItem({ id: id, name: Name });
                        setShowPasswordModel(true)
                    }
                },
                {
                    label: 'No',
                    onClick: revertDelete()
                }
            ]
        });
    }

    const handleDelete = async () => {
        const { id, name } = deleteItem;
        try {
            const deleteInvestment = await remove(concatUrlWithId(ESTATE_PLANNING_DELETE_DOCUMENT, id));
            if (deleteInvestment) {
                // history('/stock-list')
            }
            setShowPasswordModel(false)
            notify(`Document successfully deleted`, 'success')
            getDocuments();
            refreshCount();
        } catch (error) {
            setShowPasswordModel(false)
            notify(`Unable to delete ${name}`, 'error')
            console.log('Error while deleting the error : ', error)
        }

    };

    const downloadFile = (url) => {
        window.location = url;
    }
    const handleOnClickAction = (type, id, name, url) => {
        switch (type) {
            case 'view':
                handleShowViewModal(id);
                break;
            case 'edit':
                handleEdit(id);
                break;
            case 'download':
                downloadFile(url);
                break;
            case 'delete':
                deleteDocument(id, name);
                break;
            case 'setreminder':
                handleReminderClick(id, name);
                break;
            case 'reminderTask':
                reminderTaskPopUp(id);
                break;
            default:
                break;
        }
    };

    const handleReminderClick = (id, name) => {
        SetSelectedInvID(id)
        setShowReminderModel(!showReminderModel)
    };

    const handleReminderModelClose = () => {
        setShowReminderModel(false);
    };

    const onUploadSuccess = (isEdit, name) => {
        if (isEdit) {
            notify(`Document successfully updated`, 'success')
        } else {
            notify(`Document successfully uploaded`, 'success')
        }
        handleClose()
        getDocuments();
    }

    useEffect(() => {
        setLoading(true);
        authCheckAndFetch();
        // setLoading(false);
    }, [])

    const authCheckAndFetch = async () => {
        let tempPermission = await checkAndUpdatePermissions();

        setUserPermissions(tempPermission || []);

        if (checkPermission(tempPermission, 'isAdmin') || checkPermission(tempPermission, 'isManager') || checkPermission(tempPermission, 'view-estate-planning')) {
            getDocuments();
        } else {
            setLoading(false);
        }
    };

    const getDocuments = async () => {
        try {
            let data = await get(GET_ALL_ESTATE_PLANNING);
            setDocumentList(data?.data)
            setLoading(false);

        } catch (error) {
            console.log('Errro message', error?.response?.data?.message)
            if (error?.response?.data?.message) {
                notify(`${error?.response?.data?.message}`, 'error')
                history('/portfolio-summary')
            } else {
                notify(`${error}`, 'error')
                console.log('Error while deleting the error : ', error)
            }
            setLoading(false);
        }
    }

    const handleTableChange = (type, value) => {
        try {
            switch (type) {
                case 'print':
                    SetEnablePrint(value);
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.log('Error in the handle table change funciton:: ', error)
        }
    }

    const columns = [        
        { dataField: 'name', text: 'ID', csvExport: false, hidden: true },
        {
            dataField: 'get_type.name', text: 'Type', sort: true, isViewField: true, handleOnClick: handleOnClickAction, onClickParams: ['id'],
            headerWidth: '180px', wordWarp: true
        },
        { dataField: 'description', text: 'Description', sort: true, wordWarpDot: true, hMinWidth: '8%', hMaxWidth: "13%", },
        { dataField: 'createdAt', text: 'Uploaded Date', sort: true, isDateField: true },
        { dataField: 'updatedAt', text: 'Modified Date', sort: true, isDateField: true }
    ];

    let tempAction = { dataField: 'Action', text: 'Action', csvExport: false, standardAction: true, tooltipSuffixText: ' ', disableArchive: true, downloadDoc: true, handleOnClick: handleOnClickAction, onClickParams: ['id', 'document_name', 'document_url'], reminderCount: ['active_reminders_count'], setReminder: true }

    if (checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) {
        columns.push(tempAction);
    } else {
        if (!checkPermission(userPermissions, 'edit-estate-planning')) tempAction.disableEdit = true;
        if (!checkPermission(userPermissions, 'delete-estate-planning')) tempAction.disableDelete = true;
        tempAction = { ...tempAction, disableArchive: true, setReminder: true }
        columns.push(tempAction);
    };

    const updateReminderData = async () => {
        await getDocuments();
    }

    return (
        <div className="main-content introduction-farm">
            <div className="content-wraper-area">
                <div className="dashboard-area">
                    <div className="container-fluid">
                        <button id="update-remainder-data" onClick={() => updateReminderData()} style={{ display: 'none' }}> Click me </button>
                        <br></br>
                        <div className='card'>
                            <div className="card-body">
                                <div className='row'>
                                    <div className='col-6'>
                                        <legend className='text-bold'>Estate Planning <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Print</Tooltip>} >
                                            <i className="fa fa-print btn btn-circle print-btn" onClick={() => { SetEnablePrint(true); setLoading(true) }} ></i>
                                        </OverlayTrigger>
                                        </legend>
                                    </div>
                                    <div className='col-6'>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                                            {(checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager') || checkPermission(userPermissions, 'create-estate-planning')) &&
                                                <button type='button' onClick={() => handleShow('add')} className='btn btn-primary'><i className="icon_plus"></i> Add New</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <Modal show={showViewModal} onHide={handleCloseViewModal} size={completedReminderList?.length ? "lg" : 'md'}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>View Document Details</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body style={{ paddingTop: '2px' }}>

                                        <fieldset>
                                            <legend className="text-bold"> Document Details </legend>
                                            <div className='row'>
                                                <div className={`mb-3 ${viewData?.document_type === "Other" ? 'col-6' : 'col-12'}`} >
                                                    <label className="form-label" htmlFor="document-input">Document Type</label>
                                                    <div className="view-label">{viewData?.document_type}</div>
                                                </div>
                                                {viewData?.document_type === "Other" && <div className="mb-3  col-6">
                                                    <label className="form-label" htmlFor="document-input">Other</label>
                                                    <div className="view-label">{viewData?.other}</div>
                                                </div>}
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="document-input">Description</label>
                                                    <div className="view-label">{viewData?.description}</div>
                                                </div>

                                            </div>
                                            <div className='image-preview-holder'>
                                                <div className='doc-image-container'>

                                                    <div className='file_versions'>
                                                        <div>

                                                            <img className='doc-image-preview'
                                                                src={
                                                                    viewData?.extension === 'pdf' ? 'img/pdf-download.png' :
                                                                        viewData?.extension === 'csv' ? 'img/csv-download.png' :
                                                                            viewData?.extension === 'file' ? 'img/file-download.jpeg' :
                                                                                viewData?.extension === 'docx' ? 'img/docs-download.png' :
                                                                                    viewData?.document_url
                                                                }
                                                                height="20" />
                                                        </div>
                                                        <div style={{ flexGrow: 1 }} >
                                                            <div className='file-version-name'>{getFileNameFromS3Url(viewData?.document_url)}</div>
                                                            <div className='file-version-info'> Updated At : {formatDateAndTime(viewData?.updatedAt, "MM/DD/YYYY")} By: {viewData?.updatedBy} </div>
                                                        </div>
                                                        <div style={{ minWidth: '40px' }}>
                                                            <a href={viewData?.document_url}><i className="btn btn-circle-v1 zmdi zmdi-download" style={{ fontSize: '16px', margin: '0px 5px', color: 'white', background: 'rgb(6, 82, 221)', cursor: 'pointer' }}></i></a>
                                                        </div>

                                                    </div>
                                                </div>


                                                {/* {(viewData?.file_versions.length > 0) && <div style={{ borderTop: '1px solid #d3d3d3' }}>
                                                    <div style={{ marginTop: '5px', marginBottom: '5px' }}>Previous Versions:</div>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        gap: '10px'
                                                    }}>
                                                        {viewData?.file_versions.reverse().map((item) => {
                                                            return (
                                                                <div className='file_versions'>
                                                                    <div>
                                                                        <img
                                                                            className='doc-image-preview'
                                                                            src={
                                                                                item?.extension === 'pdf' ? 'img/pdf-download.png' :
                                                                                    item?.extension === 'csv' ? 'img/csv-download.png' :
                                                                                        item?.extension === 'file' ? 'img/file-download.jpeg' :
                                                                                            item?.document_url
                                                                            }
                                                                            height="45"
                                                                        />
                                                                    </div>
                                                                    <div style={{ flexGrow: 1 }} >
                                                                        <div className='file-version-name'>{getFileNameFromS3Url(item?.document_url)}</div>
                                                                        <div className='file-version-info'> Updated At : {formatDateAndTime(item?.updatedAt, "MM/DD/YYYY")} By: {item?.uploaded_by}  </div>
                                                                    </div>
                                                                    <div style={{ width: '40px' }}>
                                                                        <a href={item?.document_url}><i className="btn btn-circle-v1 zmdi zmdi-download" style={{ fontSize: '16px', margin: '0px 5px', color: 'white', background: 'rgb(6, 82, 221)', cursor: 'pointer' }}></i></a>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                } */}
                                            </div>

                                        </fieldset>
                                        {completedReminderList?.length ? <div className="card-body" style={{ paddingTop: '1px' }}>
                                            <ReminderTabList recordId={viewData?.data?.id} entityType={'investment'} reminderData={completedReminderList} />
                                        </div> : ''}
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleCloseViewModal}>
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal >

                                <EstatePlanningUploader show={show} mode={mode} editId={editId} handleClose={handleClose} onSuccess={onUploadSuccess} investmentId={investmentId} />
                                <Table data={documentList || []} columns={columns} disableCsv={true} disableSearch={true} enablePrint={enablePrint} handleTableChange={handleTableChange} headerName={'Estate Planning'} />
                                {/* <ReminderList show={showReminderModel} recordId={selectedInvID} handleClose={handleReminderModelClose} entityType={'estate_planning'} headerName={'Reminder'} invesmentCat={''} handelListUpdate={handelListUpdate} />
                                <ReminderTaskModel show={showReminderTask} investmentID={getInvestmentID} handleClose={handleCloseReminderTask} handelListUpdate={handelListUpdate} entityType={'estate_planning'} /> */}
                                <ReminderTab show={showReminderTask} recordId={getInvestmentID} handleClose={() => setShowReminderTask(false)} hangleTabChange={handelListUpdate} invesmentCatId={''} entityType={'estate_planning'}
                                    actionPermission={(checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager') || checkPermission(userPermissions, 'edit-estate-planning'))} />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PasswordConfirmation showViewModal={showPasswordModel} handleDelete={handleDelete} handleCloseViewModal={() => { setShowPasswordModel(false); revertDelete() }} />
            {(userPermissions?.length && checkPermission(userPermissions, 'isManager')) && <Footer PropUserPermissions={userPermissions} />}
        </div>
    );
}

export default EstatePlanning;