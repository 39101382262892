
import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { get, post, put, remove } from '../../api'
import {
    DOCUMENT_GET_BY_INVESTMENT_ID, DELETE_DOCUMENT, DOCUMENT_GET_BY_ID, DOCUMENT_GET_BY_OB_YEAR_ID, GET_YEAR_DOCUMENT_BY_ID,
    DELETE_YEAR_DOCUMENT
} from '../../api/endpoints'
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { concatUrlWithId, getFileNameFromS3Url, formatDateAndTime, notify } from '../../utils/commonUtils';
import { FormField, SelectField } from '../Forms/FormFields';
import Table from '../Table/Datatable'
import Uploader from './Uploader';
import { DocumentTypeStore } from '../../Store/UserPermission';


function Documents({ investmentId = '', oBYearId = '', businessID = '', uploadPermission, isArchived, isOperatingBusiness = false, documentTypeKey = '' }) {
    const [viewData, setViewData] = useState({ document_name: '', document_type: '', document_location_url: '', file_versions: [], other: '' });
    const [showViewModal, setShowViewModal] = useState(false);
    const { checkAndUpdateDocumentTypes } = DocumentTypeStore();
    const [documentTypeValue, setDocumentTypeValue] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [show, setShow] = useState(false);
    const [mode, setMode] = useState('add');
    const [editId, setEditId] = useState('add');

    const handleCloseViewModal = () => setShowViewModal(false);

    useEffect(() => {
        if (documentTypeKey !== '') {
            checkAndUpdateDocumentTypes().then(documentTypesList => {
                const documentKeyValues = documentTypesList[documentTypeKey];
                setDocumentTypeValue(documentKeyValues);
            });
        }
    }, [documentTypeKey]);

    const handleShowViewModal = async (id) => {
        const editData = await get(isOperatingBusiness ? GET_YEAR_DOCUMENT_BY_ID : DOCUMENT_GET_BY_ID, { id: id });
        let documentKeyValues = [];
        await checkAndUpdateDocumentTypes().then(documentTypesList => {
            documentKeyValues = documentTypesList[documentTypeKey] || [];
        });
        const matchedObject = documentKeyValues.find(obj => obj.value == editData?.data.document_type) || {};
        setViewData({
            ...editData?.data,
            document_type: matchedObject.label
        });

        setShowViewModal(true);
    };


    useEffect(() => {
        getDocuments();
    }, [businessID]);

    const getDocuments = async () => {
        const data = isOperatingBusiness
            ? await get(DOCUMENT_GET_BY_OB_YEAR_ID, { "yearid": businessID })
            : await get(DOCUMENT_GET_BY_INVESTMENT_ID, { investment_id: investmentId });
        setDocumentList(data?.data);
    };

    const handleClose = () => setShow(false);
    const handleShow = (type = 'add') => {
        setShow(true);
        setMode(type);
    };
    const handleEdit = (id) => {
        handleShow('edit');
        setEditId(id);
    };

    function deleteStock(id, Name) {
        confirmAlert({
            title: 'Delete document',
            message: `Are you sure you want to delete the ${Name}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleDelete(id, Name)
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    const handleDelete = async (id, name) => {
        try {
            const deleteInvestment = isOperatingBusiness
                ? await remove(concatUrlWithId(DELETE_YEAR_DOCUMENT, id))
                : await remove(concatUrlWithId(DELETE_DOCUMENT, id));
            notify(`${name} successfully deleted`, 'success');
            getDocuments();
        } catch (error) {
            notify(`Unable to delete ${name}`, 'error');
            console.log('Error while deleting the error: ', error);
        }
    };

    const downloadFile = (url) => {
        window.location = url;
    };

    const handleOnClickAction = (type, id, name, url) => {
        switch (type) {
            case 'view':
                handleShowViewModal(id);
                break;
            case 'edit':
                handleEdit(id);
                break;
            case 'download':
                downloadFile(url);
                break;
            case 'delete':
                deleteStock(id, name);
                break;
            default:
                break;
        }
    };

    const columns = [
        { dataField: 'id', text: 'ID', csvExport: false, hidden: true },
        {
            dataField: 'document_name', text: 'Document Name', sort: true, isViewField: true, handleOnClick: handleOnClickAction, onClickParams: ['id'],
            headerWidth: '180px', wordWarp: true
        },
        { dataField: 'document_type', text: 'Document Type', sort: true },
        { dataField: 'uploaded_date', text: 'Uploaded Date', sort: true, isDateField: true },
        { dataField: 'document_uploaded_by.full_name', text: 'Uploaded By', sort: true },
    ];

    let tempAction = { dataField: 'Action', text: 'Action', csvExport: false, standardAction: true, tooltipSuffixText: 'document', disableArchive: true, downloadDoc: true, handleOnClick: handleOnClickAction, onClickParams: ['id', 'document_name', 'document_location_url'] };
    if (!isArchived && uploadPermission) {
        columns.push(tempAction);
    }

    const onUploadSuccess = (isEdit, name) => {
        notify(`${name} successfully ${isEdit ? 'updated' : 'uploaded'}`, 'success');
        handleClose();
        getDocuments();
        const randomValue = Math.random().toString(36).substr(2, 9);
        
    
    };

    return (<>
        <br></br>
        <div className='row'>
            <div className='col-6'>
                <legend className='text-bold'>Upload File List</legend>
            </div>
            <div className='col-6'>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                    {!isArchived && uploadPermission && <button type='button' onClick={handleShow} className='btn btn-primary'>Upload Documents</button>}
                </div>
            </div>

        </div>


        <Modal show={showViewModal} onHide={handleCloseViewModal}>
            <Modal.Header closeButton>
                <Modal.Title>View Document Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <fieldset>
                    <legend className="text-bold"> Document Details </legend>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="document-input">Document Name</label>
                        <div className="view-label">{viewData?.document_name}</div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="document-input">Document Type</label>
                        <div className="view-label">{viewData?.document_type}</div>
                    </div>
                    {(viewData?.document_type === "Other") && <div className="mb-3">
                        <label className="form-label" htmlFor="document-input">Other</label>
                        <div className="view-label">{viewData?.other}</div>
                    </div>}
                    <div className='image-preview-holder'>
                        <div className='doc-image-container'>

                            <div className='file_versions'>
                                <div>

                                    <img className='doc-image-preview'
                                        src={
                                            viewData?.extension == 'pdf' ? '../img/pdf-download.png' :
                                                viewData?.extension == 'csv' ? '../img/csv-download.png' :
                                                    viewData?.extension == 'file' ? '../img/file-download.jpeg' :
                                                        viewData?.extension == 'docx' ? '../img/docs-download.png' :
                                                            viewData?.document_location_url
                                        }
                                        height="45" />
                                </div>
                                <div style={{ flexGrow: 1 }} >
                                    <div className='file-version-name'>{getFileNameFromS3Url(viewData?.document_location_url)}</div>
                                    <div className='file-version-info'> Updated At : {formatDateAndTime(viewData?.uploaded_date, "MM/DD/YYYY")} By: {viewData?.document_uploaded_by?.full_name}  </div>
                                </div>
                                <div style={{ minWidth: '40px' }}>
                                    <a href={viewData?.document_location_url}><i className="btn btn-circle-v1 zmdi zmdi-download" style={{ fontSize: '16px', margin: '0px 5px', color: 'white', background: 'rgb(6, 82, 221)', cursor: 'pointer' }}></i></a>

                                </div>

                            </div>
                        </div>


                        {(!isOperatingBusiness && viewData?.file_versions.length > 0) && <div style={{ borderTop: '1px solid #d3d3d3' }}>
                            <div style={{ marginTop: '5px', marginBottom: '5px' }}>Previous Versions:</div>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px'
                            }}>
                                {viewData?.file_versions.reverse().map((item) => {
                                    return (
                                        <div className='file_versions'>
                                            <div>
                                                <img
                                                    className='doc-image-preview'
                                                    src={
                                                        item?.extension === 'pdf' ? '../img/pdf-download.png' :
                                                            item?.extension === 'csv' ? '../img/csv-download.png' :
                                                                item?.extension === 'file' ? '../img/file-download.jpeg' :
                                                                    item?.extension === 'docx' ? '../img/docs-download.png' :
                                                                        item?.document_location_url
                                                    }
                                                    height="45"
                                                />
                                            </div>
                                            <div style={{ flexGrow: 1 }} >
                                                <div className='file-version-name'>{getFileNameFromS3Url(item?.document_location_url)}</div>
                                                <div className='file-version-info'> Updated At : {formatDateAndTime(item?.uploaded_at, "MM/DD/YYYY")} By: {item?.uploaded_by}  </div>
                                            </div>
                                            <div style={{ width: '40px' }}>
                                                <a href={item?.document_location_url}><i className="btn btn-circle-v1 zmdi zmdi-download" style={{ fontSize: '16px', margin: '0px 5px', color: 'white', background: 'rgb(6, 82, 221)', cursor: 'pointer' }}></i></a>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        }
                    </div>

                </fieldset>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseViewModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal >

        <Uploader show={show} mode={mode} editId={editId} handleClose={handleClose} onSuccess={onUploadSuccess} investmentId={investmentId} isOperatingBusiness={isOperatingBusiness} oBYearId={oBYearId} businessID={businessID} documentTypeKey={documentTypeKey} />
        <Table data={documentList || []} columns={columns} disableCsv={true} disableSearch={true} />
    </>)
}
export default Documents;