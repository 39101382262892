import React, { useState, useEffect, useRef } from 'react';
import { Field, ErrorMessage, useField } from 'formik';
import BSForm from 'react-bootstrap/Form';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select'
import DatePickerComponent from '../DatePicker';
import { ActionIcon, formatCurrency } from '../../utils/commonUtils';
import moment from 'moment';
import { EmailInput } from './EmailField';

export const FormField = ({ viewMode, fieldName, label, value, type = 'text', maxLength, isRequired = true, disabled = false }) => {
  return (
    <div className="mb-2">
      <label className="form-label" htmlFor={`formrow-${fieldName}-input`}>{label}
        {isRequired && !viewMode ? <span className='man-start'>*</span> : ''}</label>
      {!viewMode && <Field readOnly={viewMode} maxLength={maxLength} className="form-control" type={type} id={`formrow-${fieldName}-input`} name={fieldName} disabled={disabled} />}
      {viewMode && <div className='view-label'>{value}</div>}
      <ErrorMessage name={fieldName} component="div" className="error-message" />
    </div>
  )
};
export const EINField = ({ viewMode, fieldName, label, value, type = 'text', maxLength, isRequired = true, disabled = false }) => {
  return (
    <div className="mb-3">
      <label className="form-label" htmlFor={`formrow-${fieldName}-input`}>{label}
        {isRequired && !viewMode ? <span className='man-start'>*</span> : ''}</label>
      {!viewMode && (
        <Field className="form-control" maxLength={maxLength} readOnly={viewMode}
          type={type} id={`formrow-${fieldName}-input`} name={fieldName} disabled={disabled}
          onInput={(e) => {
            let inputValue = e.target.value.replace(/[^\d]/g, ''); // Remove all non-numeric characters
            if (inputValue.length > 2) {
              inputValue = inputValue.slice(0, 2) + '-' + inputValue.slice(2, 9); // Insert hyphen at the 3rd position
            }
            e.target.value = inputValue.slice(0, 10);
          }}
        />
      )}
      {viewMode && <div className='view-label'>{value}</div>}
      <ErrorMessage name={fieldName} component="div" className="error-message" />
    </div>
  )
};

export const TextAreaField = ({ viewMode, fieldName = "", label, value, maxLength = 500, isRequired, textAlign = false }) => (
  <div className="mb-2">
    <label className="form-label" htmlFor={`formrow-${fieldName}-input`}>
      {label}
      {isRequired && !viewMode ? <span className='man-start'>*</span> : null}
    </label>
    {!viewMode && <Field readOnly={viewMode} maxLength={maxLength} className="form-control" as="textarea" id={`formrow-${fieldName}-input`} name={fieldName} />}
    {viewMode &&
      <div className='view-label'>
        <div className='text-box'>{value} </div>
      </div>
    }
    <ErrorMessage name={fieldName} component="div" className="error-message" />
  </div>
);

export const CurrencyField = ({ viewMode, fieldName, label, value = null, values, setFieldValue, auditLog, auditValue, prefix = '$', isRequired = true, disabled = false, disableNegative = false }) => (
  <div className="mb-2">
    <label className="form-label" htmlFor={`formrow-${fieldName}-input`}>{`${label}` + `${prefix != '' ? `(${prefix})` : ''}`}
      {isRequired && !viewMode ? <span className='man-start'>*</span> : ''}</label>
    {!viewMode && <CurrencyInput
      id={`formrow-${fieldName}-input`}
      className="form-control"
      prefix={prefix}
      value={value == 'NaN' ? null : value || values[fieldName] == 'NaN' ? null : values[fieldName]}
      name={fieldName}
      decimalsLimit={2}
      readOnly={viewMode}
      maxLength={16}
      // onValueChange={(value, name, values) => setFieldValue(fieldName, value || '')}
      onValueChange={(value, name, values) => {
        let select_value = value == 'NaN' ? null : value;        
        if (disableNegative) {
          if (!select_value || select_value === "" || !value.includes("-")) {
            setFieldValue(fieldName, select_value || '');
          }
        } else {
          setFieldValue(fieldName, select_value || '');
        }
      }}
      disabled={disabled}
    />}
    {viewMode && (
      <div className='view-label'>
        {formatCurrency(value || values[fieldName])}
        {auditLog && (
          <div className='mobile-responsive-text'>
            Last Modified: {auditValue ? moment(auditValue).format('MM/DD/YYYY hh:mm a') : 'N/A'}
          </div>
        )}
      </div>
    )}
    <ErrorMessage name={fieldName} component="div" className="error-message" />
  </div>
);

export const SelectField = ({ viewMode, fieldName, label, value, options, values, setFieldValue, isRequired = true, disabled = false }) => (
  <div className="mb-2">
    <label className="form-label" htmlFor={`formrow-${fieldName}-input`}>{label}
      {isRequired && !viewMode ? <span className='man-start'>*</span> : ''}</label>
    {!viewMode && <Select
      options={options}
      value={options?.filter((item) => item.value == values[fieldName])}
      onChange={(val) => setFieldValue(fieldName, val.value, val?.label || "")}
      styles={{ control: (styles) => ({ ...styles, backgroundColor: 'white', height: '38px', border: '2px solid #ebebeb', fontSize: '14px', borderRadius: '0.5rem', fontWeight: 'normal' }) }}
      isDisabled={disabled}
    />}
    {/* {viewMode && <div className='view-label'>{value}</div>}
     */}
    {viewMode && (
      <div className='view-label'>
        {options?.find(item => item.value === values[fieldName])?.label}
      </div>
    )}
    <ErrorMessage name={fieldName} component="div" className="error-message" />
  </div>
);

export const DatePickerField = ({ viewMode, fieldName, label, value, setFieldValue, isFieldClear, isRequired = true, isYear = false, isDateMonth = false, isCustomErr = false, customErrMsg = "", handleDateFieldChange, disabled = false, disablePastDate = false, isMonth = false }) => {
  return (
    <div className="mb-2">
      <label className="form-label" htmlFor={`formrow-${fieldName}-input`}>{label}
        {isRequired && !viewMode ? <span className='man-start'>*</span> : ''}</label>
      {!viewMode && (
        <Field name={fieldName}>
          {({ field }) => (
            <DatePickerComponent
              viewMode={false}
              fieldValue={value}
              fieldName={fieldName}
              selected={value}
              onChange={(date) => { setFieldValue(fieldName, date) }}
              onhandleDateChange={handleDateFieldChange}
              handleClear={isFieldClear}
              isYear={isYear}
              isMonth={isMonth}
              isDateMonth={isDateMonth}
              disabled={disabled}
              disablePastDate={disablePastDate}
            />
          )}
        </Field>
      )}
      {viewMode && <div className='view-label'> {!(value == 'Invalid date' || value == null) ? isYear ? moment(value).format("YYYY") : isDateMonth ? moment(value).format("MMMM-DD") : isMonth ? moment(value).format("MMMM") : moment(value).format("MM/DD/YYYY") : ''}</div>}
      {isCustomErr && <div className="error-message">{customErrMsg}</div>}
      {!isCustomErr && <ErrorMessage name={fieldName} component="div" className="error-message" />}
    </div>
  )
};

export const SwitchField = ({
  fieldName, label, viewMode, values, setFieldValue, sameLine = true, enableAction = false,
  handleAction = () => { }, noMargin = false, disableAdd = false
}) => (
  <div className={`${noMargin ? 'd-flex ml-2' : (sameLine ? 'd-flex mt-4' : 'mb-2')}`}>
    <label className={`form-label ${sameLine && 'mr-7 '} ${viewMode && 'switch-view-mode'}`} htmlFor={`formrow-${fieldName}-input`}>{label}</label> {' '}
    {viewMode ? (
      <BSForm.Check
        type="switch"
        checked={values[fieldName]}
        name={fieldName}
        disabled
      />
    ) : (
      <BSForm.Check
        type="switch"
        checked={values[fieldName]}
        name={fieldName}
        onChange={() => setFieldValue(fieldName, !values[fieldName])}
      />
    )}
    {/* {enableAction && <ActionIcon
             iconClass="bx bx-plus reminder-action-btn"
             tooltip={`Add`}
             onClick={() => handleAction('document', true)}
             backgroundColor="#0652DD"
        />} */}
    {enableAction &&
      <button type="button" className="btn btn-sm btn-secondary" style={{ marginLeft: '30px' }} onClick={() => handleAction('document', true)} disabled={viewMode || disableAdd}><i className="ti-plus me-1" ></i> Add</button>
    }
  </div>
);

export const FileUploadField = ({ viewMode, fieldName, label, imageName, imageType, imageData }) => {
  const [field, , helpers] = useField({ name: fieldName });
  const [base64Image, setBase64Image] = useState(null);

  useEffect(() => {
    // Function to convert buffer to base64
    const bufferToBase64 = (buffer) => {
      const arrayBufferView = new Uint8Array(buffer.data);
      const blob = new Blob([arrayBufferView], { type: buffer.type });
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    };

    // Convert buffer to base64 when imageData changes
    if (imageData) {
      bufferToBase64(imageData).then(base64String => {
        setBase64Image(base64String);
      });
    }
  }, [imageData]);

  const handleChange = (event) => {
    const file = event.currentTarget.files[0];
    helpers.setValue(file);
  };

  return (
    <div className="mb-3">
      <label className="form-label" htmlFor={`formrow-${fieldName}-input`}>
        {label} <span className='man-start'>*</span>
      </label>
      {!viewMode && (
        <input
          className="form-control"
          type="file"
          id={`formrow-${fieldName}-input`}
          name={fieldName}
          onChange={handleChange}
        />
      )}
      {viewMode && base64Image && (
        <div className='view-label'>
          {/* Render the image if base64Image is available */}
          <img src={base64Image} alt={imageName} className="img-container" />
        </div>
      )}
      <ErrorMessage name={fieldName} component="div" className="error-message" />
    </div>
  );
};

/** System information */

export const SystemInfo = ({ createdAt, updatedAt, createdBy, updatedBy, columnClass = "col-md-3" }) => (
  <fieldset>
    <legend className='text-bold'>System Information</legend>
    <div className='row'>
      <div className={columnClass}>
        <div className="mb-2">
          <label className="form-label" htmlFor="formrow-notes-input">Created At</label>
          <div className='view-label'>{moment(createdAt).format('MM/DD/YYYY hh:mm a')}</div>
        </div>
      </div>

      <div className={columnClass}>
        <div className="mb-2">
          <label className="form-label" htmlFor="formrow-notes-input">Last Modified At</label>
          <div className='view-label'>{moment(updatedAt).format('MM/DD/YYYY hh:mm a')}</div>
        </div>
      </div>
      <div className={columnClass}>
        <div className="mb-2">
          <label className="form-label" htmlFor="formrow-notes-input">Created By</label>
          <div className='view-label'>{createdBy}</div>
        </div>
      </div>
      <div className={columnClass}>
        <div className="mb-2">
          <label className="form-label" htmlFor="formrow-notes-input">Last Modified By</label>
          <div className='view-label'>{updatedBy}</div>
        </div>
      </div>
    </div>
  </fieldset>
)



export const RadioField = ({ viewMode, fieldName, label, value, type = 'radio', isRequired = true, options, setFieldValue }) => (
  <div className="mb-2">
    <label className="form-label" htmlFor={`formrow-${fieldName}-input`}>
      {label}
      {isRequired && !viewMode ? <span className='man-start'>*</span> : ''}
    </label>
    <div className='p-2'>
      {options.map((option) => (
        <label key={option.value}>
          <Field
            type={type}
            className="form-check-input"
            id={`formrow-${fieldName}-input`}
            name={fieldName}
            value={option.value}
            disabled={viewMode}
            checked={value === option.value}
            onChange={() => setFieldValue(fieldName, option.value)}
          />
          <span className='p-2' style={{ fontSize: 'smaller' }}>{option.label}</span>
        </label>
      ))}
    </div>
    {/* Assuming you're using Formik */}
    <ErrorMessage name={fieldName} component="div" className="error-message" />
  </div>
);


export const ViewOnlyFormField = ({ fieldName, label, value, }) => (
  <div className="mb-2">
    <label className="form-label" htmlFor={`formrow-${fieldName}-input`}>{label}</label>
    <div className='view-label'>{value}</div>
  </div>
);

export const ViewOnlyCurrencyField = ({ fieldName, label, value = null, prefix = '$' }) => (
  <div className="mb-2">
    <label className="form-label" htmlFor={`formrow-${fieldName}-input`}>{label}</label>
    <div className='view-label'>
      {formatCurrency(value)}
    </div>
  </div>
);

export const ViewOnlyDatePickerField = ({ fieldName, label, value }) => (
  <div className="mb-2">
    <label className="form-label" htmlFor={`formrow-${fieldName}-input`}>{label}</label>
    <div className='view-label'>{moment(value).format("MM/DD/YYYY")}</div>
  </div>
);

export const ViewDiv = ({ fieldLabel, fieldValue, type = "text", dateFormat = "MM/DD/YYYY", logValue = null , extra = false, depValue  }) => {
  function ensureProtocol(url) {
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  }


  return (
    <div className='row ml-negative-2 mb-2' >

      <div className='col-lg-5  col-md-6 col-sm-6 col-6'>
        <label className="inv-lable" htmlFor={`formrow-input`}>{fieldLabel}</label>
      </div>
      {type === "text-area" && <div className='col-lg-11 col-md-11 col-sm-11 col-11'>
        <div className='inv-value' >{fieldValue}</div>
      </div>}

      {type === "url" && <div className='col-lg-11 col-md-11 col-sm-11 col-11'>
        <div className='inv-value' ><a href={ensureProtocol(fieldValue)} target="_blank" rel="noopener noreferrer">{fieldValue}</a></div>
      </div>}

      <div className='col-lg-6  col-md-6  col-sm-6 col-6'>
        <div className='inv-value'>
          {
            (type === "text" && fieldValue != null && fieldValue !== 'null') ? fieldValue : '' ||
              (type === "date" && fieldValue !== null && fieldValue !== '' ? moment(fieldValue).format(dateFormat) : '') ||
              (type === "currency" && formatCurrency(fieldValue)) || ''
          } {extra && depValue && ` (${depValue}%)`}
          {logValue && <span className='additional-date'> {moment(logValue, 'YYYY-MM-DD HH:mm:ss.SSSZ').utc().format(dateFormat)}</span>}
        </div>
      </div>
    </div>
  )
}

// export const ViewDiv = ({ fieldLabel, fieldValue, type = "text", dateFormat = "MM/DD/YYYY", logValue = null }) => {
//   return (
//     <div className='row ml-negative-2 mb-2' >
//       <div className='col-lg-6  col-md-6 col-sm-6 col-6'>
//         <label className="inv-lable" htmlFor={`formrow-input`}>{fieldLabel}</label>
//       </div>
//       <div className='col-lg-6  col-md-6  col-sm-6 col-6'>
//         <div className='row'>
//           <div className='col-lg-8 inv-value'>
//             {
//               (type === "text" && fieldValue) ||
//               (type === "date" && moment(fieldValue).format(dateFormat)) ||
//               (type === "currency" && formatCurrency(fieldValue)) || ''
//             }
//           </div>
//           <div className='col-4'>
//             <p >(05-24-2024)</p>
//           </div>
//         </div>

//       </div>

//     </div>
//   )
// }

export const EmailField = ({ viewMode = false, fieldName, label, value, isRequired = true, disabled = false }) => {
  return (
    <div className="mb-2">
      <label className="form-label" htmlFor={`formrow-${fieldName}-input`}>{label}
        {isRequired && !viewMode ? <span className='man-start'>*</span> : ''}</label>
      {!viewMode && <Field className="form-control" id={`formrow-${fieldName}-input`} name={fieldName} component={EmailInput} disabled={disabled} />}
      {viewMode && <div className='view-label'>{value}</div>}
      <ErrorMessage name={fieldName} component="div" className="error-message" />
    </div>
  )
};
