
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from "../../../components/Footer";
import { Formik, Form } from 'formik';
import { useLocation } from 'react-router-dom';
import { PageLoader } from '../../../Context/PageLoader';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { get, post, put, remove } from '../../../api'
import {
    CREATE_MINERAL_ASSET, GET_MINERAL_ASSET, UPDATE_MINERAL_ASSET, ARCHIVE_INVESTMENT_BY_ID,
    DELETE_INVESTMENT_BY_ID, GET_REMINDER_LIST
} from '../../../api/endpoints'
import { checkPermission, concatUrlWithId, notify, TitleTrim, ReminderTaskIcon, formatDateAndTime } from '../../../utils/commonUtils';
import { FormField, SelectField, CurrencyField, TextAreaField, DatePickerField, SystemInfo, RadioField, ViewDiv, EINField } from '../../../components/Forms/FormFields';
import TabComponent from '../../../components/TabComponent';
import { UserPermissionStore } from '../../../Store/UserPermission';
import ProfessionalContacts from '../../../components/ProfessionalContacts';
import ReminderTab from '../../../components/Reminder/ReminderTab';
import { useReactToPrint } from 'react-to-print';
import PasswordConfirmation from '../../../components/Forms/PasswordConfirmation';

export const investmentSchema = Yup.object().shape({
    investment_name: Yup.string()
        .label('Legal Entity Name').trim().required('Legal Entity Name is required'),
    // fixed_income: Yup.boolean().label('Fixed Income').required('Fixed Income is required'),
    amount_invested: Yup.string()
        .matches(/^\d{1,13}(\.\d{1,2})?$/, 'Invalid number format. Use up to 13 digits before the decimal and up to 2 digits after.')
        .required('Amount Invested is required'),
    // amount_borrowed: Yup.string()
    //     .matches(/^\d{1,13}(\.\d{1,2})?$/, 'Invalid number format. Use up to 13 digits before the decimal and up to 2 digits after.').required('Amount Borrowed is required'),
    type: Yup.string().label('Type').required('Type is required'),
    percentage_interest: Yup.string().label('Percentage Interest').trim().required('Percentage Interest is required'),
    // royalty_percentage: Yup.string().label('Royalty Percentage').trim().required('Royalty Percentage is required'),

    // lease_date: Yup.date().label('Leased/Licensed Date').required('Leased/Licensed Date id required'),
    current_value: Yup.string().matches(/^\d{1,13}(\.\d{1,2})?$/, 'Invalid number format. Use up to 13 digits before the decimal and up to 2 digits after.').required('Current value is required'),
    ein: Yup.string().matches(/^\d{2}-\d{7}$/, 'EIN must be in the format XX-XXXXXXX').nullable(true)
    // total_acreage: Yup.string()
    //     .matches(/^\d{1,13}(\.\d{1,2})?$/, 'Invalid number format. Use up to 13 digits before the decimal and up to 2 digits after.')
    //     .required('Total Acreage is required'),
    // leased: Yup.string().label('Leased or Licensed').trim().required('Leased or Licensed is required'),
    // operator: Yup.string().label('Lessee').trim().required('Lessee is required'),

});


function AddMineralAsset({ props }) {

    const { investmentID } = useParams();
    const pathname = window.location.pathname;
    const parts = pathname.split('/');
    const urlName = parts[1];

    const [initialValues, setInitialValues] = useState({
        investment_name: '',
        is_active: true,
        amount_borrowed: '',
        amount_invested: '',
        investment_category: process.env.REACT_APP_INVESTMENT_MINERAL_ASSET_MASTER_ID,
        investment_value: '',
        current_value: '',
        fixed_income: false,
        note: '',
        is_active: '',
        type: '',
        leased: '',
        operator: '',
        lease_date: '',
        total_acreage: '',
        percentage_interest: '',
        royalty_percentage: '',
        state_country_of_incorporation: '',
        ein: '',
        contingent_liability: '',
    });
    const [editMode, setEditMode] = useState(false);
    const [listEditMode, setListEditMode] = useState(false);
    const [viewMode, setViewMode] = useState(false);
    const history = useNavigate();
    const location = useLocation();
    const { loading, setLoading } = useContext(PageLoader);
    const [isSaveNew, setIsSaveNew] = useState(false);
    const [isFieldClear, setIsFieldClear] = useState(false);
    const [viewAction, setViewAction] = useState(true);
    const [previousPage, setPreviousPage] = useState('');
    const [previousUrl, setPreviousUrl] = useState('');
    const [userPermissions, setUserPermissions] = useState([]);
    const { checkAndUpdatePermissions } = UserPermissionStore();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedType, setSelectedType] = useState('');
    const [contacts, setContacts] = useState([]);
    const [printMode, setPrintMode] = useState(false);
    const formRef = useRef();
    const [showReminderModel, setShowReminderModel] = useState(false);
    const [showReminderTask, setShowReminderTask] = useState(false);
    const [reminderTaskCount, setReminderTaskCount] = useState(0);
    const category_id = process.env.REACT_APP_INVESTMENT_MINERAL_ASSET_MASTER_ID;
    const [showPasswordModel, setShowPasswordModel] = useState(false);
    const [deleteItem, setDeleteItem] = useState({ id: '', name: '' })

    const [showErrorIcon, setShowErrorIcon] = useState(false);

    function handleOptionChange(event, allValues) {
        const updatedValues = { ...allValues, leased: event };
        setInitialValues(updatedValues);
    };

    function ChangeEditmode(id) {
        setLoading(true);
        history(`/edit-royalty-income/${investmentID}`, { state: { previousPage: "Royalty Income View", previousUrl: `/view-royalty-income/${investmentID}` } });
    }

    const revertDelete = () => {
        setDeleteItem({ id: '', name: '' })
    }

    function deleteMineralAsset(id, Name) {
        confirmAlert({
            title: 'Delete',
            message: `Are you sure you want to delete the ${Name}?`,
            buttons: [
                {
                    label: 'Yes',
                    // onClick: () => handleDelete(id, Name)
                    onClick: () => {
                        setDeleteItem({ id: id, name: Name });
                        setShowPasswordModel(true)
                    }
                },
                {
                    label: 'No',
                    onClick: revertDelete()
                }
            ]
        });

    }

    const handleBackClick = (investmentId, pageUrl) => {
        const previousUrl = document.referrer; // Get the referring URL
        const currentUrl = window.location.pathname; // Get the current URL path

        if (previousUrl.includes('portfolio-summary') && !currentUrl.includes('edit-royalty-income')) {
            history('/portfolio-summary');
        } else if (pageUrl) {
            history(pageUrl);
        }
        else {
            history('/royalty-income-list');
        }
    };
    const handleDelete = async () => {
        const { id, name } = deleteItem;
        try {
            setLoading(true)
            const deleteInvestment = await remove(concatUrlWithId(DELETE_INVESTMENT_BY_ID, id));
            if (deleteInvestment) {
                setShowPasswordModel(false)
                history('/royalty-income-list')
            }
            setShowPasswordModel(false)
            notify(`${name} successfully deleted`, 'success')
        } catch (error) {
            setShowPasswordModel(false)
            console.log('Error while deleting the error : ', error)
            notify(`Failed to delete ${name}`, 'error')
        }

    };

    const archivedMineralAsset = (investment_id, investment_name) => {
        confirmAlert({
            title: 'Archive',
            message: `Are you sure want to Archive ${investment_name}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleArchived(investment_id, investment_name)
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    const handleArchived = async (investment_id, investment_name) => {
        try {
            setLoading(true)
            const archive = await put(concatUrlWithId(ARCHIVE_INVESTMENT_BY_ID, investment_id), { is_archived: true })

            if (archive) {
                history('/royalty-income-list')
            }
            notify(`${investment_name} has been archived.`, 'warning',)
        } catch (error) {
            console.log('Error while deleteing the error : ', error)
            notify(`Failed to archive ${investment_name}`, 'error')

        }

    };

    const notify = (message, type) => toast(message, { type });

    const authCheckAndUpdate = async () => {
        let tempPermission = await checkAndUpdatePermissions();
        setUserPermissions(tempPermission || []);
    };


    useEffect(() => {
        // window.scrollTo(0, 0);
        // Auth check and update
        authCheckAndUpdate();

        if (urlName == 'view-royalty-income') {
            setViewMode(true)
            setEditMode(false);
            setViewAction(true);
            setPreviousPage('Royalty Income List')
            setPreviousUrl('/royalty-income-list')
            // if((!checkPermission(userPermissions, 'isAdmin') && !checkPermission(userPermissions, 'isManager')) ){
            //     console.log('check=========',checkPermission(userPermissions, 'view-stock'),userPermissions);
            //     if(!checkPermission(userPermissions, 'view-mineral-asset') ){
            //         notify("Sorry, you do not have permission to access this.", 'warning')
            //         history('/portfolio-summary')
            //     }
            // }
        } else if (urlName == 'edit-royalty-income') {
            setViewMode(false)
            setEditMode(true)
            setViewAction(false)
            setPreviousPage('Royalty Income List')
            setPreviousUrl('/royalty-income-list')
            // if((!checkPermission(userPermissions, 'isAdmin') && !checkPermission(userPermissions, 'isManager')) ){
            //     if(!checkPermission(userPermissions, 'view-mineral-asset') ){
            //         notify("Sorry, you do not have permission to access this1.", 'warning')
            //         history('/portfolio-summary')
            //     }else if(!checkPermission(userPermissions, 'edit-mineral-asset') ){
            //         notify("Sorry, you do not have permission to access this.", 'warning')
            //         history('/stock-list')
            //     }
            // }
        }

        const fetchInvestmentById = async () => {
            if (location.state) {
                setPreviousPage(location.state.previousPage)
                setPreviousUrl(location.state.previousUrl)
            } else {
                setLoading(false);
            }
            if ((urlName == 'edit-royalty-income') || (urlName == 'view-royalty-income')) {

                try {
                    setLoading(true);
                    const response = await get(concatUrlWithId(GET_MINERAL_ASSET, investmentID));
                    if (response) {
                        let values = {
                            ...response,
                            lease_date: formatDateAndTime(response['mineral_asset_details.lease_date'], 'YYYY-MM-DD'),
                            current_value_updated_date: moment(response.current_value_updated_date).format('MM/DD/YYYY'),
                            investment_id: response.id,
                            investment_value: response['mineral_asset_details.investment_value'],
                            investment_detail_id: response['mineral_asset_details.id'],
                            type: response['mineral_asset_details.type'],
                            leased: response['mineral_asset_details.leased'],
                            operator: response['mineral_asset_details.operator'],
                            total_acreage: response['mineral_asset_details.total_acreage'],
                            percentage_interest: response['mineral_asset_details.percentage_interest'],
                            royalty_percentage: response['mineral_asset_details.royalty_percentage'],
                            auditLog: response?.investmentAuditLog?.investment_headers?.current_value,
                            investmentAuditLog: response?.investmentAuditLog?.investment_headers?.amount_invested,
                            state_country_of_incorporation: response['mineral_asset_details.state_country_of_incorporation'] || '',
                            ein: response['ein']
                        };
                        setContacts(response?.professional_contacts);
                        setSelectedType(values.type);
                        getReminderTaskCount()
                        setInitialValues(values);

                        if (response.permission_keys?.length && response.permission_keys?.[0]?.permission_keys) {
                            setUserPermissions(response.permission_keys[0].permission_keys)
                        }

                        if (response.is_archived == true) {
                            setViewAction(false)
                            setPreviousPage('Archived List')
                            setPreviousUrl('/archived-list')
                        }
                    }
                } catch (error) {
                    window.scrollTo(0, 1);
                    console.log('Error while throwing:: ', error);
                    notify(error?.response?.data?.message, 'error');
                } finally {
                    window.scrollTo(0, 1);
                    setLoading(false);
                }
            } else {
                window.scrollTo(0, 1);
                setLoading(false);
            }
        }
        fetchInvestmentById();

    }, [location.state]);


    const handleTypeChange = (value) => {
        setSelectedType(value);
    };
    // Reminder change start

    const handleReminderModelClose = () => {
        setShowReminderModel(false);
    };

    const handelListUpdate = () => {
        // console.log('values ;::')
    }

    const getReminderTaskCount = async () => {

        let payload = {
            investment_id: investmentID
        };
        const reminderTasks = await post(GET_REMINDER_LIST, payload);

        if (reminderTasks?.data?.reminderList?.length) {
            setReminderTaskCount(reminderTasks?.data?.reminderList?.length)
        } else {
            setReminderTaskCount(0)
        }
    }
    // Reminder change end 

    const mineralAssetType = [
        { value: 'Licensing', label: 'Licensing' },
        { value: 'Minerals', label: 'Minerals' },
        { value: 'Music', label: 'Music' },
        { value: 'Patents', label: 'Patents' },
        { value: 'Published', label: 'Published' },
        { value: 'Trademark', label: 'Trademark' },
    ];

    const handleCollectedContacts = (updatedContacts) => {
        setContacts(updatedContacts);
    };

    const updateReminderData = async () => {
        await getReminderTaskCount();
    }

    const handleBeforePrint = () => {
        setPrintMode(true);
    };
    const checkValidation = () => {
        setShowErrorIcon(true);
    }


    const handleAfterPrint = () => {
        setPrintMode(false);
        setLoading(false);
    };

    const handlePrint = useReactToPrint({
        content: () => formRef.current,
        onBeforeGetContent: handleBeforePrint,
        onAfterPrint: handleAfterPrint,
        documentTitle: initialValues.investment_name || 'investment'
    });

    const handleChangePrint = () => {
        confirmAlert({
            title: 'Alert',
            message: `For better viewing, please use landscape mode.`,
            buttons: [
                {
                    label: 'Done',
                    onClick: () => { setPrintMode(true); setLoading(true) }
                },
            ]
        });
    }

    const onDataLoadingComplete = () => {
        setLoading(false);
        setTimeout(() => {
            handlePrint()
        }, 100);
    }

    useEffect(() => {
        if (printMode) {
            setTimeout(() => {
                handlePrint()
            }, 100);
        };
    }, [printMode])
    return (
        <>
            <button id="update-remainder-data" onClick={() => updateReminderData()} style={{ display: 'none' }}> Click me </button>
            <div className="container-fluid" style={{ marginTop: '20px' }}>
                <div className="col-12" style={{ padding: '15px' }}>
                    <div className="card">
                        <div className="card-body card-breadcrumb">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <a href="#" onClick={() => handleBackClick(initialValues?.id, previousUrl)} className="btn btn-light mb-2 me-2" style={{ display: 'flex', alignItems: 'center' }}><i className="arrow_left"></i>
                                    Back</a>
                                {<div className="page-title-right title-prefix" >
                                    {`${editMode ? 'Edit -' : (viewMode ? 'View -' : '')}`} <TitleTrim title={initialValues.investment_name} />
                                </div>}
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="#" onClick={() => handleBackClick(initialValues?.id, previousUrl)}>{previousPage}
                                        </a></li>
                                        <li className="breadcrumb-item active" >{editMode ? 'Edit Royalty Income' : (viewMode ? 'View Royalty Income' : 'Add Royalty Income')}</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div ref={formRef}>
                {((editMode && initialValues?.id) || (viewMode && initialValues?.id) || !(viewMode || editMode)) && <Formik

                    initialValues={initialValues}
                    validationSchema={investmentSchema}
                    onSubmit={async (values, actions) => {
                        setIsSubmitting(true);
                        try {
                            let response = []
                            const url = editMode ? concatUrlWithId(UPDATE_MINERAL_ASSET, investmentID) : CREATE_MINERAL_ASSET;
                            const bodyPayload = {
                                investment_name: values.investment_name,
                                amount_borrowed: values.amount_borrowed,
                                amount_invested: values.amount_invested,
                                investment_category: values.investment_category,
                                current_value: values.current_value,
                                note: values.note,
                                fixed_income: values.fixed_income,
                                type: values.type,
                                lease_date: values.lease_date,
                                leased: values.leased,
                                operator: values.operator,
                                total_acreage: values.total_acreage,
                                percentage_interest: values.percentage_interest,
                                royalty_percentage: values.royalty_percentage,
                                professional_contacts: contacts,
                                quarter1_pd: values.quarter1_pd,
                                quarter2_pd: values.quarter2_pd,
                                quarter3_pd: values.quarter3_pd,
                                quarter4_pd: values.quarter4_pd,
                                state_country_of_incorporation: values.state_country_of_incorporation || '',
                                ein: values.ein,
                                contingent_liability: values.contingent_liability

                            };

                            response = editMode ? await put(url, bodyPayload) : await post(url, bodyPayload);

                            if (response) {

                                notify(`Royalty Income ${values.investment_name} ${editMode ? "Updated" : "Added"} Successfully...`, 'success')

                                if (isSaveNew == true) {
                                    actions.resetForm();
                                    setIsFieldClear((prevState) => prevState ? prevState + 1 : 1);
                                    setShowErrorIcon(false)
                                    setContacts([]);
                                } else {
                                    if (previousUrl) {
                                        history(previousUrl);
                                    } else {
                                        history('/royalty-income-list', { state: { status: editMode ? "update" : "create" } });
                                        setLoading(true);
                                    }
                                }
                            } else {
                                editMode ? notify(`Royalty Income update failed...`, 'error') : notify(`Create Royalty Income failed...`, 'error');
                            }
                        } catch (error) {
                            notify(error?.response?.data?.message, 'error');

                            console.error('API Error:', error);
                        }
                        setIsSubmitting(false);
                    }}
                    enableReinitialize
                >
                    {props => (<div className="main-content introduction-farm">
                        <div className="content-wraper-area">
                            <div className="dashboard-area">
                                <div className="container-fluid">
                                    <div className="card">
                                        <div className={`card-body ${viewMode ? 'view-mode' : 'add-mode'}`}>
                                            {!viewAction && viewMode && <div className='no-print' style={{ textAlign: 'center' }}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Print</Tooltip>} >
                                                        <i className="fa fa-print btn-circle print-btn" onClick={() => { handleChangePrint() }} ></i>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                            }
                                            {viewAction && viewMode && <div style={{ textAlign: 'center' }}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Print</Tooltip>} >
                                                        <i className="fa fa-print btn-circle print-btn no-print" onClick={() => { handleChangePrint(); }} ></i>
                                                    </OverlayTrigger>
                                                    {<ReminderTaskIcon
                                                        iconClass="no-print"
                                                        tooltip={`Set/View Reminders `}
                                                        onClick={() => setShowReminderTask(true)}
                                                        backgroundColor="#8098ad"
                                                        count={reminderTaskCount}
                                                    />}
                                                    {/* {<OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Set Reminder</Tooltip>} >
                                                    <i className="zmdi zmdi-alarm-plus btn btn-circle" onClick={() => setShowReminderModel(true)} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: '#359bff', cursor: 'pointer' }} ></i>
                                                </OverlayTrigger>} */}
                                                    {(checkPermission(userPermissions, 'edit-mineral-asset') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Edit Royalty Income</Tooltip>} >
                                                        <i className="bx bxs-edit btn btn-circle no-print" onClick={() => ChangeEditmode(initialValues?.id)} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: '#58ae25', cursor: 'pointer' }} ></i>
                                                    </OverlayTrigger>}
                                                    {(checkPermission(userPermissions, 'archive-mineral-asset') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Archive Royalty Income</Tooltip>} >
                                                        <i className="zmdi zmdi-archive btn btn-circle no-print" onClick={() => archivedMineralAsset(initialValues?.id, initialValues?.investment_name)} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: '#74788d', cursor: 'pointer' }} ></i>
                                                    </OverlayTrigger>}
                                                    {(checkPermission(userPermissions, 'delete-mineral-asset') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Delete Royalty Income</Tooltip>} >
                                                        <i className="bx bx-trash btn btn-circle no-print" onClick={() => deleteMineralAsset(initialValues?.id, initialValues?.investment_name)} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: '#dd4a57', cursor: 'pointer' }}></i>
                                                    </OverlayTrigger>}
                                                </div>
                                            </div>
                                            }
                                            <Form>
                                                {printMode && <div className="card-title only-visible-in-print" style={{ marginBottom: '5px' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'center', }}>
                                                        <h4 className='heading-print text-bold' style={{ wordWrap: 'break-word', maxWidth: '300px' }}>Royalty Income - {initialValues.investment_name}</h4>
                                                    </div>
                                                </div>}
                                                <div className='row print-font-header'>
                                                    {!viewMode && <div className='col-lg-6'>
                                                        <fieldset>
                                                            <legend className='text-bold'> Royalty Income Information </legend>

                                                            <FormField viewMode={viewMode} fieldName="investment_name" label="Legal Entity Name" value={initialValues.investment_name} maxLength={200} />
                                                            <EINField viewMode={viewMode} fieldName="ein" label="EIN" value={initialValues.ein} maxLength={10} isRequired={false} />
                                                            <SelectField viewMode={viewMode} fieldName="type" label="Type" value={initialValues.type} options={mineralAssetType} values={props.values} setFieldValue={(field, val) => {
                                                                props.setFieldValue('type', val);
                                                                handleTypeChange(val);
                                                            }} />
                                                            <FormField viewMode={viewMode} fieldName="state_country_of_incorporation" label="State/Country of Incorporation" value={initialValues.state_country_of_incorporation} maxLength={200} isRequired={false} />

                                                            <RadioField viewMode={viewMode} fieldName="leased" label="Leased or Licensed" value={initialValues.leased}
                                                                isRequired={false} setFieldValue={(field, val) => {
                                                                    props.setFieldValue('leased', val);
                                                                    handleOptionChange(val, props.values);
                                                                }} options={[{ label: "YES", value: "YES" }, { label: "NO", value: "NO" }]} />
                                                            <FormField viewMode={viewMode} fieldName="operator" label="Lessee" value={initialValues.operator} maxLength={100} isRequired={false} />

                                                            {selectedType == 'Minerals' && <CurrencyField viewMode={viewMode} fieldName="total_acreage" label="Total Acreage" values={props.values} setFieldValue={props.setFieldValue} prefix={''} isRequired={false} />}

                                                            <DatePickerField viewMode={viewMode} fieldName="lease_date" label="Leased/Licensed Date" value={initialValues.lease_date} setFieldValue={props.setFieldValue} isFieldClear={isFieldClear} isRequired={false} />
                                                        </fieldset>
                                                    </div>}
                                                    {viewMode && <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                                        <div>
                                                            <legend className='text-bold lenged-view'> Royalty Income Information </legend>
                                                            <ViewDiv fieldLabel={"Legal Entity Name"} fieldValue={initialValues.investment_name} type={"text"} />
                                                            <ViewDiv fieldLabel={"EIN"} fieldValue={initialValues.ein} type={"text"} />
                                                            <ViewDiv fieldLabel={"Type"} fieldValue={initialValues.type} type={"text"} />
                                                            <ViewDiv fieldLabel={"Leased or Licensed"} fieldValue={initialValues.leased} type={"text"} />
                                                            <ViewDiv fieldLabel={"State/Country of incorporation"} fieldValue={initialValues.state_country_of_incorporation} type={"text"} />
                                                            {selectedType == 'Minerals' && <ViewDiv fieldLabel={"Total Acreage"} fieldValue={initialValues.total_acreage} type={"currency"} />}
                                                            <ViewDiv fieldLabel={"Lessee"} fieldValue={initialValues.operator} type={"text"} />
                                                            <ViewDiv fieldLabel={"Leased/Licensed Date"} fieldValue={initialValues.lease_date} type={"date"} />

                                                            <legend className='text-bold lenged-view'> Additional Information </legend>
                                                            <ViewDiv fieldLabel={"Notes"} fieldValue={initialValues.note} type={"text-area"} />

                                                            <legend className='text-bold lenged-view'> System Information</legend>
                                                            <ViewDiv fieldLabel={"Created At"} fieldValue={initialValues.createdAt} type={"date"} dateFormat={'MM/DD/YYYY hh:mm a'} />
                                                            <ViewDiv fieldLabel={"Last Modified At"} fieldValue={initialValues.updatedAt} type={"date"} dateFormat={'MM/DD/YYYY hh:mm a'} />
                                                            <ViewDiv fieldLabel={"Created By"} fieldValue={initialValues['investment_headers_created_by.full_name']} type={"text"} />
                                                            <ViewDiv fieldLabel={"Last Modified By"} fieldValue={initialValues['investment_headers_modified_by.full_name']} type={"text"} />
                                                        </div>
                                                    </div>}

                                                    {viewMode && <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                                        <div>
                                                            <legend className='text-bold lenged-view'> Investment Details </legend>
                                                            <ViewDiv fieldLabel={"Amount Invested($)"} fieldValue={initialValues.amount_invested} type={"currency"} />
                                                            <ViewDiv fieldLabel={"Amount Borrowed($)"} fieldValue={initialValues.amount_borrowed} type={"currency"} extra={true} depValue={initialValues.contingent_liability} />
                                                            <ViewDiv fieldLabel={"Current Value($)"} fieldValue={initialValues.current_value} type={"currency"} />
                                                            <ViewDiv fieldLabel={"Percentage Interest(%)"} fieldValue={initialValues.percentage_interest} type={"text"} />
                                                            <ViewDiv fieldLabel={"Royalty Percentage(%)"} fieldValue={initialValues.royalty_percentage} type={"text"} />

                                                            <legend className='text-bold lenged-view'>Cash Flow</legend>
                                                            <ViewDiv fieldLabel={"1 st Quarter Projected Distributions($)"} fieldValue={initialValues.quarter1_pd} type={'currency'} />
                                                            <ViewDiv fieldLabel={"2 st Quarter Projected Distributions($)"} fieldValue={initialValues.quarter2_pd} type={'currency'} />
                                                            <ViewDiv fieldLabel={"3 st Quarter Projected Distributions($)"} fieldValue={initialValues.quarter3_pd} type={'currency'} />
                                                            <ViewDiv fieldLabel={"4 st Quarter Projected Distributions($)"} fieldValue={initialValues.quarter4_pd} type={'currency'} />
                                                            {!printMode &&
                                                                <>
                                                                    <legend className="text-bold lenged-view">Professional Contacts</legend>
                                                                    <div className="mb-2">
                                                                        <ProfessionalContacts viewAction={viewMode} contactList={contacts} CollectedContact={handleCollectedContacts} />
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>}
                                                    {(viewMode) &&
                                                        <div className='col-12 only-visible-in-print'>
                                                            <legend className="text-bold lenged-view">Professional Contacts</legend>
                                                            <div className="mb-2">
                                                                <ProfessionalContacts viewAction={viewMode} contactList={contacts} CollectedContact={handleCollectedContacts} />
                                                            </div>
                                                        </div>
                                                    }

                                                    {!viewMode && <div className='col-lg-6'>
                                                        <fieldset>
                                                            <legend className='text-bold'> Investment Details </legend>

                                                            <CurrencyField viewMode={viewMode} fieldName="amount_invested" label="Amount Invested" values={props.values} setFieldValue={props.setFieldValue} auditLog={initialValues.investmentAuditLog ? true : false} auditValue={initialValues.investmentAuditLog} />

                                                            <div className='row'>
                                                                <div className='col-lg-6'>
                                                                    <CurrencyField viewMode={viewMode} fieldName="amount_borrowed" label="Amount Borrowed" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} />
                                                                </div>
                                                                <div className='col-lg-6'>
                                                                    <CurrencyField viewMode={viewMode} fieldName="contingent_liability" label="Contingent Liability(%)" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} prefix='' />
                                                                </div>
                                                            </div>

                                                            <CurrencyField viewMode={viewMode} fieldName="current_value" label="Current Value" values={props.values} setFieldValue={props.setFieldValue} auditLog={initialValues.auditLog ? true : false} auditValue={initialValues.auditLog} />

                                                            <CurrencyField viewMode={viewMode} fieldName="percentage_interest" label="Percentage Interest(%)" values={props.values} setFieldValue={props.setFieldValue} prefix={''} disableNegative={true} />

                                                            <CurrencyField viewMode={viewMode} fieldName="royalty_percentage" label="Royalty Percentage(%)" values={props.values} setFieldValue={props.setFieldValue} prefix={''} isRequired={false} disableNegative={true} />

                                                        </fieldset>
                                                    </div>}
                                                    {!viewMode && <div className='col-lg-12'>
                                                        <fieldset>
                                                            <legend className='text-bold'> Additional Information</legend>

                                                            <TextAreaField viewMode={viewMode} fieldName="note" label="Notes" value={initialValues.note} maxLength={500} textAlign={true} />
                                                        </fieldset>
                                                    </div>}

                                                    {!viewMode && <div className='col-lg-12'>

                                                        <fieldset>
                                                            <legend className='text-bold'> Cash Flow </legend>
                                                            <div className='row'>
                                                                <div className='col-lg-6'>
                                                                    <CurrencyField viewMode={viewMode} fieldName="quarter1_pd" label="1st Quarter Projected Distributions" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} disableNegative={true} />
                                                                    <CurrencyField viewMode={viewMode} fieldName="quarter3_pd" label="3rd Quarter Projected Distributions" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} disableNegative={true} />
                                                                </div>
                                                                <div className='col-lg-6'>
                                                                    <CurrencyField viewMode={viewMode} fieldName="quarter2_pd" label="2nd Quarter Projected Distributions" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} disableNegative={true} />
                                                                    <CurrencyField viewMode={viewMode} fieldName="quarter4_pd" label="4th Quarter Projected Distributions" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} disableNegative={true} />
                                                                </div>
                                                            </div>
                                                        </fieldset>

                                                    </div>}


                                                    {!viewMode && <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                        <fieldset>
                                                            <legend className="text-bold">Professional Contacts</legend>
                                                            <div className="mb-2">
                                                                <ProfessionalContacts contactList={contacts} CollectedContact={handleCollectedContacts} />
                                                            </div>
                                                        </fieldset>
                                                    </div>}


                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            gap: '10px'
                                                        }}>
                                                            {!viewMode && showErrorIcon && !props.isValid && <><OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Please find the page error</Tooltip>} >
                                                                <i className="zmdi zmdi-block-alt" style={{ fontSize: '19px', margin: '0px 5px', padding: '8px 0px 0px 0px', color: 'red', cursor: 'pointer' }} ></i>
                                                            </OverlayTrigger></>}
                                                            {/* {!viewMode && <button type="reset" onClick={() => history('/royalty-income-list')} className="btn btn-secondary w-md">{viewMode ? 'Back' : 'Cancel'}</button>} */

                                                                !viewMode && <button type="reset" onClick={() => handleBackClick(initialValues?.id, previousUrl)} className="btn btn-secondary w-md">{viewMode ? 'Back' : 'Cancel'}</button>}

                                                            {!viewMode && <button type="submit" onClick={() => { setIsSaveNew(false); checkValidation(); }} disabled={isSubmitting} className="btn btn-primary w-md">{editMode ? 'Update' : 'Save'}</button>}
                                                            {editMode != true && (
                                                                !viewMode && <button type="submit" onClick={() => { setIsSaveNew(true); checkValidation(); }} className="btn btn-primary w-md">{editMode ? 'create' : 'Save and New'}</button>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                            {/* <div className='row'>
                                            {viewMode && <Documents investmentId={initialValues?.id}
                                                uploadPermission={checkPermission(userPermissions, 'upload-mineral-asset') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')}
                                            />}
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <PasswordConfirmation showViewModal={showPasswordModel} handleDelete={handleDelete} handleCloseViewModal={() => { setShowPasswordModel(false); revertDelete() }} />
                        {/* <ReminderTaskModel show={showReminderTask} handleClose={() => setShowReminderTask(false)} investmentID={investmentID} handelListUpdate={getReminderTaskCount} />
                    <ReminderList show={showReminderModel} recordId={investmentID} handleClose={handleReminderModelClose} entityType={'investment'} headerName={'Reminder'} invesmentCat={category_id} handelListUpdate={getReminderTaskCount} /> */}
                        <ReminderTab show={showReminderTask} recordId={investmentID} handleClose={() => setShowReminderTask(false)} hangleTabChange={getReminderTaskCount} invesmentCatId={category_id}
                            entityType={'investment'} isAdminOrManager={(checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager'))} actionPermission={checkPermission(userPermissions, 'edit-mineral-asset')} />
                        <div className="card-body">
                            {viewMode && userPermissions?.length && !printMode && <TabComponent investmentId={initialValues?.id} permissions={userPermissions} viewAction={viewAction}
                                uploadPermission={checkPermission(userPermissions, 'upload-mineral-asset') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')} isArchived={!viewAction} documentTypeKey={'Royalty Income'} printMode={printMode} onDataLoadingComplete={onDataLoadingComplete} />}
                        </div>
                        {(userPermissions?.length && checkPermission(userPermissions, 'isManager') && (editMode || viewMode)) && <Footer PropUserPermissions={userPermissions} />}
                    </div>
                    )}
                </Formik >
                }
            </div>
        </>
    );
}
export default AddMineralAsset;