import React, { useState, useEffect, useContext } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { UserPermissionStore } from '../Store/UserPermission';
import { CREATE_TRUSTEE_INFORMATION, GET_TRUSTEE_INFORMATION, UPDATE_TRUSTEE_INFORMATION } from '../api/endpoints';
import { get, post, put} from '../api/index'
import { checkPermission, concatUrlWithId } from '../utils/commonUtils';
import { PageLoader } from '../Context/PageLoader';

const TrusteeInstructions = () => {
    const { loading, setLoading } = useContext(PageLoader);
    const [content, setContent] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [userPermissions, setUserPermissions] = useState([]);
    const { checkAndUpdatePermissions } = UserPermissionStore();
    const [id, setId] = useState('');
    const [canEdit, setCanEdit] = useState(false);

    const fetchTrusteeInfo = async (permissions) => {
        try {
            const response = await get(GET_TRUSTEE_INFORMATION);
            if (response && Array.isArray(response.trusteeData) && response.trusteeData.length > 0) {
                setContent(response.trusteeData[0].notes);
                setId(response.trusteeData[0].id);
                setIsEditing(false);
            } else if (checkPermission(permissions, 'isAdmin') || checkPermission(permissions, 'isManager') || canEdit) {
                setIsEditing(true);
            }
            setLoading(false)
        } catch (error) {
            console.error('Error fetching trustee information:', error);
            if (canEdit) {
                setIsEditing(true);
            }
            setLoading(false)

        }
    };

    const saveTrusteeInfo = async () => {
        try {
            setLoading(true)
            const payload = {
                notes: content,
                role: userPermissions[0],
                ID: id || '',
            };

            const response = id ? await put(concatUrlWithId(UPDATE_TRUSTEE_INFORMATION, id), payload) : await post(CREATE_TRUSTEE_INFORMATION, payload);

            if (response.status === 200) {
                console.log(`Trustee information ${id ? 'updated' : 'created'} successfully`);
                setIsEditing(false);
                setLoading(false)
            } else {
                console.error(`Failed to ${id ? 'update' : 'create'} trustee information`);
                setLoading(false)
            }
        } catch (error) {
            console.error('Error updating trustee information:', error);
            setLoading(false)

        }
    };

    const authCheckAndFetch = async () => {
        const permissions = await checkAndUpdatePermissions();
        setUserPermissions(permissions || []);

        const isAdmin = await checkPermission(permissions, 'isAdmin');
        const isManager = await checkPermission(permissions, 'isManager');
        setCanEdit(isAdmin || isManager);
        fetchTrusteeInfo(permissions);
    };

    useEffect(() => {
        setLoading(true)
        authCheckAndFetch();
    }, []);

    const handleEditToggle = () => {
        if (isEditing) {
            saveTrusteeInfo();
        } else if (canEdit) {
            setIsEditing(true);
        }
    };

    const handleChange = (value) => {
        setContent(value);
    };

    return (
        <div className="main-content introduction-farm">
            <div className="content-wraper-area">
                <div className="dashboard-area">
                    <div className="container-fluid">
                        <br />
                        <div className="card" style={{ height: '600px', overflow: 'hidden' /* Fixed height and content containment */ }}>
                            <div className="card-body d-flex flex-column" style={{ height: '100%' }}>
                                <div className="row">
                                    <div className="col-6">
                                        <legend className="text-bold">Trustee Instructions</legend>
                                    </div>
                                    <div className="col-6">
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                                            {canEdit && (
                                                <button
                                                    type="button"
                                                    onClick={handleEditToggle}
                                                    className={`btn  ${isEditing ? 'btn-success' : 'btn-primary'} `}
                                                >
                                                    <i className={`fa ${isEditing ? 'fa-save' : 'fa-edit'}`} /> {isEditing ? 'Save' : 'Edit'}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* Scrollable area */}
                                <div
                                    className="content-area mt-3 flex-grow-1"
                                    style={{
                                        overflowY: isEditing ? 'hidden' : 'auto', // Hide the outer scrollbar in edit mode
                                        border: '1px solid #ddd',
                                        borderRadius: '5px',
                                        backgroundColor: '#f8f9fa',
                                    }}
                                >
                                    {isEditing ? (
                                        <div
                                            className="editor-container"
                                            style={{
                                                height: '95%',
                                            }}
                                        >
                                            <ReactQuill
                                                theme="snow"
                                                value={content}
                                                onChange={handleChange}
                                                placeholder="Write your instructions here..."
                                                style={{
                                                    height: '100%',
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            className="preview-container bg-light rounded"
                                            style={{
                                                height: '100%',
                                            }}
                                        >
                                            <div
                                                className="border rounded p-3"
                                                style={{
                                                    minHeight: '100%',
                                                    backgroundColor: '#fff',
                                                }}
                                                dangerouslySetInnerHTML={{ __html: content }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default TrusteeInstructions;
