
import { useState, useContext, useEffect } from 'react';
import Footer from "../../../components/Footer";
import { PageLoader } from '../../../Context/PageLoader';
import { useNavigate, useLocation } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Datatable from '../../../components/Table/Datatable';
import DataChart from '../../../components/DataChart';
import { get, put, remove } from '../../../api';
import { GET_MINERAL_ASSETS, DELETE_INVESTMENT_BY_ID, ARCHIVE_INVESTMENT_BY_ID } from '../../../api/endpoints'
import { concatUrlWithId, checkPermission, notify, refreshCount } from '../../../utils/commonUtils';
import { UserPermissionStore } from '../../../Store/UserPermission';
import ReminderTab from '../../../components/Reminder/ReminderTab';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PasswordConfirmation from '../../../components/Forms/PasswordConfirmation';


function MineralAssetList() {
  const [investmentList, setInvestmentList] = useState([]);
  const [selectedInvList, setSelectedInvList] = useState([]);
  const [selectedChartValue, setSelectedChartValue] = useState("");
  const [userPermissions, setUserPermissions] = useState("");
  const [selectedInvID, SetSelectedInvID] = useState('')
  const [showReminderModel, setShowReminderModel] = useState(false);
  const location = useLocation();
  const locationData = location.state;
  const { loading, setLoading } = useContext(PageLoader);
  const history = useNavigate();
  const { checkAndUpdatePermissions } = UserPermissionStore();
  const [showAction, setShowAction] = useState(false);
  const [enablePrint, SetEnablePrint] = useState(false)
  const [reminderActionPermission, setReminderActionPermission] = useState(false);
  const category_id = process.env.REACT_APP_INVESTMENT_MINERAL_ASSET_MASTER_ID;

  // Reminder Task 
  const [showReminderTask, setShowReminderTask] = useState(false);
  const [getInvestmentID, setInvestmentID] = useState('');
  const [showPasswordModel, setShowPasswordModel] = useState(false);
  const [deleteItem, setDeleteItem] = useState({ id: '', name: '' })

  const reminderTaskPopUp = (investment_id, actionPermission) => {
    setInvestmentID(investment_id);
    setShowReminderTask(true);
    setReminderActionPermission(actionPermission);
  };

  const [headerValues, setHeaderValues] = useState({
    totalInvestments: 0,
    totalCurrentValues: 0,
    totalDebt: 0,
  });
  const handleClose = () => setShowReminderTask(false);

  const handelListUpdate = () => fetchInvestmentList();

  const fetchInvestmentList = async () => {
    try {
      const investmentData = await get(GET_MINERAL_ASSETS);
      const investments = investmentData.investments;
      const totals = investmentData.totals;

      if (investments) {
        const isAction = investments.some((inv) => inv.permission_keys?.length > 1);
        setShowAction(isAction);

        // Process investments for chart and list
        const chartData = investments
          .filter((value) => value.cal_current_value !== null)
          .map((value) => ({
            ...value,
            current_value: value.cal_current_value,
          }));

        setInvestmentList(chartData);
        setSelectedInvList(investments);

        // Set header values
        setHeaderValues({
          totalInvestments: totals.total_invested,
          totalCurrentValues: totals.total_current_value,
          totalDebt: totals.total_inv_aft_Debt,  // Assuming 'total_debt' is the correct key
        });

        refreshCount();
      }
    } catch (error) {
      console.error('Error while fetching data:', error);
      notify(`Failed to fetch data. Please try again later`, 'error');
    } finally {
      setLoading(false);
    }
  };


  const authCheckAndFetch = async () => {
    let tempPermission = await checkAndUpdatePermissions();
    setUserPermissions(tempPermission || []);
    if (checkPermission(tempPermission, 'view-mineral-asset') || checkPermission(tempPermission, 'isAdmin') || checkPermission(tempPermission, 'isManager')) {
      fetchInvestmentList()
    } else {
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    authCheckAndFetch();
  }, [locationData])

  const handleChartChange = (selectedValue) => {
    if (selectedChartValue == selectedValue) {
      setSelectedInvList(investmentList)
      setSelectedChartValue("")
    } else {
      let filterInvesmentList = investmentList.filter((item) => (selectedValue == item['type']));
      setSelectedChartValue(selectedValue)
      setSelectedInvList(filterInvesmentList)
    }
  }
  const handleDelete = async () => {
    const { id, name } = deleteItem;
    try {
      setLoading(true)
      let deleteInvestment = await remove(concatUrlWithId(DELETE_INVESTMENT_BY_ID, id));
      if (deleteInvestment) {
        setShowPasswordModel(false);
        fetchInvestmentList()
      }
      setShowPasswordModel(false);
      notify(`${name} successfully deleted`, 'success')
    } catch (error) {
      notify(`Unable to delete ${name}`, 'warning')
      console.log('Error while deleteing the error : ', error)
      setLoading(false);
      setShowPasswordModel(false);
    }

  };

  const handleEditClick = (investmentId) => {
    setLoading(true);
    history(`/edit-royalty-income/${investmentId}`);
  };
  const handleViewClick = (investmentId) => {
    setLoading(true);
    history(`/view-royalty-income/${investmentId}`);
  };

  const AddMineralAsset = () => {
    setLoading(true);
    history('/add-royalty-income', { state: { previousPage: "Royalty Income List", previousUrl: "/royalty-income-list" } });
  };
  const revertDelete = () => {
    setDeleteItem({ id: '', name: '' })
  }

  const deleteMineralAsset = (investment_id, investment_name) => {
    confirmAlert({
      title: 'Delete',
      message: `Are you sure you want to delete the ${investment_name}?`,
      buttons: [
        {
          label: 'Yes',
          // onClick: () => handleDelete(investment_id, investment_name)
          onClick: () => {
            setDeleteItem({id: investment_id, name: investment_name}); 
            setShowPasswordModel(true)
          }
        },
        {
          label: 'No',
          onClick: revertDelete()
        }
      ]
    });
  }

  const archivedMineralAsset = (investment_id, investment_name) => {
    confirmAlert({
      title: 'Archive',
      message: `Are you sure want to Archive ${investment_name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleArchived(investment_id, investment_name)
        },
        {
          label: 'No',
        }
      ]
    });
  }

  const handleArchived = async (investment_id, investment_name) => {
    try {
      setLoading(true)
      const archive = await put(concatUrlWithId(ARCHIVE_INVESTMENT_BY_ID, investment_id), { is_archived: true });
      if (archive) {
        fetchInvestmentList()
      }
      notify(`${investment_name} has been archived.`, 'warning',)
    } catch (error) {
      console.log('Error while deleting the error : ', error)
      notify(`Unable to archive ${investment_name}.`, 'warning',)
    }
  };

  const handleOnClickAction = (type, id, name, actionPermission) => {
    switch (type) {
      case 'view':
        handleViewClick(id);
        break;
      case 'edit':
        handleEditClick(id);
        break;
      case 'archive':
        archivedMineralAsset(id, name);
        break;
      case 'delete':
        deleteMineralAsset(id, name);
        break;
      case 'reminderTask':
        reminderTaskPopUp(id, actionPermission);
        break;
      case 'setreminder':
        handleReminderClick(id, name);
        break;
      default:
        break;
    }
  };

  const handleReminderClick = (id, name) => {
    SetSelectedInvID(id)
    setShowReminderModel(!showReminderModel)
  };


  const handleReminderModelClose = () => {
    setShowReminderModel(false);
  };

  // Custom sort function for numeric values
  const numericSort = (a, b, order) => {
    if (order === 'asc') {
      return a - b; // Ascending order
    }
    return b - a; // Descending order
  };

  const columns = [
    { dataField: 'id', text: 'ID', csvExport: false, hidden: true },
    {
      dataField: 'investment_name', text: 'Legal Entity Name', sort: true, isViewField: true, handleOnClick: handleOnClickAction, onClickParams: ['id'],
      headerWidth: '180px', wordWarp: true, action: true, order: 'asc'
    },
    { dataField: 'type', text: 'Type', sort: true },
    // { dataField: 'lease_date', text: 'Lease Date', sort: true, isDateField: true },
    { dataField: 'amount_invested', text: 'Investment Value($)', sort: true, isAmountField: true, sortFunc: numericSort },
    { dataField: 'cal_current_value', text: 'Current Value ($)', sort: true, isAmountField: true, sortFunc: numericSort },
    { dataField: 'percentage_interest', text: 'Percentage Interest(%)', sort: true, sortFunc: numericSort },
    // { dataField: 'performance', text: 'Performance', sort: false, isPerformanceField: true },
    { dataField: 'projected_annual_income', text: 'Cash Flow($)', sort: true, hMinWidth: '120px', isAmountField: true, sortFunc: numericSort }
  ];
  let tempAction = { dataField: 'Action', text: 'Action', csvExport: false, standardAction: true, hMinWidth: '150px', handleOnClick: handleOnClickAction, tooltipSuffixText: "Royalty Income", onClickParams: ['id', 'investment_name'], reminderCount: ['active_reminders_count'], setReminder: true }

  if (checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) {
    columns.push(tempAction);
  } else {
    tempAction = { ...tempAction, disableDelete: true, disableEdit: true, disableArchive: true }
    columns.push(tempAction);
  };

  const headerTotalValues = [
    { label: 'Total Investments ($)', value: headerValues.totalInvestments },
    { label: 'Total Current Value ($)', value: headerValues.totalCurrentValues },
    { label: 'Total Inv.Val after Debt ($)', value: headerValues.totalDebt },
  ];

  const handleBackClick = () => {
    history('/portfolio-summary')
  }

  const updateReminderData = async () => {
    await fetchInvestmentList();
  }

  const handleTableChange = (type, value) => {
    try {
      switch (type) {
        case 'print':
          SetEnablePrint(value);
          break;
        default:
          break;
      }
    } catch (error) {
      console.log('Error in the handle table change funciton:: ', error)
    }
  }

  const handlePrintClick = () => {
    confirmAlert({
      title: 'Alert',
      message: `For better viewing, please use landscape mode.`,
      buttons: [
        {
          label: 'Done',
          onClick: () => SetEnablePrint(true)
        },
      ]
    });


  }



  return (
    <div className="main-content introduction-farm">
      <div className="col-12" style={{ padding: '5px 15px 15px 15px' }}>
        <div className="card">
          <button id="update-remainder-data" onClick={() => updateReminderData()} style={{ display: 'none' }}> Click me </button>
          <div className="card-body card-breadcrumb">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <a href="#" onClick={() => handleBackClick()} className="btn btn-light mb-2 me-2" style={{ display: 'flex', alignItems: 'center' }}><i className="arrow_left"></i>
                Back</a>
              {/* <div className="page-title-right title-prefix" >Royalty Income</div> */}
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item"><a href="#" onClick={() => handleBackClick()}>Portfolio Summary
                  </a></li>
                  <li className="breadcrumb-item active" >Royalty Income</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-wraper-area">
        <div className="dashboard-area">
          <div className="container-fluid">
            <DataChart
              header={"Royalty Income"} data={investmentList} labelKey={'type'}
              seriesKey={["current_value", "amount_invested"]} onChange={handleChartChange}
              seriesName={["Current Value", "Invested Value"]} type="bar"
            />
            <br />
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="card-title" style={{ marginBottom: '5px' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <h4 style={{ flex: 'auto' }}>Royalty Income List <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Print</Tooltip>} >
                        <i className="fa fa-print btn btn-circle print-btn" onClick={() => { handlePrintClick() }} ></i>
                      </OverlayTrigger>
                      </h4>
                      {
                        (checkPermission(userPermissions, 'create-mineral-asset') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) &&
                        <button type="button" onClick={AddMineralAsset} className="btn btn-primary mb-2 me-2"><i className="icon_plus"></i> Add New</button>
                      }
                    </div>
                  </div>
                  <div >
                    <Datatable data={selectedInvList} columns={columns} csvFileName={'MineralAssetList'} actionPermissionPrefix={'mineral-asset'} isAdminOrManager={checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')} headerName={'Royalty Income List'} enablePrint={enablePrint} handleTableChange={handleTableChange} headerObjValue={headerTotalValues} showHeaderTotal={true} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PasswordConfirmation showViewModal={showPasswordModel} handleDelete={handleDelete} handleCloseViewModal={() => { setShowPasswordModel(false); revertDelete() }} />
      <ReminderTab show={showReminderTask} recordId={getInvestmentID} handleClose={() => setShowReminderTask(false)} hangleTabChange={handelListUpdate}
        invesmentCatId={category_id} entityType={'investment'} isAdminOrManager={checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')} actionPermission={reminderActionPermission} />
      {(userPermissions?.length && checkPermission(userPermissions, 'isManager')) && <Footer PropUserPermissions={userPermissions} />}
    </div>);
}

export default MineralAssetList;