import React, { useEffect, useRef } from 'react';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
import $ from 'jquery';
import 'bootstrap-datepicker';
import moment from 'moment';
import { useField } from 'formik';

const DatePickerComponent = ({
  viewMode,
  fieldName,
  fieldValue,
  handleClear,
  isYear,
  isMonth,
  isDateMonth,
  selected,
  onhandleDateChange = () => {},
  disabled = false,
  disablePastDate = false,
  ...props
}) => {
  const inputRef = useRef(null);
  const [field, meta, helpers] = useField(fieldName);

  useEffect(() => {
    const $input = $(inputRef.current);

    if (isYear) {
      $input.datepicker({
        format: 'yyyy',
        viewMode: 'years',
        minViewMode: 'years',
        maxViewMode: 'years',
        autoclose: true,
        startDate: disablePastDate ? new Date() : '',
      })
      .on('changeDate', (e) => {
        helpers.setValue(moment(e.date).format('YYYY'));
      })
      .on('keydown', function (e) {
        if (e.keyCode === 8) {
          helpers.setValue('');
        }
      })
      .on('input', function (e) {
        const inputVal = e.target.value;
        const isValidDate = moment(inputVal, 'YYYY', true).isValid();
        if (isValidDate) {
          helpers.setValue(moment(inputVal, 'YYYY').format('YYYY'));
        }
      });

      if (field.value && !moment(field.value, 'YYYY', true).isValid()) {
        helpers.setValue('');
      }

      if (field.value) {
        onhandleDateChange(moment(field.value, 'YYYY').format('YYYY'));
        $input.datepicker('update', moment(field.value, 'YYYY').format('YYYY'));
      }
    } else if (isDateMonth) {
      $input.datepicker({
        format: 'MM-dd',
        startView: 'months',
        minViewMode: 'days',
        maxViewMode: 'months',
        autoclose: true,
        startDate: disablePastDate ? new Date() : '',
      })
      .on('changeDate', (e) => {
        helpers.setValue(moment(e.date).format('YYYY-MM-DD'));
      })
      .on('keydown', function (e) {
        if (e.keyCode === 8) {
          helpers.setValue('');
        }
      })
      .on('input', function (e) {
        const inputVal = e.target.value;
        const isValidDate = moment(inputVal, 'MM-DD', true).isValid();
        if (isValidDate) {
          helpers.setValue(moment(inputVal, 'MM-DD').format('YYYY-MM-DD'));
        }
      });

      if (field.value && !moment(field.value, 'YYYY-MM-DD', true).isValid()) {
        helpers.setValue('');
      }

      if (field.value) {
        onhandleDateChange(moment(field.value, 'YYYY-MM-DD').format('YYYY-MM-DD'));
        $input.datepicker('update', moment(field.value, 'YYYY-MM-DD').format('MM-DD'));
      }
    } else if (isMonth) {
      $input.datepicker({
        format: 'mm/yyyy',
        startView: 'months',
        minViewMode: 'months',
        maxViewMode: 'months',
        autoclose: true,
        startDate: disablePastDate ? new Date() : '',
      })
      .on('changeDate', (e) => {
        helpers.setValue(moment(e.date).format('YYYY-MM'));
      })
      .on('keydown', function (e) {
        if (e.keyCode === 8) {
          helpers.setValue('');
        }
      })
      .on('input', function (e) {
        const inputVal = e.target.value;
        const isValidDate = moment(inputVal, 'MM/YYYY', true).isValid();
        if (isValidDate) {
          helpers.setValue(moment(inputVal, 'MM/YYYY').format('YYYY-MM'));
        }
      });

      if (field.value && !moment(field.value, 'YYYY-MM', true).isValid()) {
        helpers.setValue('');
      }

      if (field.value) {
        onhandleDateChange(moment(field.value, 'YYYY-MM').format('YYYY-MM'));
        $input.datepicker('update', moment(field.value, 'YYYY-MM').format('MM-YYYY'));
      }
    } else {
      $input.datepicker({
        format: 'mm/dd/yyyy',
        autoclose: true,
        startView: 'years',
        minViewMode: 'days',
        maxViewMode: 'years',
        startDate: disablePastDate ? new Date() : '',
      })
      .on('changeDate', (e) => {
        helpers.setValue(moment(e.date).format('YYYY-MM-DD'));
      })
      .on('keydown', function (e) {
        if (e.keyCode === 8) {
          helpers.setValue('');
        }
      })
      .on('input', function (e) {
        const inputVal = e.target.value;
        const isValidDate = moment(inputVal, 'MM/DD/YYYY', true).isValid();
        if (isValidDate) {
          helpers.setValue(moment(inputVal, 'MM/DD/YYYY').format('YYYY-MM-DD'));
        }
      });

      if (field.value && !moment(field.value, 'YYYY-MM-DD', true).isValid()) {
        helpers.setValue('');
      }

      if (field.value) {
        onhandleDateChange(moment(field.value, 'YYYY-MM-DD').format('YYYY-MM-DD'));
        $input.datepicker('update', moment(field.value, 'YYYY-MM-DD').format('MM/DD/YYYY'));
      }
    }

    return () => {
      $input.datepicker('destroy');
    };
  }, [field.value, fieldName, helpers, isYear, isMonth, isDateMonth, disablePastDate]);

  useEffect(() => {
    if (handleClear) {
      helpers.setValue('');
      const $input = $(inputRef.current);
      $input.datepicker('update', '');
    }
  }, [handleClear, helpers]);

  useEffect(() => {
    const $input = $(inputRef.current);
    $input.prop('disabled', disabled);
  }, [disabled]);

  useEffect(() => {
    const styleTag = document.createElement('style');
    styleTag.innerHTML = `
      .datepicker td, .datepicker th {
        width: 35px;
        height: 32px;
        font-weight: 400;
        font-size: 14px;
      }
    `;
    document.head.appendChild(styleTag);

    return () => {
      document.head.removeChild(styleTag);
    };
  }, []);

  return (
    <input
      readOnly={viewMode}
      className='form-control'
      type="text"
      id={fieldName}
      name={fieldName}
      ref={inputRef}
      placeholder={(isDateMonth && "MM-DD") || (isYear && "YYYY") || (isMonth && "MM-YYYY") || "MM/DD/YYYY"}
      autoComplete='off'
    />
  );
};

export default DatePickerComponent;
