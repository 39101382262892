import { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import Footer from "../components/Footer";
import { PageLoader } from '../Context/PageLoader';
import { useNavigate } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Datatable from '../components/Table/Datatable';
import { get } from '../api';
import { GET_NET_WORTH_DATA } from '../api/endpoints'
import { checkPermission } from '../utils/commonUtils';
import { UserPermissionStore } from '../Store/UserPermission';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function NetWorthList() {
    const [overviewDataList, setOverViewList] = useState([]);
    const [selectedInvList, setSelectedInvList] = useState([]);
    const [filterOptions, setFilterOptions] = useState("");
    const { loading, setLoading } = useContext(PageLoader);
    const [userPermissions, setUserPermissions] = useState([]);
    const navigation = useNavigate();
    const history = useNavigate();
    const notify = (message, type) => toast(message, { type });
    const { checkAndUpdatePermissions } = UserPermissionStore();
    const [enablePrint, SetEnablePrint] = useState(false);
    const [headerValues, setHeaderValues] = useState({
        totalAssets: 0,
        totalLiabilities: 0,
        totalNetWorth: 0,
    });

    const fetchInvestmentList = async () => {
        try {
            setLoading(true)
            const overviewData = await get(GET_NET_WORTH_DATA);

            if (overviewData?.investmentData?.length) {
                let overviewInvData = overviewData.investmentData
                const filterData = overviewInvData.filter(value => value.current_value != null);
                let totalAssets = 0;
                let totalLiabilities = 0;

                const netWorthData = filterData.map((value) => {
                    
                        let currentValue = parseFloat(value['current_value']) || 0;
                        let amountBorrowed = parseFloat(value['amount_borrowed']) || 0;

                        value['assets'] = currentValue.toFixed(2);
                        value['liabilities'] = amountBorrowed.toFixed(2);
                        value['net_worth'] = (currentValue - amountBorrowed).toFixed(2);

                        totalAssets += currentValue;
                        totalLiabilities += amountBorrowed;
                    
                    return value;
                });

                // After all calculations, you can format the totals to two decimal places
                totalAssets = totalAssets.toFixed(2);
                totalLiabilities = totalLiabilities.toFixed(2);

                const totalNetWorth = (totalAssets - totalLiabilities).toFixed(2);

                setHeaderValues({
                    totalAssets: totalAssets,
                    totalLiabilities: totalLiabilities,
                    totalNetWorth: totalNetWorth,
                })

                setOverViewList(netWorthData);
                setSelectedInvList(overviewInvData)
                const dropdownOptionsSet = new Set();
                overviewInvData.forEach(item => {
                    const value = item['investment_categories.value'];
                    if (value !== null) {
                        dropdownOptionsSet.add(value);
                    }
                });

                const dropdownOptions = Array.from(dropdownOptionsSet).reduce((options, value) => {
                    options.push({ label: value, value });
                    return options;
                }, [{ label: 'All', value: 'all' }]);
                setFilterOptions(dropdownOptions);
            };

        } catch (error) {
            console.log('Error while throughing:: ', error);
        } finally {
            setLoading(false);
        }
    }

    const authCheckAndFetch = async () => {
        let tempPermission = await checkAndUpdatePermissions();
        const permissionFetch = async () => {
            if (tempPermission) {
                if (!tempPermission.length) {
                    setUserPermissions([]);
                } else {
                    setUserPermissions(tempPermission);
                }
            }
            if (checkPermission(tempPermission, 'overview') || checkPermission(tempPermission, 'isAdmin') || checkPermission(tempPermission, 'isManager')) {
                fetchInvestmentList()
            } else {
                setLoading(false);
            }
        };
        permissionFetch();
    }

    useEffect(() => {
        //Check Auth permissions and fetch the data 
        authCheckAndFetch();
    }, [])

    const handleViewClick = (type, investmentId, categoryId) => {
        const real_estate_master = process.env.REACT_APP_INVESTMENT_REAL_ESTATE_MASTER_ID;
        const stock_master = process.env.REACT_APP_INVESTMENT_STOCK_MASTER_ID;
        const mineral_asset_master = process.env.REACT_APP_INVESTMENT_MINERAL_ASSET_MASTER_ID;
        const personal_asset_master = process.env.REACT_APP_PERSONAL_ASSETS_MASTER_ID;
        const real_estate_holding_master = process.env.REACT_APP_REAL_ESTATE_HOLDINGS_DETAILS_MASTER_ID;
        if (categoryId == real_estate_master) {
            setLoading(true);
            navigation(`/view-private-equity/${investmentId}`, { state: { previousPage: "Portfolio Summary", previousUrl: "/portfolio-summary", isArchived: false } });
        } else if (categoryId == stock_master) {
            setLoading(true);
            navigation(`/view-liquid-asset/${investmentId}`, { state: { previousPage: "Portfolio Summary", previousUrl: "/portfolio-summary", isArchived: false } });
        } else if (categoryId == mineral_asset_master) {
            setLoading(true);
            navigation(`/view-royalty-income/${investmentId}`, { state: { previousPage: "Portfolio Summary", previousUrl: "/portfolio-summary", isArchived: false } });
        } else if (categoryId == personal_asset_master) {
            setLoading(true);
            navigation(`/view-personal-assets/${investmentId}`, { state: { previousPage: "Portfolio Summary", previousUrl: "/portfolio-summary", isArchived: false } });
        } else if (categoryId == real_estate_holding_master) {
            setLoading(true);
            navigation(`/view-real-estate-holdings/${investmentId}`, { state: { previousPage: "Portfolio Summary", previousUrl: "/portfolio-summary", isArchived: false } });
        }
    };

    const handleFilterChange = (selectedValue) => {
        if (selectedValue === 'all') {
            setSelectedInvList(overviewDataList)
        } else {
            // let filterInvesmentList = overviewDataList.filter((item) => (selectedValue == item['investment_categories.value']));
            let filterInvesmentList = [];
            let totalAssets = 0;
            let totalLiabilities = 0;

            overviewDataList.forEach((item) => {
                if (selectedValue == item['investment_categories.value']) {
                    totalAssets += parseFloat(item['assets'] || 0);
                    totalLiabilities += parseFloat(item['liabilities'] || 0);
                    filterInvesmentList.push(item)
                }
            });

            totalAssets = totalAssets.toFixed(2);
            totalLiabilities = totalLiabilities.toFixed(2);

            const totalNetWorth = (totalAssets - totalLiabilities).toFixed(2);;

            setHeaderValues({
                totalAssets: totalAssets,
                totalLiabilities: totalLiabilities,
                totalNetWorth: totalNetWorth,
            })
            setSelectedInvList(filterInvesmentList)
        }
    }

    const handleBackClick = () => {
        history('/portfolio-summary')
    }

    const handleChartChange = (selectedValue) => {
        if (selectedValue != '') {
            setLoading(true)
            switch (selectedValue) {
                case 'Liquid Assets':
                    navigation('/liquid-asset-list');
                    break;
                case 'Private Equity':
                    navigation('/private-equity-list');
                    break;
                case 'Real Estate Holdings':
                    navigation('/real-estate-holding-list');
                    break;
                case 'Royalty Income':
                    navigation('/royalty-income-list');
                    break;
                case 'Personal Assets':
                    navigation('/personal-assets-list');
                    break;
                case 'Operating Business':
                    navigation('/operating-business');
                    break;
                default:
                    setLoading(false);
                    break;
            }
        }
    }

    const handleTableChange = (type, value) => {
        try {
            switch (type) {
                case 'print':
                    SetEnablePrint(value);
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.log('Error in the handle table change funciton:: ', error)
        }
    }

    const columns = [
        { dataField: 'id', text: 'ID', csvExport: false, hidden: true },
        // { dataField: 'investment_name', text: 'Investment Name', sort: true, hMinWidth: '15%', hMaxWidth:"15%",  wordWarp: true },
        {
            dataField: 'investment_name', text: 'Investment Name', sort: true, isViewField: true, handleOnClick: handleViewClick, onClickParams: ['id', 'investment_category'],
            headerWidth: '250px', wordWarp: true, action: true
        },
        { dataField: 'investment_categories.value', text: 'Asset Class', hMinWidth: '12%', hMaxWidth: "15%", sort: true },
        { dataField: 'assets', text: 'Asset ($)', sort: true, hMinWidth: '12%', hMaxWidth: "15%", isAmountField: true },
        { dataField: 'liabilities', text: 'Liabilities ($)', sort: true, hMinWidth: '12%', hMaxWidth: "13%", isAmountField: true },
        { dataField: 'net_worth', text: 'Net Worth ($)', sort: true, hMinWidth: '12%', hMaxWidth: "13%", isAmountField: true },
    ];

    const headerTotalValues = [
        { label: 'Total Assets ($)', value: headerValues.totalAssets},
        { label: 'Total liabilities ($)', value: headerValues.totalLiabilities},
        { label: 'Total NetWorth ($)', value: headerValues.totalNetWorth}, 
    ];

    return (
        <div className="main-content introduction-farm">
            <div className="col-12" style={{ padding: '5px 15px 15px 15px' }}>
                <div className="card">
                    <div className="card-body card-breadcrumb">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <a href="#" onClick={() => handleBackClick()} className="btn btn-light mb-2 me-2" style={{ display: 'flex', alignItems: 'center' }}><i className="arrow_left"></i>
                                Back</a>
                            {/* <div className="page-title-right title-prefix" >Liquid Assets</div> */}
                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#" onClick={() => handleBackClick()}>Portfolio Summary
                                    </a></li>
                                    <li className="breadcrumb-item active" >Net Worth List</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-wraper-area">
                <div className="dashboard-area">
                    <div className="container-fluid">
                        <div className="row" style={{ alignItems: 'flex-end', alignItems: 'center' }} >
                        </div>
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title no-print" style={{ marginBottom: '5px' }}>
                                        {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}> */}
                                        <div>
                                            <div className='row'>
                                                <div className='col-3'>
                                                    <h4 style={{ flex: 'auto' }}>Net Worth List <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Print</Tooltip>} >
                                                        <i className="fa fa-print btn btn-circle print-btn" onClick={() => { SetEnablePrint(true); setLoading(true) }} ></i>
                                                    </OverlayTrigger></h4>
                                                </div>
                                                {/* <div className='col-3 mt-2'><span style={{ fontWeight: 'bold', color: '#636161', fontSize: '16px' }} id='net-worth-title'>Total Assets ($): {headerValues.totalAssets}</span></div>
                                                <div className='col-3 mt-2'><span style={{ fontWeight: 'bold', color: '#636161', fontSize: '16px' }} id='net-worth-title'>Total Liabilities ($): {headerValues.totalLiabilities}</span></div>
                                                <div className='col-3 mt-2'><span style={{ fontWeight: 'bold', color: '#636161', fontSize: '16px' }} id='net-worth-title'>Net Worth ($): {headerValues.totalNetWorth}</span></div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div >
                                        <Datatable data={selectedInvList} columns={columns} csvFileName={'Net Worth List'} headerName={'Net Worth List'} filterOptions={filterOptions} handleFilterChange={handleFilterChange}
                                            enablePrint={enablePrint} handleTableChange={handleTableChange} showHeaderTotal={true} headerObjValue={headerTotalValues} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* {(userPermissions?.length && checkPermission(userPermissions, 'isManager')) && <Footer PropUserPermissions={userPermissions} />} */}
        </div>);
}
export default NetWorthList;