import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReminderList from './ReminderList';
import ReminderTaskModel from './ReminderTaskModel';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getCategory } from '../../utils/commonUtils';

const ReminderTab = ({ show, recordId, permissions, hangleTabChange, handleClose, invesmentCatId, entityType, actionPermission, isAdminOrManager, getReminderTaskCount = () => { },
    isOperatingBusiness = false, setShowReminderTask = () => { } }) => {
    const [selectedTab, setSelectedTab] = useState('reminder_task');
    const [viewMode, setViewMode] = useState(true);
    const location = useLocation();
    const handleTabChange = (tabName, event) => {
        event.preventDefault();
        setSelectedTab(tabName);
        setViewMode(true);
    };

    useEffect(() => {
        // if (!checkPermission(permissions, 'isAdmin') && !checkPermission(permissions, 'isManager')) {
        //     setSelectedTab('documents');
        //     setSelectedTab('income');
        //   } 
        // }
    }, [])

    const tabHandleClose = () => {
        setSelectedTab('reminder_task');
        handleClose()
    }

    const handelListUpdate = () => {
        hangleTabChange();
    }

    return (
        <Modal show={show} onHide={tabHandleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="heading-title">
                        <h6>{getCategory(invesmentCatId, entityType)} - Reminder &nbsp; &nbsp;<span className="board-badge">
                            {/* <a href="#">{count}</a> */}
                        </span></h6>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '0px' }}>
                <div className='card'>
                    <div className='card-body'>

                        <ul className="nav nav-tabs">
                            {/* {(checkPermission(permissions, 'isAdmin') || checkPermission(permissions, 'isManager') || checkPermission(permissions, 'view-inc-real-estate-hld')) && (incExptab) && ( */}
                            <li className="nav-item" onClick={(e) => handleTabChange('reminder_task', e)} style={{ borderBottom: '0px' }}>
                                {/* <a className={`nav-link ${selectedTab === 'reminder_task' ? 'active' : ''}`} style={{ borderBottom: '0px' }} href="#">Reminder Task</a> */}
                                <a className={`nav-link reminder-tab-icon ${selectedTab === 'reminder_task' ? 'active active-tab-color' : ''}`} href="#"  style={{borderBottom:'0px'}}  >
                                    <i className="zmdi zmdi-view-list-alt" style={{ fontSize: '20px', marginRight: '5px' }}></i>
                                    <span>Reminder Task</span>
                                </a>
                            </li>
                            {/* )} */}
                            {/* {(checkPermission(permissions, 'isAdmin') || checkPermission(permissions, 'isManager') || checkPermission(permissions, 'view-exp-real-estate-hld')) && (incExptab) && ( */}
                            <li className="nav-item" onClick={(e) => handleTabChange('reminder_list', e)} style={{ borderBottom: '0px' }}>
                                {/* <a className={`nav-link ${selectedTab === 'reminder_list' ? 'active' : ''}`} style={{borderBottom:'0px'}} href="#">  <i className="zmdi zmdi-time-restore"></i>   <span>Set Reminder</span></a> */}
                                <a className={`nav-link reminder-tab-icon ${selectedTab === 'reminder_list' ? 'active active-tab-color' : ''}`} href="#"  style={{borderBottom:'0px'}}  >
                                    <i className="zmdi zmdi-time-restore" style={{ fontSize: '20px', marginRight: '5px' }}></i>
                                    <span>Set Reminder</span>
                                </a>
                            </li>
                            {/* )} */}
                        </ul>
                    </div>
                    {/* Conditional rendering based on selected tab */}
                    {(selectedTab === 'reminder_task') && (
                        <div >
                            <div className="introduction-farm" style={{ paddingBottom: '10px' }}>
                                <div className="content-wraper-area">
                                    <div className="dashboard-area">
                                        <div className="container-fluid">
                                            <div className="col-12">
                                                <div className="card">
                                                    <div className="card-body tab-data-model">
                                                        <div style={{ marginLeft: '-2px' }}>
                                                            <ReminderTaskModel show={true} handleClose={tabHandleClose} investmentID={recordId} investmentCatID={invesmentCatId}
                                                                handelListUpdate={handelListUpdate} isTabView={true} entityType={entityType} actionPermission={actionPermission} isAdminOrManager={isAdminOrManager} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {(selectedTab === 'reminder_list') && (
                        <div >
                            <div className="introduction-farm" style={{ paddingBottom: '10px' }}>
                                <div className="content-wraper-area">
                                    <div className="dashboard-area">
                                        <div className="container-fluid">
                                            <div className="col-12">
                                                <div className="card">
                                                    <div className="card-body tab-data-model">
                                                        <div>
                                                            <ReminderList show={true} recordId={recordId} handleClose={tabHandleClose} isTabView={true} entityType={entityType}
                                                                headerName={'Reminder'} invesmentCat={invesmentCatId} handelListUpdate={handelListUpdate} actionPermission={actionPermission} isAdminOrManager={isAdminOrManager} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button type="reset" variant="secondary" onClick={tabHandleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>

    );
};

export default ReminderTab;