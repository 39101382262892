
import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { get, post, put } from '../../../api'
import { GET_STOCK_INV_INTEREST, CREATE_STOCK_INV_INTEREST, UPDATE_STOCK_INV_INTEREST, DELETE_STOCK_INV_INTEREST } from '../../../api/endpoints';
import { confirmAlert } from 'react-confirm-alert';
import * as Yup from 'yup';
import 'react-toastify/dist/ReactToastify.css'
import Datatable from '../../../components/Table/Datatable';
import { checkPermission, concatUrlWithId, formatDateAndTime } from '../../../utils/commonUtils';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import { CurrencyField, DatePickerField } from '../../../components/Forms/FormFields';
import moment from 'moment';

export const investmentSchema = Yup.object().shape({
    interest_earned: Yup.string()
        .matches(/^\d{1,13}(\.\d{1,2})?$/, 'Invalid number format. Use up to 13 digits before the decimal and up to 2 digits after.')
        .required('Interest Earned is required'),
    interest_earned_date: Yup.date().label('Interest Earned Date').required('Interest Earned Date is required')
});


function StockInterestTable({ investmentId, isArchived = false, stockInvId , onChangeInterest , uploadPermission, printMode = false, onComplete =  () => {} }) {
    const notify = (message, type) => toast(message, { type });
    const [initialValues, setInitialValues] = useState({ interest_earned: '', interest_earned_date: '' })
    const [stockInterestList, setStockInterestList] = useState([]);
    const [showViewModal, setShowViewModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage,  setErrorMessage] = useState('')
    /* Upload Modal  */
    const [mode, setMode] = useState('Add');
    const [editId, setEditId] = useState('');
    const [viewMode, setViewMode] = useState(false);
    const [isFieldClear, setIsFieldClear] = useState(false);
    const [isSaveNew, setIsSaveNew] = useState(false); 

    const handleOnClickAction = (type, id, int_earned, int_earned_date) => {
        switch (type) {
            // case 'view':
            //     handleShowViewModal(id);
            //     break;
            case 'edit':
                handleEdit(id, int_earned, int_earned_date);
                break;
            case 'delete':
                deleteStock(id);
                break;
            default:
                break;
        }
    };


    const deleteStock = (interest_id) => {
        confirmAlert({
            title: 'Delete Interest Earned',
            message: `Are you sure you want to delete ?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleDeleteIncomeExpense(interest_id)
                },
                {
                    label: 'No',
                }
            ]
        });
    };

    const handleDeleteIncomeExpense = async (interest_id) => {
        try {
            let totalInterestEarned = 0
            stockInterestList.forEach(stock => {
                const amount = parseFloat(stock.interest_earned);
                if (!isNaN(amount) && (stock.id !== interest_id)) { // Check if amount is a valid number
                    totalInterestEarned += amount;
                }
            });
            const deleteInvestment = await put(concatUrlWithId(DELETE_STOCK_INV_INTEREST, interest_id), {
                totalInterestEarned,
                stock_investment_id: stockInvId
            });
            onChangeInterest(totalInterestEarned);
            getStockInterestList();
            notify(`Interest earned successfully deleted`, 'success')
        } catch (error) {
            console.log('Error while deleting the error : ', error)
            notify(`Unable to delete interest earned`, 'Error')
        }

    };

    const handleEdit = (id, int_earned, int_earned_date) => {
        setIsFieldClear(false);
        setInitialValues({ interest_earned: int_earned, interest_earned_date: formatDateAndTime(int_earned_date,'YYYY-MM-DD') })
        handleShow('Edit');
        setEditId(id);
    } 
    const columns = [
        { dataField: 'id', text: 'ID', csvExport: false, hidden: true },
        { dataField: 'interest_earned', text: 'Interest Earned ($)', sort: true, isAmountField: true },
        { dataField: 'interest_earned_date', text: 'Interest Earned Date', sort: true, isDateField: true },
        { dataField: 'stock_interest_modified_by.full_name', text: 'Updated By', sort: true, isUserNameField: true },
        { dataField: 'updatedAt', text: 'Updated At', sort: true, isDateField: true },
    ];
    let tempAction = {dataField: 'Action', text: 'Action', csvExport: false, standardAction: true, tooltipSuffixText: "Interest", handleOnClick: handleOnClickAction, onClickParams: ['id', 'interest_earned', 'interest_earned_date'], disableArchive: true };
    if(!isArchived  && (checkPermission(uploadPermission, 'edit-stock') || checkPermission(uploadPermission, 'isAdmin') || checkPermission(uploadPermission, 'isManager'))){
        columns.push(tempAction);
    }
 

    // Example of calling it for the "formrow-email-input" input
    useEffect(() => {
        getStockInterestList()
        setErrorMessage('')
    }, [showViewModal])

    useEffect(() => {
        getStockInterestList()
        setErrorMessage('')
    }, [])

    const handleShow = (type = 'Add') => {
        setShowViewModal(true);
        setMode(type)
    }

    const handleClose = () => {
        setInitialValues({ interest_earned: '', interest_earned_date: '' });
        setShowViewModal(false)
    };

    const getStockInterestList = async () => {
        try {
            let data = []
            if (stockInvId) {
                data = await get(concatUrlWithId(GET_STOCK_INV_INTEREST, stockInvId));
            }
            setStockInterestList(data)
        } catch (error) {
            console.log('Something went wrong unable to fetch Audit Log');
            // notify(error?.response?.data?.error, 'error');
            notify('Something went wrong unable to fetch Auditlog', 'error');
        }
    };

    const onTableComplete = () => {
        onComplete()
    }

    return (<>
        <br></br>
        <div className='row'>
            <div className='col-6'>
                <legend className='text-bold print-legend '>Interest Earned List</legend>
            </div>
            <div className='col-6 no-print'>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                    {!isArchived &&  (checkPermission(uploadPermission, 'edit-stock') || checkPermission(uploadPermission, 'isAdmin') || checkPermission(uploadPermission, 'isManager')) && <button type='button' onClick={() => handleShow()} className='btn btn-primary'>Add Interest Earned</button>}
                </div>
            </div>
        </div>

        {!printMode && <Modal show={showViewModal} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{mode} Interest</Modal.Title>
            </Modal.Header>
            <Formik

                initialValues={initialValues}
                validationSchema={investmentSchema}
                enableReinitialize
                onSubmit={async (values, actions) => {
                    setIsSubmitting(true);
                    setIsFieldClear(false);
                    try {
                        let response = [];

                        let totalInterestEarned = 0;
                        stockInterestList.forEach(stock => {
                            const amount = parseFloat(stock.interest_earned);
                            if (!isNaN(amount) && (!(mode === 'Edit') || (stock.id !== editId))) { // Check if amount is a valid number
                                totalInterestEarned += amount;
                            }
                        });
                        totalInterestEarned += !isNaN(parseFloat(values.interest_earned)) ? parseFloat(values.interest_earned) : 0;

                        const url = (mode === 'Edit' && editId) ? concatUrlWithId(UPDATE_STOCK_INV_INTEREST, editId) : CREATE_STOCK_INV_INTEREST;
                        const bodyPayload = {
                            interest_earned: values.interest_earned,
                            interest_earned_date: values.interest_earned_date,
                            stock_investment_id: stockInvId,
                            totalInterestEarned: totalInterestEarned
                        }
                        response = (mode === 'Edit' && editId) ? await put(url, bodyPayload) : await post(url, bodyPayload);

                        if (response) {
                            onChangeInterest(totalInterestEarned);

                            notify(`${(mode === 'Edit' && editId) ? "Updated" : "Added"} Successfully...`, 'success')
                            setErrorMessage('')
                            getStockInterestList();
                            if (isSaveNew == true) {
                                actions.resetForm();
                                setIsFieldClear(true);
                                setIsSaveNew(false)
                            } else {
                                handleClose()
                            }
                        } else {
                            (mode === 'Edit' && editId) ? notify(`Interest earned update failed...`, 'error') : notify(`Unable to create interest earned...`, 'error');
                        }
                    } catch (error) {
                        notify(error?.response?.data?.message, 'error');
                        if(error?.response?.data?.date_message){
                            setErrorMessage(error?.response?.data?.date_message);
                        }
                        console.error('API Error:', error);
                    }
                    setIsSubmitting(false);
                }}
            >
                {props => (
                    <>
                        <Modal.Body>
                            <div>
                                <Form>
                                    <div className='row'>
                                        <CurrencyField viewMode={viewMode} fieldName="interest_earned" label="Interest Earned" values={props.values} setFieldValue={props.setFieldValue} />
                                        <DatePickerField viewMode={viewMode} fieldName="interest_earned_date" label="Interest Earned Date" value={initialValues.interest_earned_date} setFieldValue={props.setFieldValue} isFieldClear={isFieldClear} />
                                        {errorMessage != '' && <div style={{ fontSize:'12px', color:'red'}}>{errorMessage}</div>}
                                    </div>
                                </Form>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="reset" variant="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                            {!viewMode && <Button type="submit" variant="primary" disabled={isSubmitting} onClick={() => { props.submitForm() }} className="btn btn-primary w-md">
                                {(mode === "Edit") ? 'Update' : 'Save'}
                            </Button>}
                            {(mode === "Add") && <Button type="submit" onClick={() => { props.submitForm(); setIsSaveNew(true) }} className="btn btn-primary w-md">Save and New</Button>}
                        </Modal.Footer>
                    </>
                )}
            </Formik>
        </Modal >}
        <Datatable data={stockInterestList || []} columns={columns} disableCsv={true} disableSearch={true} viewPrintMode={printMode} onTableComplete={onTableComplete} />
    </>)
}
export default StockInterestTable;