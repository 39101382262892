import React, { useState, useEffect, useContext } from 'react';
import Table from '../../components/Table/Datatable'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { get, post, put, remove } from '../../api/index'
import { GET_ALL_ESTATE_PLANNING, ESTATE_PLANNING_DELETE_DOCUMENT, ESTATE_PLANNING_DOCUMENT_GET_BY_ID, GET_REMINDER_TASK_LIST, GET_REAL_ESTATE_COMPANY } from '../../api/endpoints'
import { confirmAlert } from 'react-confirm-alert';
import 'react-toastify/dist/ReactToastify.css'
import { concatUrlWithId, getFileNameFromS3Url, formatDateAndTime, notify, refreshCount, addValueLabel, checkPermission } from '../../utils/commonUtils';
import { PageLoader } from '../../Context/PageLoader';
import EstatePlanningUploader from '../../components/DocumentManager/EstatePlanningUploader';
import ReminderTabList from '../../components/Reminder/ReminderTabList';
import ReminderTab from '../../components/Reminder/ReminderTab';
import { useNavigate } from 'react-router-dom';
import moment, { relativeTimeRounding } from 'moment';
import { dateOptions, reminderOptionType, timeOptions, weeklyOptions } from '../../Data/ReminderOptions'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Footer from '../../components/Footer';
import { UserPermissionStore } from '../../Store/UserPermission';
import PasswordConfirmation from '../../components/Forms/PasswordConfirmation';
import { counter } from '@fortawesome/fontawesome-svg-core';
import RealEstateList from '../investment/RealEstate/RealEstateList';
import EstateList from './CompanyInvestingList';


function CompanyInvesting() {
    const { loading, setLoading } = useContext(PageLoader);
    const [viewData, setViewData] = useState({ description: '', document_type: '', document_url: '', file_versions: [], extension: '', other: '' })
    const [showViewModal, setShowViewModal] = useState(false);
    const [showReminderModel, setShowReminderModel] = useState(false);
    const [completedReminderList, setCompletedReminderList] = useState(false);
    const [selectedInvID, SetSelectedInvID] = useState('')
    const history = useNavigate();
    const [showReminderTask, setShowReminderTask] = useState(false);
    const [enablePrint, SetEnablePrint] = useState(false)
    const [getInvestmentID, setInvestmentID] = useState('');
    const [userPermissions, setUserPermissions] = useState("");
    const { checkAndUpdatePermissions } = UserPermissionStore();
    const [showPasswordModel, setShowPasswordModel] = useState(false);
    const [deleteItem, setDeleteItem] = useState({ id: '', name: '' })
    const [showRealEstate, setRealEstate] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const reminderTaskPopUp = (investment_id) => {
        setInvestmentID(investment_id);
        setShowReminderTask(true);
    };


    const handleShowViewModal = async (name) => {
        setRealEstate(true);
        setCompanyName(name)
    }


    const [documentList, setDocumentList] = useState([]);




    const downloadFile = (url) => {
        window.location = url;
    }
    const handleOnClickAction = (type, id, name, url) => {
        switch (type) {
            case 'view':
                handleShowViewModal(id);
                break;

            case 'download':
                downloadFile(url);
                break;

            case 'reminderTask':
                reminderTaskPopUp(id);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        setLoading(true);
        authCheckAndFetch();
        // setLoading(false);
    }, [])

    const authCheckAndFetch = async () => {
        let tempPermission = await checkAndUpdatePermissions();

        setUserPermissions(tempPermission || []);

        if (checkPermission(tempPermission, 'isAdmin') || checkPermission(tempPermission, 'isManager') || checkPermission(tempPermission, 'view-estate-planning')) {
            getDocuments();
        } else {
            setLoading(false);
        }
    };

    const getDocuments = async () => {
        try {
            let data = await get(GET_REAL_ESTATE_COMPANY);
            setDocumentList(data?.data)
            setLoading(false);

        } catch (error) {
            console.log('Errro message', error?.response?.data?.message)
            if (error?.response?.data?.message) {
                notify(`${error?.response?.data?.message}`, 'error')
                history('/portfolio-summary')
            } else {
                notify(`${error}`, 'error')
                console.log('Error while deleting the error : ', error)
            }
            setLoading(false);
        }
    }

    const handleTableChange = (type, value) => {
        try {
            switch (type) {
                case 'print':
                    SetEnablePrint(value);
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.log('Error in the handle table change funciton:: ', error)
        }
    }
    const handleBackClick = () => {
        setRealEstate(false);
        setCompanyName('')
    }

    const columns = [
        { dataField: 'company_investing', text: 'Company Investing', csvExport: false, hidden: true },
        {
            dataField: 'company_investing', text: 'Company Investing', sort: true, isViewField: true, handleOnClick: handleOnClickAction, onClickParams: ['company_investing'],
            wordWarp: true
        },
        { dataField: 'count', text: 'Count', sort: true, wordWarpDot: true, hMinWidth: '8%', hMaxWidth: "13%", },
    ];

    return (
        <div className="main-content introduction-farm">
            <div className='card'>
                <div className="card-body">


                    {!showRealEstate && (
                        <Table data={documentList || []} columns={columns} disableCsv={true} disableSearch={false} enablePrint={enablePrint} handleTableChange={handleTableChange} headerName={'Estate Planning'} />
                    )}
                    {showRealEstate && (
                        <>
                            <button type='button' onClick={handleBackClick} className="btn btn-success mb-2 me-2" style={{ display: 'flex', alignItems: 'center' }}><i className="arrow_left"></i>Back</button>
                            <EstateList companyName={companyName} />
                        </>
                    )}
                </div>
            </div>
            {/* {(userPermissions?.length && checkPermission(userPermissions, 'isManager')) && <Footer PropUserPermissions={userPermissions} />} */}
        </div>
    );

}

export default CompanyInvesting;