import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form } from 'formik';
import { FormField, CurrencyField, DatePickerField } from './Forms/FormFields';
import { ActionIcon } from '../utils/commonUtils';
import * as Yup from 'yup';
import moment from 'moment';

function CapitalCalls({ viewAction = false, capitalList = [], CollectedCapital }) {
    const [showViewModal, setShowViewModal] = useState(false);
    const [capitals, setCapitals] = useState([]);
    const [viewMode, setViewMode] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [currentContact, setCurrentContact] = useState(null);
    const [editIndex, setEditIndex] = useState(-1);

    useEffect(() => {
        setCapitals(capitalList);
    }, [capitalList]);

    const initialValues = {
        date_due: '',
        amount_due: '',
        intended_source: ''
    };

    const handleCloseViewModal = () => {
        setShowViewModal(false);
        setCurrentContact(null);
        setEditIndex(-1);
    };

    const handleShowViewModal = (contact = null, index = -1) => {
        if (contact) {
            const formattedContact = {
                ...contact,
                date_due: moment(contact.date_due).format('YYYY-MM-DD'),
            };
            setCurrentContact(formattedContact);
            setEditIndex(index);
        } else {
            setCurrentContact(initialValues);
            setEditIndex(-1);
        }
        setShowViewModal(true);
    };

    const handleSubmit = (values, actions) => {
        let updatedCapitals = [];
        if (editIndex === -1) {
            updatedCapitals = [...capitals, values];
        } else {
            updatedCapitals = [...capitals];
            updatedCapitals[editIndex] = values;
        }
        setCapitals(updatedCapitals);
        CollectedCapital(updatedCapitals);
        actions.resetForm();
        handleCloseViewModal();
    };

    const contactValidationSchema = Yup.object().shape({
        date_due: Yup.string().label('Date Due').trim().required('Date Due is required'),
        amount_due: Yup.string().label('Amount Due').trim().required('Amount Due is required'),
        intended_source: Yup.string().label('Intended Source').trim().required('Intended Source is required'),
    });

    return (
        <>
            {!viewAction && <div className='row'>
                <div className='col-6'></div>
                <div className='col-6'>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                        <button type="button" className="btn btn-sm btn-secondary" onClick={() => handleShowViewModal()}><i className="ti-plus me-1"></i> Add</button>
                    </div>
                </div>
            </div>}

            <Modal show={showViewModal} onHide={handleCloseViewModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editIndex === -1 ? 'Add' : 'Edit'} Capital Call</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={currentContact || initialValues}
                    validationSchema={contactValidationSchema}
                    enableReinitialize
                    onSubmit={handleSubmit}
                >
                    {props => (
                        <>
                            <Modal.Body>
                                <Form>
                                    <div className='row'>
                                        <DatePickerField viewMode={viewMode} fieldName="date_due" label="Date Due" value={props.values.date_due} setFieldValue={props.setFieldValue} />
                                        <CurrencyField viewMode={viewMode} fieldName="amount_due" label="Amount Due" values={props.values} setFieldValue={props.setFieldValue} />
                                        <FormField viewMode={viewMode} fieldName="intended_source" label="Intended Source" value={props.values.intended_source} maxLength={100} />
                                    </div>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button type="reset" variant="secondary" onClick={handleCloseViewModal}>
                                    Cancel
                                </Button>
                                {!viewMode && <Button type="submit" variant="primary" disabled={isSubmitting} onClick={props.submitForm} className="btn btn-primary w-md">
                                    Save
                                </Button>}
                            </Modal.Footer>
                        </>
                    )}
                </Formik>
            </Modal>

            <div>
                <div className="table-responsive ">
                    <table className="table table-borderless table-nowrap mb-0">
                        <thead>
                            <tr>
                                <th style={{minWidth: '100px'}}>Date Due</th>
                                <th>Amount Due($)</th>
                                <th>Intended Source</th> 
                                {!viewAction && <th>Action</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {capitals.map((contact, index) => (
                                <tr key={index}>
                                    <td>{moment(contact.date_due).format('MM-DD-YYYY')}</td>
                                    <td>{contact.amount_due}</td>
                                    <td>{contact.intended_source}</td> 
                                    {!viewAction && <td>
                                        <ActionIcon
                                            iconClass="bx bxs-edit"
                                            tooltip={`Edit`}
                                            onClick={() => handleShowViewModal(contact, index)}
                                            backgroundColor="#58ae25"
                                        />
                                        <ActionIcon
                                            iconClass="bx bx-trash"
                                            tooltip={`Delete`}
                                            onClick={() => {
                                                const newCapitals = capitals.filter((_, i) => i !== index);
                                                setCapitals(newCapitals);
                                                CollectedCapital(newCapitals);
                                            }}
                                            backgroundColor={"#dd4a57"}
                                        />
                                    </td>}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default CapitalCalls;
