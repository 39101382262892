
import { useState, useContext, useEffect } from 'react';
import Footer from "../components/Footer";
import { PageLoader } from '../Context/PageLoader';
import { useNavigate } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Datatable from '../components/Table/Datatable';
import { ARCHIVE_INVESTMENT_BY_ID, GET_ARCHIVED, OPERATING_BUSINESS_ARCHIVE } from '../api/endpoints';
import { get, post, put } from '../api';
import { checkPermission, concatUrlWithId, notify } from '../utils/commonUtils';
import { UserPermissionStore } from '../Store/UserPermission';
import { confirmAlert } from 'react-confirm-alert';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function ArchivedList() {
  const [investmentList, setInvestmentList] = useState([]);
  const { loading, setLoading } = useContext(PageLoader);
  const history = useNavigate();
  const [userPermissions, setUserPermissions] = useState([]);
  const { checkAndUpdatePermissions } = UserPermissionStore();
  const [enablePrint, SetEnablePrint] = useState(false)

  const fetchInvestmentList = async () => {
    try {
      const response = await get(GET_ARCHIVED);
      if (response) {
        setInvestmentList(response);
      }
    } catch (error) {
      console.log('Error while throughing:: ', error);
    } finally {
      setLoading(false);
    }
  }

  const authCheckAndFetch = async () => {
    let tempPermission = await checkAndUpdatePermissions();
    setUserPermissions(tempPermission || []);
    if (checkPermission(tempPermission, 'view-archive') || checkPermission(tempPermission, 'isAdmin') || checkPermission(tempPermission, 'isManager')) {
      fetchInvestmentList()
    } else {
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true)
    authCheckAndFetch();
  }, [])

  const handleViewClick = (type, investmentId, categoryId) => {
    const real_estate_master = process.env.REACT_APP_INVESTMENT_REAL_ESTATE_MASTER_ID;
    const stock_master = process.env.REACT_APP_INVESTMENT_STOCK_MASTER_ID;
    const mineral_asset_master = process.env.REACT_APP_INVESTMENT_MINERAL_ASSET_MASTER_ID;
    const personal_asset_master = process.env.REACT_APP_PERSONAL_ASSETS_MASTER_ID;
    const real_estate_holding_master = process.env.REACT_APP_REAL_ESTATE_HOLDINGS_DETAILS_MASTER_ID;
    if (categoryId == real_estate_master) {
      setLoading(true);
      history(`/view-private-equity/${investmentId}`);
    } else if (categoryId == stock_master) {
      setLoading(true);
      history(`/view-liquid-asset/${investmentId}`);
    } else if (categoryId == mineral_asset_master) {
      setLoading(true);
      history(`/view-royalty-income/${investmentId}`);
    } else if (categoryId == personal_asset_master) {
      setLoading(true);
      history(`/view-personal-assets/${investmentId}`);
    } else if (categoryId == real_estate_holding_master) {
      setLoading(true);
      history(`/view-real-estate-holdings/${investmentId}`);
    }else if (categoryId == 'Operating business') {
      setLoading(true);
      history(`/view-operating-business/${investmentId}`);
    }
  };

  const handleArchived = async (investment_id, investment_name, url) => {
    try {
      setLoading(true)
      let archive = '';
      if(url === 'Operating business'){
        archive = await post(OPERATING_BUSINESS_ARCHIVE,{id: investment_id, is_archived: false})
      }else{
        archive = await put(concatUrlWithId(ARCHIVE_INVESTMENT_BY_ID, investment_id), { is_archived: false });
      }
      if (archive) {
        fetchInvestmentList()
        setLoading(false)
      }
      notify(`${investment_name} has been unarchive.`, 'success',)
    } catch (error) {
      console.log('Error while deleting the error : ', error)
      notify(`Failed to archive ${investment_name}.`, 'error',)
    }
  };

  const handleTableChange = (type, value) => {
    try {
      switch (type) {
        case  'print':
          SetEnablePrint(value);
          break;
        default:
          break;
      }
    } catch (error) {
      console.log('Error in the handle table change funciton:: ', error)
    }
  }

  const archivedPersonalAssets = (investment_id, investment_name, url) => {
    confirmAlert({
      title: 'Unarchive',
      message: `Are you sure you want to unarchive ${investment_name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleArchived(investment_id, investment_name, url)
        },
        {
          label: 'No',
        }
      ]
    });
  }

  const handleOnClickAction = (type, id, name, url) => {
    switch (type) {
      case 'unarchive':
          archivedPersonalAssets(id, name, url);
        break;
      default:
        break;
    }
  }

  const handlePrintClick = () => {
    confirmAlert({
      title: 'Alert',
      message: `For better viewing, please use landscape mode.`,
      buttons: [
        {
          label: 'Done',
          onClick: () => {SetEnablePrint(true); setLoading(true); }
        },
      ]
    }); 
  }

  const columns = [
    { dataField: 'id', text: 'ID', csvExport: false, hidden: true },
    {
      dataField: 'investment_name', text: 'Investment Name', sort: true, isViewField: true, handleOnClick: handleViewClick, onClickParams: ['id', 'investment_category'],
      headerWidth: '250px', wordWarp: true, action: true
    },
    { dataField: 'investment_categories.value', text: 'Category', sort: true },
    { dataField: 'investment_headers_modified_by.username', text: 'Archived By', sort: true },
    { dataField: 'updatedAt', text: 'Archived Date', sort: true, isDateField: true },
    { dataField: 'Action', text: 'Action', csvExport: false, standardAction: true, tooltipSuffixText: ' ', disableDelete: true, disableEdit: true, disableArchive:true, showUnarchive: true, handleOnClick: handleOnClickAction, onClickParams: ['id', 'investment_name', 'investment_categories.value'] }
  ];

  return (<div className="main-content introduction-farm">
    <div className="content-wraper-area">
      <div className="dashboard-area">
        <div className="container-fluid">
          <br />
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="card-title" style={{ marginBottom: '5px' }}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <h4 style={{ flex: 'auto' }}>Archived List <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Print</Tooltip>} >
                        <i className="fa fa-print btn btn-circle print-btn" onClick={() => {handlePrintClick()}} ></i>
                      </OverlayTrigger></h4>
                  </div>
                </div>
                {/* <div style={{ overflow: 'auto' }}> */}
                <Datatable data={investmentList} columns={columns} csvFileName={'ArchivedList'} headerName={'Archived List'} pdfFile={'archived_list'} enablePrint={enablePrint} handleTableChange={handleTableChange}/>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {(userPermissions?.length && checkPermission(userPermissions, 'isManager')) && <Footer PropUserPermissions={userPermissions} />}
  </div>);
}

export default ArchivedList;