import React, { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { get, post, put, remove } from '../../api';
import { GET_REMINDER_LIST, UPDATE_REMINDER_STATUS } from '../../api/endpoints';
import { PageLoader } from '../../Context/PageLoader';
import { notify, updateCurrentComponentData } from '../../utils/commonUtils';
import Datatable from '../Table/Datatable';

function ReminderTaskModel({ show, handleClose, handelListUpdate,actionPermission ,isAdminOrManager, investmentID = '', investmentCatID = '', entityType = '', isTabView = false  }) {

    const [reminderTaskList, setReminderTaskList] = useState([]);
    const [count, setCount] = useState(0);
    const [showAction, setShowAction] = useState(false);
    const { loading, setLoading } = useContext(PageLoader);

    const fetchData = async () => {
        try {
            let payload = {
                investment_id: investmentID,
                investment_cat_id: investmentCatID,
                entity_type: entityType
            };
            const reminderTasks = await post(GET_REMINDER_LIST, payload);
            setCount(reminderTasks.data.reminderList.length);
            setReminderTaskList(reminderTasks.data.reminderList);
            setShowAction(reminderTasks.data.showActions);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
            notify('Failed to fetch data.', 'error');
        }
    };

    useEffect(() => {
        setLoading(true);
        if (show) {
            fetchData();
        } else {
            // Reset state when modal is closed
            setReminderTaskList([]);
            setCount(0);
            setLoading(false);
        }
    }, [show]);
    // useEffect(() => {
    //     console.log('Intial test value fomr the reminder header or task ::: ', test)
    //     setLoading(true);
    //     // if (show) {
    //     fetchData();
    //     // } else {
    //     //     // Reset state when modal is closed
    //     //     setReminderTaskList([]);
    //     //     setCount(0);
    //     // }
    // }, []);

    useEffect(() => {
        if (show) {
            fetchData();
        }
    }, [investmentID, investmentCatID, entityType]);

    const getCategory = (id,type) => {
        switch (id) {
            case process.env.REACT_APP_INVESTMENT_REAL_ESTATE_MASTER_ID:
                return "Private Equity";
            case process.env.REACT_APP_INVESTMENT_STOCK_MASTER_ID:
                return "Liquid Assets";
            case process.env.REACT_APP_INVESTMENT_MINERAL_ASSET_MASTER_ID:
                return "Royalty Income";
            case process.env.REACT_APP_PERSONAL_ASSETS_MASTER_ID:
                return "Personal Asset";
            case process.env.REACT_APP_REAL_ESTATE_HOLDINGS_DETAILS_MASTER_ID:
                return "Real Estate Holding";
            default:
                if (type == 'operating_business') {
                    return "Operating Business";
                } else if (type == 'estate_planning') {
                    return "Estate Planning";
                }
        }
    };

    const handleOnClickAction = (type, id) => {
        if (type === 'markAsDone') {
            markAsDone(id);
        }
    };

    const markAsDone = (id) => {
        updateStatus('completed', id);
    };

    const updateStatus = async (status, id) => {
        setLoading(true);
        try {
            let payload = {
                id: id,
                reminder_task_status: status
            };
            const notificationStatus = await post(UPDATE_REMINDER_STATUS, payload);

            if (notificationStatus.status === true) {
                if (isTabView) handelListUpdate();
                updateCurrentComponentData();
                // handelListUpdate();
                const button = document.getElementById('notification-btn-fetch');
                if (button) {
                    button.click();
                }

                await fetchData();
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log('Errro message :: ', error)
            notify('Error updating status', 'error');
        }
    };

    const columns = [
        { dataField: 'id', text: 'ID', csvExport: false, hidden: true },
        {
            dataField: `${entityType == 'operating_business' ? 'operating_business.business_name' : entityType == 'estate_planning' ? 'estate_planning.description' : 'investment_header.investment_name'}`,
            text: `${entityType == 'operating_business' ? 'Business Name' : entityType == 'estate_planning' ? 'Description' : 'Investment Name'}`,
            sort: true,
            headerWidth: '250px',
            showDotsOnLengthExceed: true,
            wordWarp: true,
            maxCharLength: 60
        },
        { dataField: 'reminder_data.reminder_name', text: 'Scheduled Name', sort: true ,  headerWidth: '250px', showDotsOnLengthExceed: true, wordWarp: true, maxCharLength: 60 },
        { dataField: 'reminder_date', text: 'Date Due', sort: true, isDateField: true },
    ];

    if (showAction) {
        columns.push({
            dataField: 'Action',
            text: 'Action',
            csvExport: false,
            standardAction: true,
            handleOnClick: handleOnClickAction,
            tooltipSuffixText: " ",
            onClickParams: ['id', 'investment_name'],
            reminderCount: ['active_reminders_count'],
            disableDelete: true,
            disableEdit: true,
            disableArchive: true,
            showMarkAsDone: true
        })
    }


    return (
        <>
            {!isTabView ? <>
                <Modal show={show} onHide={handleClose} size="lg" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="heading-title">
                                <h6>{`${getCategory(investmentCatID,entityType)} - `}Reminder Task &nbsp; &nbsp;
                                    <span className="board-badge">
                                        <a href="#">{count}</a>
                                    </span>
                                </h6>
                            </div>

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Datatable data={reminderTaskList} columns={columns} actionPermissionPrefix={'stock'} disableSearch={true} isReminderAdminOrManager={isAdminOrManager} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="reset" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
                :
                <>
                    <br></br>
                    <div className="heading-title">
                        <h6>Reminder Task &nbsp; &nbsp;<span className="board-badge">
                            <a href="#">{count}</a>
                        </span></h6>
                    </div>
                    <Datatable data={reminderTaskList} columns={columns} actionPermissionPrefix={'stock'} disableSearch={true} isReminderAdminOrManager={isAdminOrManager} />
                </>
            }
        </>
    );

}

export default ReminderTaskModel;
