import React, { useState, useEffect } from 'react';
import { checkPermission } from '../../utils/commonUtils';
import InvesmentPermissionTable from './InvesmentPermissionTable';
import Datatable from '../../components/Table/Datatable';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UPDATE_USER_PERMISSION } from '../../api/endpoints'
import { get, put } from '../../api'



export default function UserPermissionTab({ initialValues, userID, selectedTab, permissionList, userPermissions, disabledCheckboxes, userSelectedPermissionIds, handleTabChange, handleBackClick, handlePermissionChange }) {
    const [loading, setLoading] = useState(true);
    const [filteredData, setFilteredData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selectedPermission, setSelectedPermission] = useState([]);
    var localSelectedPermissions = [...userSelectedPermissionIds]; // Initialize with userSelectedPermissionIds
    const notify = (message, type) => toast(message, { type });

    useEffect(() => {
        dataTable();
    }, [permissionList, userSelectedPermissionIds]);

    const dataTable = () => {
        const generatedColumns = [];
        const tableData = [];
        const columnNames = new Set();

        permissionList.forEach((group) => {
            const { permissions, permission_type: groupName } = group;

            // Create a row for this group with the group name
            const rowData = { Name: group.group.permission_type }; // Use permission_type as the group name

            // Process permissions for this group
            permissions.forEach((permission) => {
                const { id, permission_name } = permission;

                // Add a column for this permission if not already added
                if (!columnNames.has(permission_name)) {
                    columnNames.add(permission_name);

                    generatedColumns.push({
                        dataField: permission_name,
                        text: (
                        <div style={{ display: 'flex', minWidth: '150px', maxWidth:'200px', alignItems: 'center', justifyContent: 'center' }}>
                            <input
                                type="checkbox"
                                onChange={(e) => onHeaderCheckboxChange(permission_name, e.target.checked)}
                                style={{ width: '17px', height: '17px', marginRight: '10px' }}
                            />
                            {permission_name}
                        </div>
                        ),
                        sort: false,
                        formatter: (cell, row) => {
                            if (cell && cell.permission_name) {
                                const { id, permission_name } = cell;

                                // Check if the permission is selected
                                const hasPermission = localSelectedPermissions.includes(id);

                                return (
                                    <input
                                        type="checkbox"
                                        name={permission_name}
                                        value={id}
                                        checked={hasPermission}
                                        onChange={() => onCheckboxChange(id)}
                                        style={{ width: '17px', height: '17px', display: 'block', margin: '0 auto' }}
                                    />
                                );
                            }
                            return cell; // Return the cell value as-is if it doesn't match the expected structure
                        },
                    });
                }
                // Add the permission id to the rowData for this specific permission
                rowData[permission_name] = { id, permission_name };
                rowData['userPermissionsData'] = userSelectedPermissionIds;
            });

            tableData.push(rowData);
        });

        generatedColumns.unshift({
            dataField: 'Name',
            text: 'Name',
            sort: true,
        });

        setColumns(generatedColumns);
        setFilteredData(tableData);
        setLoading(false);
    };

    const onHeaderCheckboxChange = (columnName, isChecked) => {
        setFilteredData((prevFilter) => {
            const updatedPermissions = [...localSelectedPermissions];

            prevFilter.forEach((row) => {
                const cell = row[columnName];
                if (cell && cell.id) {
                    const permissionId = cell.id;

                    if (isChecked && !updatedPermissions.includes(permissionId)) {
                        updatedPermissions.push(permissionId);
                    } else if (!isChecked && updatedPermissions.includes(permissionId)) {
                        updatedPermissions.splice(updatedPermissions.indexOf(permissionId), 1);
                    }
                }
            });

            localSelectedPermissions = updatedPermissions;
            setSelectedPermission(localSelectedPermissions);

            // Update rows for re-render
            return prevFilter.map((data) => ({
                ...data,
                userPermissionsData: [...updatedPermissions],
            }));
        });
    };

    const onCheckboxChange = (id) => {
        setFilteredData((prevFilter) => {
            let updatedPermissions = [...localSelectedPermissions];

            if (updatedPermissions.includes(id)) {
                updatedPermissions = updatedPermissions.filter(
                    (permissionId) => permissionId !== id
                );
            } else {
                updatedPermissions.push(id);
            }

            localSelectedPermissions = updatedPermissions;

            setSelectedPermission(localSelectedPermissions);
            // Update rows for re-render
            return prevFilter.map((data) => ({
                ...data,
                userPermissionsData: [...updatedPermissions],
            }));
        });
    };

    const handleSave = () => {

        const formattedPermissions = selectedPermission.map((selectedId) => {
            let matchedPermissionType = 'Unknown'; // Default fallback type

            permissionList.forEach((group) => {
                const foundPermission = group.permissions.find((perm) => perm.id === selectedId);
                if (foundPermission) {
                    matchedPermissionType = group.permission_type;
                }
            });

            if (!matchedPermissionType) {
                console.warn(`Permission type not found for ID: ${selectedId}`);
            }

            return {
                PermissionId: selectedId,
                Permission_type: matchedPermissionType,
            };
        });

        userUpdatePermission(formattedPermissions);
    };

    const userUpdatePermission = async (formattedPermissions) => {
        try {
            const url = UPDATE_USER_PERMISSION;

            // Prepare payload with all selected PermissionIds
            const bodyPayload = {
                UserId: userID,
                PermissionIds: formattedPermissions.map((perm) => perm.PermissionId), // Extract PermissionId array
                Permission_type: formattedPermissions[0]?.Permission_type || 'ALL', // Fallback type
            };


            // Make API call
            const response = await put(url, bodyPayload);
            if (response) {
                notify(`Permission shared successfully with ${initialValues.user_name}`, 'success');
            } else {
                notify('Permission update failed...', 'error');
            }
        } catch (error) {
            notify(error?.response?.data?.message || 'An error occurred', 'error');
            console.error('API Error:', error);
        }
    };

    return (
        <>
            {initialValues.manager && <div key="user-permission-tab" className='card'>
                <div className='card-body'>
                    {/* Tab buttons */}
                    <ul className="nav nav-tabs">
                        <li key={'category-tab'} className="nav-item" onClick={(e) => handleTabChange('category', e)}>
                            <a className={`nav-link ${selectedTab === 'category' ? 'active' : ''}`} href="#">Category</a>
                        </li>
                        <li key={'investment-tab'} className="nav-item" onClick={(e) => handleTabChange('investment', e)}>
                            <a className={`nav-link ${selectedTab === 'investment' ? 'active' : ''}`} href="#">Investment</a>
                        </li>
                    </ul>
                </div>

                {selectedTab === 'category' && (
                    <div>
                        <div className="row">
                            <div className="col-6">
                                <legend className="text-bold">Permissions</legend>
                            </div>
                        </div>

                        {!loading && filteredData.length > 0 ? (
                            <Datatable data={filteredData} columns={columns} disableCsv={true} FreezeColumn={true} disableSearch={true} />
                        ) : (
                            <div>No permissions available.</div>
                        )}

                        {loading && <div style={{ margin: '100px' }}>Loading...</div>}

                        {/* Save button below the table */}
                        <div className="text-right mt-3">
                            <button className="btn btn-primary" onClick={handleSave}>
                                Save
                            </button>
                        </div><br></br>
                    </div>
                )}

                {(selectedTab === 'investment') && (
                    <div >
                        <InvesmentPermissionTable userId={userID} isManager={checkPermission(userPermissions, 'isManager')} />
                    </div>
                )}
            </div>}
        </>
    );
}
