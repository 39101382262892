import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { UserPermissionStore } from '../Store/UserPermission';
import { checkPermission } from '../utils/commonUtils';
function SideBar({ updateImageFunction }) {



    const pathname = window.location.pathname;
    const parts = pathname.split('/');
    const urlName = parts[1];

    const isOverviewActive = urlName === 'portfolio-summary';
    const isStockListActive = urlName === 'liquid-asset-list' || urlName === 'view-liquid-asset' || urlName === 'edit-liquid-asset' || urlName === 'add-liquid-asset';
    const isArchivedListActive = urlName === 'archived-list';
    const isRealEstateListActive = urlName === 'private-equity-list' || urlName === 'view-private-equity' || urlName === 'edit-private-equity' || urlName === 'add-private-equity';
    const isMineralAssetListActive = urlName === 'royalty-income-list' || urlName === 'view-royalty-income' || urlName === 'edit-royalty-income' || urlName === 'add-royalty-income';
    const isPersonalAssetsActive = urlName === 'personal-assets-list' || urlName === 'view-personal-assets' || urlName === 'edit-personal-assets' || urlName === 'add-personal-assets';
    const isRealEstateHoldingActive = urlName === 'real-estate-holding-list' || urlName === 'view-real-estate-holdings' || urlName === 'edit-real-estate-holdings' || urlName === 'add-income-expense/' || urlName === 'edit-income-expense' || urlName === 'view-income-expense' || urlName === 'add-real-estate-holdings';
    const isUserActive = urlName === 'users' || urlName === 'user-info';
    const isEstatePlanning = urlName === 'estate-planning';
    const isReminderList = urlName === 'reminder-list';
    const isOperatingBusiness = urlName === 'operating-business' || urlName === 'operating-business-view' || urlName === 'view-operating-business' || urlName === 'add-operating-business' || urlName === 'edit-operating-business';
    const isTrusteeInstructions = urlName === 'trustee-instructions';
    // Initialize isInvestmentCategoryOpen based on isStockListActive
    const [isInvestmentCategoryOpen, setIsInvestmentCategoryOpen] = useState(isStockListActive);
    const [userPermissions, setUserPermissions] = useState([]);
    const { checkAndUpdatePermissions } = UserPermissionStore();

    const [isAdminMenuOpen, setIsAdminMenuOpen] = useState(isArchivedListActive || isUserActive);

    const toggleAdminMenu = () => {
        setIsAdminMenuOpen(!isAdminMenuOpen);
    };

    useEffect(() => {
        setIsInvestmentCategoryOpen(isStockListActive);
        setIsAdminMenuOpen(isArchivedListActive || isUserActive);
        authCheckAndUpdate();
    }, [isStockListActive, isArchivedListActive, isUserActive]);

    const authCheckAndUpdate = async () => {
        let tempPermission = await checkAndUpdatePermissions();
        setUserPermissions(tempPermission || []);
    };
    // Adjust the submenu to remain open if the current location is '/liquid-asset-list'
    useEffect(() => {
        setIsInvestmentCategoryOpen(isStockListActive);
        authCheckAndUpdate()
    }, []); // Depend on isStockListActive to re-evaluate when it changes

    // Toggle function for Investment Category submenu
    const toggleInvestmentCategory = () => {
        setIsInvestmentCategoryOpen(!isInvestmentCategoryOpen);
    };
    return (
        <div className="flapt-sidemenu-wrapper no-print">
            <div className="flapt-logo">
                <a href="/portfolio-summary" style={{
                    color: '#fff',
                    fontSize: '22px',
                    textAlign: 'center'
                }}>
                    <img
                        // src={updateImageFunction ? "/img/WMS - ICON_sidepar.svg" : "/img/WMS - LOGO.svg"}
                        src={updateImageFunction ? "/img/WMS - ICON_sidepar.svg" : "/img/WMS - LOGO.png"}
                        style={{ height: '40px' }}
                        alt="logo" />
                </a>
            </div>
            <div className="flapt-sidenav" id="flaptSideNav">
                <div className="side-menu-area">
                    <nav>
                        <ul className="sidebar-menu tree" data-widget="tree" style={{ marginTop: '80px' }}>
                            {/* {(checkPermission(userPermissions, 'overview') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) && */}
                            <li className={isOverviewActive ? 'active' : ''}>
                                <Link to="/portfolio-summary">
                                    <i className="zmdi zmdi-chart-donut"></i>
                                    <span>Portfolio Summary</span>
                                </Link>
                            </li>
                            {/* } */}
                            {(checkPermission(userPermissions, 'view-stock') || checkPermission(userPermissions, 'view-real-estate-hld') || checkPermission(userPermissions, 'view-real-estate-invt') || checkPermission(userPermissions, 'view-mineral-asset') || checkPermission(userPermissions, 'view-personal-asset') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) &&
                                <li className={`treeview menu ${isInvestmentCategoryOpen || isStockListActive || isRealEstateListActive || isMineralAssetListActive || isPersonalAssetsActive || isRealEstateHoldingActive ? 'menu-open' : ''}`}>
                                    <a href="#!" onClick={toggleInvestmentCategory}>
                                        <i className="zmdi zmdi-money"></i>
                                        <span>Asset Class</span>
                                        <i className={`fa fa-angle-${isInvestmentCategoryOpen || isStockListActive || isRealEstateListActive || isMineralAssetListActive || isPersonalAssetsActive || isRealEstateHoldingActive ? 'down' : 'right'}`}></i>
                                    </a>
                                    <ul className="treeview-menu" style={{ display: isInvestmentCategoryOpen || isStockListActive || isRealEstateListActive || isMineralAssetListActive || isPersonalAssetsActive || isRealEstateHoldingActive ? 'block' : 'none' }}>
                                        {(checkPermission(userPermissions, 'view-stock') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) && <li className={isStockListActive ? 'active' : ''}>
                                            <Link to="/liquid-asset-list" style={{ width: '175px' }}>
                                                <i className="zmdi zmdi-chart"></i>
                                                Liquid Assets
                                            </Link>
                                        </li>}
                                        {(checkPermission(userPermissions, 'view-real-estate-hld') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) && <li className={isRealEstateHoldingActive ? 'active' : ''}>
                                            <Link to="/real-estate-holding-list">
                                                <i className="zmdi zmdi-store"></i>
                                                Real Estate Holdings
                                            </Link>
                                        </li>}
                                        {(checkPermission(userPermissions, 'view-real-estate-invt') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) && <li className={isRealEstateListActive ? 'active' : ''}>
                                            <Link to="/private-equity-list">
                                                <i className="zmdi zmdi-city"></i>
                                                <span>Private Equity</span>
                                            </Link>
                                        </li>}
                                        {(checkPermission(userPermissions, 'view-mineral-asset') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) && <li className={isMineralAssetListActive ? 'active' : ''}>
                                            <Link to="/royalty-income-list">
                                                <i className="zmdi zmdi-landscape"></i>
                                                <span>Royalty Income</span>
                                            </Link>
                                        </li>}
                                        {(checkPermission(userPermissions, 'view-personal-asset') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) && <li className={isPersonalAssetsActive ? 'active' : ''}>
                                            <Link to="/personal-assets-list">
                                                <i className="zmdi zmdi-home"></i>
                                                <span>Personal Assets</span>
                                            </Link>
                                        </li>}
                                    </ul>
                                </li>
                            }
                            {(checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager') || checkPermission(userPermissions, 'view-operating-business')) &&
                                <li className={isOperatingBusiness ? 'active' : ''}>
                                    <Link to="/operating-business">
                                        <i className="fa fa-bar-chart"></i>
                                        <span>Operating Business</span>
                                    </Link>
                                </li>
                            }
                            {(checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager') || checkPermission(userPermissions, 'view-estate-planning')) &&
                                <li className={isEstatePlanning ? 'active' : ''}>
                                    <Link to="/estate-planning">
                                        <i className="zmdi zmdi-view-dashboard"></i>
                                        <span>Estate Planning</span>
                                    </Link>
                                </li>
                            }
                            {(checkPermission(userPermissions, 'view-stock') || checkPermission(userPermissions, 'view-real-estate-hld') || checkPermission(userPermissions, 'view-real-estate-invt') || checkPermission(userPermissions, 'view-mineral-asset') || checkPermission(userPermissions, 'view-personal-asset') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) &&
                                <li className={isReminderList ? 'active' : ''}>
                                    <Link to="/reminder-list">
                                        <i className="zmdi zmdi-time-restore"></i>
                                        <span>Reminder List</span>
                                    </Link>
                                </li>
                            }
                            {(checkPermission(userPermissions, 'view-archive')) &&
                                <li className={isArchivedListActive ? 'active' : ''}>
                                    <Link to="/archived-list">
                                        <i className="zmdi zmdi-archive"></i>
                                        <span>Archived</span>
                                    </Link>
                                </li>
                            }
                            <li className={isTrusteeInstructions ? 'active ' : ''}>
                                <Link to="/trustee-instructions">
                                    <i className="zmdi zmdi-comment-alt-text "></i>
                                    <span>Trustee Instructions</span>
                                </Link>
                            </li>
                            {(checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) &&
                                <li className={`treeview menu ${isArchivedListActive || isUserActive ? 'menu-open' : ''}`}>
                                    <a href="#!" onClick={toggleAdminMenu}>
                                        <i className="fa fa-cog"></i>
                                        <span>Admin</span>
                                        <i className={`fa fa-angle-${isAdminMenuOpen ? 'down' : 'right'}`}></i>
                                    </a>
                                    <ul className="treeview-menu" style={{ display: isAdminMenuOpen ? 'block' : 'none' }}>
                                        {(checkPermission(userPermissions, 'view-archive') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) && <li className={isArchivedListActive ? 'active' : ''}>
                                            <Link to="/archived-list">
                                                <i className="zmdi zmdi-archive"></i>
                                                <span>Archived</span>
                                            </Link>
                                        </li>}
                                        {(checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) &&
                                            <li className={isUserActive ? 'active' : ''}>
                                                <Link to="/users">
                                                    <i className="zmdi zmdi-accounts-alt"></i>
                                                    <span>User Management</span>
                                                </Link>
                                            </li>
                                        }
                                    </ul>
                                </li>
                            }

                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default SideBar;


