import React, { useState, useEffect, useContext } from 'react';
import { PageLoader } from '../Context/PageLoader';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ReactComponent as LOGO } from './WMS.svg';
import logo from './WMS.png';
import { postForm, withoutAuthGet } from '../api';
import { SET_PASSWORD, SET_PASSWORD_TOKEN_VERIFY } from '../api/endpoints';
import { concatUrlWithId } from '../utils/commonUtils';
function SetPassword() {
  const { token } = useParams();
  // const token = 'test1234'; 
  const [Invalidpass, setInvalidpass] = useState('');
  const [fromValue, setfromValue] = useState([]);
  const { loading, setLoading } = useContext(PageLoader);
  const history = useNavigate()
  const [tokenStatus, setTokenStatus] = useState(true);
  const [responseStatus, setResponseStatus] = useState(false);
  const [message, setMessage] = useState('');
  const [initialValues, setInitialValues] = useState({
    username: '',
    email: '',
    phone: '',
  });
  const [showCriteria, setShowCriteria] = useState(true);

  useEffect(() => {
    // setLoading(false)
    setLoading(true)

    const userFetch = async () => {
      const verifyToken = await withoutAuthGet(concatUrlWithId(SET_PASSWORD_TOKEN_VERIFY, token))

      if (verifyToken) {
        if (verifyToken.data.success == true) {
          setTokenStatus(true);
          let values = {
            id: verifyToken.data.data.id,
            username: verifyToken.data.data.username,
            email: verifyToken.data.data.email,
            phone: verifyToken.data.data.phone
          };
          setInitialValues(values);
        } else {
          setTokenStatus(false);
          setMessage(verifyToken.data.message)
        }

      }
      setLoading(false)
    }
    userFetch();
  }, [])

  const validatePassword = values => {
    const errors = {};
    const password = values.new_password;
    const confirm_password = values.confirm_password;

    if (!password) {
      errors.new_password = 'New password is required!';
      setShowCriteria(true);
    } else if (password.length < 8) {
      errors.new_password = 'Password must be at least 8 characters long!';
    } else if (!/(?=.*[a-z])/.test(password)) {
      errors.new_password = 'Password must contain at least one lowercase letter!';
    } else if (!/(?=.*[A-Z])/.test(password)) {
      errors.new_password = 'Password must contain at least one uppercase letter!';
    } else if (!/(?=.*\d)/.test(password)) {
      errors.new_password = 'Password must contain at least one numeric digit!';
    } else if (!/(?=.*[!@#$%^&*])/.test(password)) {
      errors.new_password = 'Password must contain at least one special character!';
    } else {
      // Password meets criteria, hide the criteria notes
      setShowCriteria(false);
    }

    if (!confirm_password) {
      errors.confirm_password = 'Confirm password is required!';
    } else if (values.confirm_password !== password) {
      errors.confirm_password = 'Passwords do not match!';
    }

    return errors;
  };

  const handelClick = () => {
    window.location = '/login';
  }

  return (<div className="main-content- h-100vh">

    {!responseStatus && tokenStatus && <Formik
      initialValues={{ new_password: '', confirm_password: '' }}
      validate={validatePassword}
      onSubmit={async (values) => {
        await new Promise((r) => setTimeout(r, 500));
        try {
          // setLoading(true)
          // Your form submission logic
          const response = await postForm(SET_PASSWORD, {
            new_password: values.new_password,
            confirm_password: values.confirm_password,
            token: token
          });
          //  setLoading(false) 
          if (response.success == true) {
            setResponseStatus(true);
            // setTimeout(() => {
            //   window.location.href = '/login';
            // }, 20000);
          } else {
            setInvalidpass(response.response.data.error);
          }
        } catch (error) {
          setInvalidpass(error.response.data.error);
          console.error('API Error:', error);
        }
      }}
    >
      <div className="container h-100">
        <div className="row h-100 align-items-center justify-content-center">
          <div className="col-sm-10 col-md-7 col-lg-5">
            <div className="middle-box">
              <div className="card-body">
                <div className="log-header-area card p-4 mb-4 text-center">
                  {/* <LOGO /> */}
                  <img src={logo} className="App-logo" alt="logo" />
                </div>

                <div className="card">
                  <div className="card-body p-4">
                    <div className="mb-3">
                      <h3>Set Your Password</h3>
                      {/* <span>{initialValues.username} </span> */}
                    </div>
                    <Form>

                      <div className="mb-3">
                        <label className="form-label" htmlFor="formrow-stock-code-input">New Password</label>
                        <Field className="form-control" type="password" id="formrow-stock-code-input" name="new_password" />
                        <ErrorMessage className="text-danger" name="new_password" component="div" />
                      </div>

                      <div className="mb-3">
                        <label className="form-label" htmlFor="formrow-stock-code-input1">Confirm Password</label>
                        <Field className="form-control" type="password" id="formrow-stock-code-input1" name="confirm_password" />
                        <ErrorMessage className="text-danger" name="confirm_password" component="div" />
                      </div>

                      {/** Display custom error message */}
                      <div className="form-group mb-3">
                        <div className="text-danger">
                          {Invalidpass}
                          <ErrorMessage className="text-danger" name="status" />
                        </div>
                      </div>

                      {showCriteria && (
                        <div className="password-criteria">
                          <span>Password Criteria:</span>
                          <ul>
                            <li>Password must be at least 8 characters long.</li>
                            <li>- at least one lowercase letter.</li>
                            <li>- at least one uppercase letter.</li>
                            <li>- at least one numeric digit.</li>
                            <li>- at least one special character (e.g., !@#$%^&*).</li>
                          </ul>
                        </div>
                      )}<br></br>

                      <div className="form-group mb-3">
                        <button className="btn btn-primary btn-lg w-100" type="submit">
                          Continue
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Formik>}

    {!responseStatus && !tokenStatus &&
      <div className="container h-100">
        <div className="row h-100 align-items-center justify-content-center">
          <div className="col-sm-10 col-md-7 col-lg-5">
            <div className="middle-box">
              <div className="log-header-area card p-4 mb-4 text-center">
                {/* <LOGO /> */}
                <img src={logo} className="App-logo" alt="logo" />
              </div>
              <div className="card">
                <div className="card-body p-4">
                  <div className="c476543f4 cfee98151">

                    <div className="c49c1ba2f c7425a8d7 c50747c71" data-event-id="">
                      <div className="c1415f71d c8c497cb1">
                        <span className="c39e239ae error-cross"></span>
                      </div>

                      <section className="c57eb80bc c15d84f93">
                        <h1>Invalid Link</h1>
                        <p>{message}, return to the login page and select "Forgot Your Password" to send a new email.</p>
                        <div>
                          <a href="#" onClick={handelClick}>Back to WMS</a>
                        </div>

                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}

    {responseStatus &&
      <div className="container h-100">
        <div className="row h-100 align-items-center justify-content-center">
          <div className="col-sm-10 col-md-7 col-lg-5">
            <div className="middle-box">
              <div className="log-header-area card p-4 mb-4 text-center">
                {/* <LOGO /> */}
                <img src={logo} className="App-logo" alt="logo" />
              </div>
              <div className="card">
                <div className="card-body p-4">
                  <div className="c476543f4 cfee98151">

                    <div className="c49c1ba2f c7425a8d7 c50747c71" data-event-id="">
                      <div className="c1415f71d c8c497cb1">
                        <span className="c39e239ae error-cross"></span>
                      </div>

                      <section className="c57eb80bc c15d84f93">
                        <h3>Password set successfully</h3>
                        <p>Your password has been set successfully. Please return to the login page.</p>
                        <div>
                          <a href="#" onClick={handelClick}>Back to WMS</a>
                        </div>

                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
  </div>
  )
}

export default SetPassword;