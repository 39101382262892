import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import IncomeExpenseList from '../pages/investment/RealEstateHoldings/incomeExpense/IncomeExpenseList';
import TenantList from '../pages/investment/RealEstateHoldings/incomeExpense/TenantList';
import { checkPermission } from '../utils/commonUtils';
import AuditLogTable from './AuditLogTable';
import Documents from './DocumentManager/Documents';
import StockInterestTable from '../pages/investment/StockHoldings/StockInterestTable';
import ReminderTabList from './Reminder/ReminderTabList';
import EstatePlanning from '../pages/EstatePlannig/EstatePlanning';
import CompanyInvesting from '../pages/EstatePlannig/CompanyInvesting';

const TabComponent = ({ props, investmentId, permissions, hangleValueChange, incExptab = false,
  isArchived, uploadPermission, printMode = false, isStockView = false, stockInvId = null, hideAuditLog = false,
  onChangeInterest = () => { }, onDataLoadingComplete = () => { }, documentTypeKey, isOperatingBusiness = false, EstatePlannings = false }) => {
  const [selectedTab, setSelectedTab] = useState(EstatePlannings ? 'esatePlans' : (!printMode ? (incExptab ? 'income' : 'documents') : ''));
  const [viewMode, setViewMode] = useState(true);
  const location = useLocation();
  const handleTabChange = (tabName, event) => {
    event.preventDefault();
    setSelectedTab(tabName);
    setViewMode(true);
  };

  useEffect(() => {
    if (EstatePlannings) {
      setSelectedTab('esatePlans')
    }
    else if (!printMode) {
      if (!checkPermission(permissions, 'isAdmin') && !checkPermission(permissions, 'isManager')) {
        if (!checkPermission(permissions, 'view-exp-real-estate-hld') && !checkPermission(permissions, 'view-inc-real-estate-hld')) {
          setSelectedTab('documents');
        } else if (!checkPermission(permissions, 'view-inc-real-estate-hld') && checkPermission(permissions, 'view-exp-real-estate-hld') && incExptab) {
          setSelectedTab('expense');
        } else if (checkPermission(permissions, 'view-inc-real-estate-hld') && incExptab) {
          setSelectedTab('income');
        } else {
          setSelectedTab('tenant');
        }
      }
    } else {
      setSelectedTab('');
    }
  }, [])

  useEffect(() => {
    setViewMode(true)
  }, [printMode]);



  const onComplete = () => {
    onDataLoadingComplete();
  }

  const onIncExpComplete = (key) => {
    onDataLoadingComplete(key);
  }
  return (
    <>
      {!EstatePlannings && (
        <div className='card'>
          <div className='card-body'>
            {!EstatePlanning && (
              <legend className='text-bold pb-4'>Transaction and Document Upload and Tracking</legend>
            )}
            {/* Tab buttons */}
            <ul className="nav nav-tabs no-print">
              {/* Income tab */}
              {(checkPermission(permissions, 'isAdmin') || checkPermission(permissions, 'isManager') || checkPermission(permissions, 'view-inc-real-estate-hld')) && (incExptab) && (
                <li className="nav-item" onClick={(e) => handleTabChange('income', e)}>
                  <a className={`nav-link ${selectedTab === 'income' ? 'active' : ''}`} style={{ borderBottom: '0px' }} href="#">Income</a>
                </li>
              )}
              {/* Expense tab */}
              {(checkPermission(permissions, 'isAdmin') || checkPermission(permissions, 'isManager') || checkPermission(permissions, 'view-exp-real-estate-hld')) && (incExptab) && (
                <li className="nav-item" onClick={(e) => handleTabChange('expense', e)}>
                  <a className={`nav-link ${selectedTab === 'expense' ? 'active' : ''}`} style={{ borderBottom: '0px' }} href="#">Expense</a>
                </li>
              )}
              {/* Tenant Info */}
              {(checkPermission(permissions, 'isAdmin') || checkPermission(permissions, 'isManager') || checkPermission(permissions, 'view-exp-real-estate-hld')) && (incExptab) && (
                <li className="nav-item" onClick={(e) => handleTabChange('tenant', e)}>
                  <a className={`nav-link ${selectedTab === 'tenant' ? 'active' : ''}`} style={{ borderBottom: '0px' }} href="#">Tenant Info</a>
                </li>
              )}
              {/* Documents tab */}
              {(checkPermission(permissions, 'isAdmin') || checkPermission(permissions, 'isManager') || checkPermission(permissions, 'view-real-estate-hld') || checkPermission(permissions, 'view-real-estate-invt') || checkPermission(permissions, 'view-stock') || checkPermission(permissions, 'view-mineral-asset') || checkPermission(permissions, 'view-personal-asset') || checkPermission(permissions, 'view-archive')) && (
                <li className="nav-item" onClick={(e) => handleTabChange('documents', e)}>
                  <a className={`nav-link ${selectedTab === 'documents' ? 'active' : ''}`} style={{ borderBottom: '0px' }} href="#">Upload Files</a>
                </li>
              )}
              {/* Interset Tab */}
              {((checkPermission(permissions, 'isAdmin') || checkPermission(permissions, 'isManager') || checkPermission(permissions, 'view-stock')) && isStockView) && (
                <li className="nav-item" onClick={(e) => handleTabChange('interest', e)}>
                  <a className={`nav-link ${selectedTab === 'interest' ? 'active' : ''}`} style={{ borderBottom: '0px' }} href="#">Interest Earned</a>
                </li>
              )}
              {/* Auditlog tab */}
              {/* {(checkPermission(permissions, 'isAdmin') || checkPermission(permissions, 'isManager')) && ( */}
              <li className="nav-item" onClick={(e) => handleTabChange('reminder', e)}>
                <a className={`nav-link ${selectedTab === 'reminder' ? 'active' : ''}`} style={{ borderBottom: '0px' }} href="#">Completed Reminders</a>
              </li>
              {/* )} */}
              {/* Auditlog tab */}
              {(!hideAuditLog) && (checkPermission(permissions, 'isAdmin') || checkPermission(permissions, 'isManager')) && (
                <li className="nav-item" onClick={(e) => handleTabChange('auditlog', e)}>
                  <a className={`nav-link ${selectedTab === 'auditlog' ? 'active' : ''}`} style={{ borderBottom: '0px' }} href="#">Audit Log</a>
                </li>
              )}
            </ul>
          </div>
          {/* Conditional rendering based on selected tab */}

          {((selectedTab === 'income' || printMode) && incExptab) &&
            <div >
              <IncomeExpenseList investmentId={investmentId} headerName={'Income'} keyValue={'INCOME'} isTabMode={viewMode}
                createPermission={!isArchived && (checkPermission(permissions, 'create-inc-real-estate-hld') || checkPermission(permissions, 'isAdmin') || checkPermission(permissions, 'isManager'))}
                viewPermission={checkPermission(permissions, 'isAdmin') || checkPermission(permissions, 'isManager') || checkPermission(permissions, 'view-inc-real-estate-hld')}
                editPermission={!isArchived && (checkPermission(permissions, 'edit-inc-real-estate-hld') || checkPermission(permissions, 'isAdmin') || checkPermission(permissions, 'isManager'))}
                deletePermission={!isArchived && (checkPermission(permissions, 'delete-inc-real-estate-hld') || checkPermission(permissions, 'isAdmin') || checkPermission(permissions, 'isManager'))}
                hangleValueChange={hangleValueChange} actionPermissionPrefix="inc-real-estate-hld" isAdminOrManager={checkPermission(permissions, 'isAdmin') || checkPermission(permissions, 'isManager')}
                permissionsArr={permissions} onIncExpComplete={onIncExpComplete} enablePrint={printMode}
              />
            </div>
          }
          {((checkPermission(permissions, 'isAdmin') || checkPermission(permissions, 'isManager') || checkPermission(permissions, 'view-exp-real-estate-hld')) && ((selectedTab === 'expense' || printMode) && incExptab)) &&
            <div >
              <IncomeExpenseList investmentId={investmentId} headerName={'Expense'} keyValue={'EXPENSE'} isTabMode={viewMode}
                createPermission={!isArchived && (checkPermission(permissions, 'create-exp-real-estate-hld') || checkPermission(permissions, 'isAdmin') || checkPermission(permissions, 'isManager'))}
                viewPermission={checkPermission(permissions, 'view-exp-real-estate-hld') || checkPermission(permissions, 'isAdmin') || checkPermission(permissions, 'isManager')}
                editPermission={!isArchived && (checkPermission(permissions, 'edit-exp-real-estate-hld') || checkPermission(permissions, 'isAdmin') || checkPermission(permissions, 'isManager'))}
                deletePermission={!isArchived && (checkPermission(permissions, 'delete-exp-real-estate-hld') || checkPermission(permissions, 'isAdmin') || checkPermission(permissions, 'isManager'))}
                hangleValueChange={hangleValueChange} actionPermissionPrefix="exp-real-estate-hld" isAdminOrManager={checkPermission(permissions, 'isAdmin') || checkPermission(permissions, 'isManager')}
                permissionsArr={permissions} enablePrint={printMode} onIncExpComplete={onIncExpComplete}
              />
            </div>
          }
          {(((selectedTab === 'tenant' || printMode) && incExptab)) &&
            <div >
              <TenantList investmentId={investmentId} headerName={'Tenant Info'} keyValue={'TENANT'} isTabMode={viewMode}
                // createPermission={!isArchived && (checkPermission(permissions, 'create-exp-real-estate-hld') || checkPermission(permissions, 'isAdmin') || checkPermission(permissions, 'isManager'))}
                // viewPermission={checkPermission(permissions, 'view-exp-real-estate-hld') || checkPermission(permissions, 'isAdmin') || checkPermission(permissions, 'isManager')}
                // editPermission={!isArchived && (checkPermission(permissions, 'edit-exp-real-estate-hld') || checkPermission(permissions, 'isAdmin') || checkPermission(permissions, 'isManager'))}
                // deletePermission={!isArchived && (checkPermission(permissions, 'delete-exp-real-estate-hld') || checkPermission(permissions, 'isAdmin') || checkPermission(permissions, 'isManager'))}
                hangleValueChange={hangleValueChange}
                //  actionPermissionPrefix="exp-real-estate-hld" isAdminOrManager={checkPermission(permissions, 'isAdmin') || checkPermission(permissions, 'isManager')}
                permissionsArr={permissions} enablePrint={printMode} onIncExpComplete={onIncExpComplete}
              />
            </div>
          }
          {(!printMode && selectedTab === 'documents') && (
            <div >
              <div className="introduction-farm" style={{ paddingBottom: '10px' }}>
                <div className="content-wraper-area">
                  <div className="dashboard-area">
                    <div className="container-fluid">
                      <div className="col-12">
                        <div className="card">
                          <div className="card-body tab-data-model" >
                            <div style={{ overflow: 'auto' }}>
                              <Documents investmentId={investmentId} uploadPermission={uploadPermission} isOperatingBusiness={isOperatingBusiness} businessID={investmentId} isArchived={isArchived} documentTypeKey={documentTypeKey} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {((selectedTab === 'interest' || printMode) && isStockView) && (
            <div >
              <div className="introduction-farm" style={{ paddingBottom: '10px' }}>
                <div className="content-wraper-area">
                  <div className="dashboard-area">
                    <div className="container-fluid">
                      <div className="col-12">
                        <div className="card">
                          <div className="card-body tab-data-model" >
                            <div style={{ overflow: 'auto' }}>
                              <StockInterestTable investmentId={investmentId} stockInvId={stockInvId} uploadPermission={permissions} isArchived={isArchived} onChangeInterest={onChangeInterest} printMode={printMode} onComplete={onComplete} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(!printMode && selectedTab === 'reminder') && (
            <div >
              <div className="introduction-farm" style={{ paddingBottom: '10px' }}>
                <div className="content-wraper-area">
                  <div className="dashboard-area">
                    <div className="container-fluid">
                      <div className="col-12">
                        <div className="card">
                          <div className="card-body tab-data-model" >
                            <div style={{ overflow: 'auto' }}>
                              <ReminderTabList recordId={investmentId} entityType={'investment'} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(!printMode && (checkPermission(permissions, 'isAdmin') || checkPermission(permissions, 'isManager')) && (selectedTab === 'auditlog')) && (
            <div >
              <div className="introduction-farm" style={{ paddingBottom: '10px' }}>
                <div className="content-wraper-area">
                  <div className="dashboard-area">
                    <div className="container-fluid">
                      <div className="col-12">
                        <div className="card">
                          <div className="card-body tab-data-model">
                            <div style={{ overflow: 'auto' }}>
                              <AuditLogTable investmentId={investmentId} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {EstatePlannings && (
        <div className="main-content introduction-farm">
          <div className="content-wraper-area">
            <div className="dashboard-area">
              <div className="container-fluid">
                <div className='card'>
                  <div className='card-body'>
                    {/* Tab buttons */}
                    <ul className="nav nav-tabs no-print">
                      <li className="nav-item" onClick={(e) => handleTabChange('esatePlans', e)}>
                        <a className={`nav-link ${selectedTab === 'esatePlans' ? 'active' : ''}`} style={{ borderBottom: '0px' }} href="#">Estate Planning</a>
                      </li>
                      <li className="nav-item" onClick={(e) => handleTabChange('companyInvesting', e)}>
                        <a className={`nav-link ${selectedTab === 'companyInvesting' ? 'active' : ''}`} style={{ borderBottom: '0px' }} href="#">Company Investing</a>
                      </li>
                    </ul>
                  </div>
                  {/* Conditional rendering based on selected tab */}

                  {EstatePlannings && selectedTab === 'esatePlans' && (
                    <div >
                      <div className="introduction-farm" style={{ paddingBottom: '10px' }}>
                        <div className="content-wraper-area">
                          <div className="dashboard-area">
                            <div className="container-fluid">
                              <div className="col-12">
                                <div className="card">
                                  <div className="card-body tab-data-model">
                                    <div style={{ overflow: 'auto' }}>
                                      <EstatePlanning />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {EstatePlannings && selectedTab === 'companyInvesting' && (
                    <div >
                      <div className="introduction-farm" style={{ paddingBottom: '10px' }}>
                        <div className="content-wraper-area">
                          <div className="dashboard-area">
                            <div className="container-fluid">
                              <div className="col-12">
                                <div className="card">
                                  <div className="card-body tab-data-model">
                                    <div style={{ overflow: 'auto' }}>
                                      <CompanyInvesting />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TabComponent;