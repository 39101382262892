import React, { useState, useEffect, useContext } from 'react';
import { PageLoader } from '../Context/PageLoader';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ReactComponent as LOGO } from './WMS.svg';
import logo from './WMS.png';
import { postForm, withoutAuthGet } from '../api';
import { FORGET_PASSWORD } from '../api/endpoints';
import { concatUrlWithId } from '../utils/commonUtils';
function ForgetPassword() {
  const { token } = useParams();
  // const token = 'test1234'; 
  const [Invalidpass, setInvalidpass] = useState('');
  const [fromValue, setfromValue] = useState([]);
  const { loading, setLoading } = useContext(PageLoader);
  const history = useNavigate();
  const [tokenStatus, setTokenStatus] = useState(true);
  const [message, setMessage] = useState('');
  const [initialValues, setInitialValues] = useState({
    username: '',
    email: '',
    phone: '',
  });

  useEffect(() => {
    setLoading(false)

  }, [])
  const handelClick = () => {
    window.location.href = '/login';
  }

  return (<div className="main-content- h-100vh">

    {tokenStatus && <Formik
      initialValues={{ username: '' }}
      validate={(values) => {
        const errors = {};
        if (!values.username) {
          errors.username = 'Username is required!';
        }
        setfromValue(values)

        return errors;
      }}
      onSubmit={async (values) => {
        await new Promise((r) => setTimeout(r, 500));
        try {
          // setLoading(true)
          // Your form submission logic
          const response = await postForm(FORGET_PASSWORD, {
            username: values.username,
          });
          //  setLoading(false) 
          if (response.success == true) {
            // setTimeout(() => {
            //   window.location.href = '/login';
            // }, 20000);
            setTokenStatus(false)
          } else {
            setInvalidpass(response.response.data.error);
          }
        } catch (error) {
          setInvalidpass(error.response.data.error);
          console.error('API Error:', error);
        }
      }}
    >
      <div className="container h-100">
        <div className="row h-100 align-items-center justify-content-center">
          <div className="col-sm-10 col-md-7 col-lg-5">
            <div className="middle-box">
              <div className="card-body">
                <div className="log-header-area card p-4 mb-4 text-center">
                  {/* <LOGO /> */}
                  <img src={logo} className="App-logo" alt="logo" />
                </div>

                <div className="card">
                  <div className="card-body p-4">
                    <div className="mb-3">
                    <h3>Forgot Your Password</h3> 
                    </div>
                    <Form>

                      <div className="mb-3">
                        <label className="form-label" htmlFor="formrow-stock-code-input">Username</label>
                        <Field className="form-control" type="text" id="formrow-stock-code-input" name="username" />
                        <ErrorMessage className="text-danger" name="username" component="div" />
                      </div> 

                      {/** Display custom error message */}
                      <div className="form-group mb-3">
                        <div className="text-danger">
                          {Invalidpass}
                          <ErrorMessage className="text-danger" name="status" />
                        </div>
                      </div>

                      <div className="form-group mb-3">
                        <button className="btn btn-primary btn-lg w-100" type="submit">
                          Continue
                        </button>
                      </div>

                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Formik>}

    {!tokenStatus &&
      <div className="container h-100">
        <div className="row h-100 align-items-center justify-content-center">
          <div className="col-sm-10 col-md-7 col-lg-5">
            <div className="middle-box">
              <div className="log-header-area card p-4 mb-4 text-center">
                {/* <LOGO /> */}
                <img src={logo} className="App-logo" alt="logo" />
              </div>
              <div className="card">
                <div className="card-body p-4">
                  <div className="c476543f4 cfee98151">

                    <div className="c49c1ba2f c7425a8d7 c50747c71" data-event-id="">
                      <div className="c1415f71d c8c497cb1">
                        <span className="c39e239ae error-cross"></span>
                      </div>

                      <section className="c57eb80bc c15d84f93">
                        <h3>Email send successfully!</h3>
                        <p>Kindly check your inbox for further details and confirmation.</p>
                        <div>
                          <a href="#" onClick={handelClick}>Back to WMS</a>
                        </div>

                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
  </div>
  )
}

export default ForgetPassword;