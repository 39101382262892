import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts'
import { useLocation } from 'react-router-dom';

const DataChart = ({ header, data, labelKey, seriesKey, seriesName, onChange, type, handleDoubleClick, overviewPage = false }) => {
    const [tempSelectedChartValue, setTempSelectedChartValue] = useState("");
    const [clickEvent, setClickEvent] = useState("");
    const [chartType, setChartType] = useState('pie');
    const location = useLocation();

    const headerName = header || 'chart';

    const handleChange = (event) => {
        const selectedChartType = event.target.value;
        setChartType(selectedChartType); // Update chart view state
    };
    const [pieChart, setPieChart] = useState({
        series: [],
        options: {
            labels: [],
            chart: {
                type: 'pie',
                fontFamily: 'inter, sans-serif',
            },
            colors: [
                "#3e9efc", // Blue
                "#4ee5a6", // Green
                "#ff9f00", // Orange
                "#8e44ad", // Purple
                "#3498db", // Sky Blue
                "#f1c40f", // Yellow
                "#2ecc71", // Emerald
                "#7698b3", // Dark
                "#e67e22", // Carrot
                "#95a5a6", // Concrete
                "#16a085", // Green Sea
                "#9b59b6", // Amethyst
                "#f39c12"  // Orange
            ],
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '12px',
                    fontFamily: 'Inter, sans-serif',
                    colors: ['#fff']
                },
            },
            tooltip: {
                y: {
                    formatter: function (value) {
                        return new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            // You can adjust the minimumFractionDigits and maximumFractionDigits as needed
                        }).format(value);
                    }
                }
            },
            responsive: [
                {
                    breakpoint: 1900,
                    options: {
                        chart: {
                            width: 500,
                            height: 300,
                            events: {
                                dataPointSelection: (event, chartContext, config) => {
                                    let selectedData = config.w.config.labels[config.dataPointIndex];
                                    selectedData = selectedData.replace(/\s*\(.*?\)\s*/, '');
                                    setTempSelectedChartValue(selectedData, data);
                                    if (event?.detail == 2) {
                                        setClickEvent(selectedData);
                                    }
                                },
                            },
                        },
                        noData: {
                            text: 'No data available',
                            align: 'center',
                            verticalAlign: 'middle',
                            offsetX: 0,
                            offsetY: 0,
                            style: {
                                fontSize: "30px",
                                color: "#7f838b",
                                opacity: "0.7"
                            },
                        },
                        legend: {
                            position: "bottom",
                            horizontalAlign: 'center',
                            floating: false,
                            offsetY: 0,
                            fontSize: '12px'
                        },
                    },
                },
                {
                    breakpoint: 600,
                    options: {
                        chart: {
                            width: 200,
                            height: 400
                        },
                        noData: {
                            text: 'No data available',
                            align: 'center',
                            verticalAlign: 'middle',
                            offsetX: 0,
                            offsetY: 0,
                            style: {
                                fontSize: "30px",
                                color: "#7f838b",
                                opacity: "0.7"
                            },
                        },
                        legend: {
                            position: 'bottom',
                        },
                    },
                },
                {
                    breakpoint: 400,
                    options: {
                        chart: {
                            width: 330,
                            height: 250
                        },
                        noData: {
                            text: 'No data available',
                            align: 'center',
                            verticalAlign: 'middle',
                            offsetX: 0,
                            offsetY: 0,
                            style: {
                                fontSize: "20px",
                                color: "#7f838b",
                                opacity: "0.7"
                            },
                        },
                        legend: {
                            position: 'bottom',
                        },
                    },
                },
            ],
        },
    });

    const [barChart, setBarChart] = useState({
        series: [],
        options: {
            chart: {
                type: 'bar',
                width: '100%', // Change width to 100% for responsiveness
                height: 'auto',
                toolbar: {   
                    show: true,
                    tools: {
                        download: false,  // export the chart options disable
                    }
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                width: 1,
                colors: ["#fff"],
            },
            xaxis: {
                categories: [],
            },
            legend: {
                position: "bottom", // Change legend position for mobile
                markers: {
                    width: 10,
                    height: 10,
                },
            },
            tooltip: {
                y: {
                    formatter: function (value) {
                        return new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        }).format(value);
                    },
                },
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0,
                        }).format(value);
                    },
                },
            },
            noData: {
                text: 'No data available',
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: "30px",
                    color: "#7f838b",
                    opacity: "0.7",
                },
            },
            responsive: [
                {
                    breakpoint: 1000,
                    options: {
                        chart: {
                            height: 400,
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                            },
                        },
                        legend: {
                            position: "bottom",
                        },
                    },
                },
                {
                    breakpoint: 800,
                    options: {
                        chart: {
                            height: 300,
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                            },
                        },
                        legend: {
                            position: "bottom",
                        },
                    },
                },
                {
                    breakpoint: 600, // Add a breakpoint for mobile devices
                    options: {
                        chart: {
                            height: 350, // Adjust height for mobile view
                            width: 250,
                        },
                        legend: {
                            position: "bottom",
                            markers: {
                                width: 8, // Adjust marker size for mobile view
                                height: 8,
                            },
                        },
                    },
                },
                {
                    breakpoint: 400, // Add a breakpoint for mobile devices
                    options: {
                        chart: {
                            height: 350, // Adjust height for mobile view
                            width: 250,
                        },
                        legend: {
                            position: "bottom",
                            markers: {
                                width: 8, // Adjust marker size for mobile view
                                height: 8,
                            },
                        },
                    },
                },
            ],
        },
    });


    useEffect(() => {
        const transformedData = data.reduce((result, item) => {
            let assetType = labelKey == "operating_bussiness"? 'Operating Business' : item[labelKey];
            const currentValue = item[seriesKey[0]] ? parseFloat(item[seriesKey[0]]) : 0.00;
            const investedValue = item[seriesKey[1]] ? parseFloat(item[seriesKey[1]]): 0.00;
            // Check if the assetType already exists in the result
         
            if (assetType in result) {
                // If the assetType already exists, update the currentValue and investedValue
                result[assetType].currentValue += currentValue;
                result[assetType].investedValue += investedValue;
            } else {
                // If the assetType doesn't exist, add a new entry
                result[assetType] = { currentValue };
                result[assetType].investedValue = investedValue;
            }
            if(assetType == 'Operating Business' ){

                console.log('assetType ::: ', currentValue , '---',item[seriesKey[0]], seriesKey)
            }

            return result;
        }, {});

        const totalSum = Object.values(transformedData).reduce((acc, asset) => acc + asset.currentValue, 0);

        // Calculate percentages and create labeled data
        const labeledData = {};
        Object.keys(transformedData).forEach((key) => {
            if (chartType !== "bar") {
                const percentage = ((transformedData[key].currentValue / totalSum) * 100).toFixed(1);
                labeledData[`${key} (${percentage}%)`] = transformedData[key].currentValue;
            }
        });

        // Chart Data by key and values
        const investedAmt = Object.values(transformedData).map(item => parseFloat(item.investedValue).toFixed(2));
        const currentAmt = Object.values(transformedData).map(item => parseFloat(item.currentValue).toFixed(2));
        setBarChart((prevBarChart) => ({
            ...prevBarChart,
            series: [
                { name: seriesName[1], data: investedAmt },
                { name: seriesName[0], data: currentAmt }
            ],
            options: {
                ...prevBarChart.options,
                xaxis: {
                    categories: Object.keys(transformedData)
                }
            }
        }));

        setPieChart((prevStockOverView) => ({
            ...prevStockOverView,
            series: Object.values(labeledData),
            options: {
                ...prevStockOverView.options,
                labels: Object.keys(labeledData),
            },
        }));
    }, [data]);


    useEffect(() => {
        if (tempSelectedChartValue) {
            if (location.pathname != '/portfolio-summary') {
                onChange(tempSelectedChartValue)
            }
            if (clickEvent !== "" && location.pathname === '/portfolio-summary') {
                handleDoubleClick(clickEvent)
            }
            setTempSelectedChartValue("")
        }
    }, [tempSelectedChartValue]);
    return (
        <div className="row ">
            <div className="col-sm-12 col-lg-6 mb-20 mb-2 ">
                <div className="card">
                    <div className="card-body">
                        {/* <div className="card-title" style={{ marginBottom: '5px' }}>
                            <h4>{header || "Chart Overview"}</h4>
                        </div> */}
                        <div className=" single-widget d-flex align-items-center justify-content-between" style={{ marginBottom: '5px' }}>
                            <div className="card-title">
                                {/* <h4>{header || "Chart Overview"}</h4> */}
                                <h4>{`${headerName} Allocation`}</h4>
                            </div>
                            {/* <div className="card-title">
                        <div className="custom-dropdown">
                            <select className="btn btn-outline-primary " id="chartViewSelector" onChange={handleChange} value={chartType}>
                                <option value="pie">Pie Chart</option>
                                <option value="bar">Column Chart</option>
                            </select>
                        </div>

                        
                        </div> */}
                        </div>
                        <div id="stock-overview" style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>

                            {data?.length ?
                                <Chart
                                    options={pieChart.options}
                                    series={pieChart.series}
                                    type={'pie'} height={300} width={500} /> :
                                <div className="datachart-nodata">No data available</div>
                            }
                            {/* <Chart 
                            options={ barChart.options} 
                            series={barChart.series} 
                            type={chartType} height={350} width={500}/> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 mb-20">
                <div className="card">
                    <div className="card-body">
                        {/* <div className="card-title" style={{ marginBottom: '5px' }}>
                            <h4>{header || "Chart Overview"}</h4>
                        </div> */}
                        <div className=" single-widget d-flex align-items-center justify-content-between" style={{ marginBottom: '5px' }}>
                            <div className="card-title">
                                {/* <h4>{header || "Chart Overview"}</h4> */}
                                <h4>{`${headerName} Summary Performance`}</h4>
                            </div>
                            {/* <div className="card-title">
                        <div className="custom-dropdown">
                            <select className="btn btn-outline-primary " id="chartViewSelector" onChange={handleChange} value={chartType}>
                                <option value="pie">Pie Chart</option>
                                <option value="bar">Column Chart</option>
                            </select>
                        </div>

                        
                        </div> */}
                        </div>
                        <div id="stock-overview" style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            {data?.length ? <Chart
                                id="bar-chart"
                                options={barChart.options}
                                series={barChart.series}
                                type={"bar"} height={250} width={500} /> :
                                <div className="datachart-nodata">No data available</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DataChart; 