import React, { useState, useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';

export const EmailInput = ({ field, form }) => {
    const [emails, setEmails] = useState(field.value||[]);
    const [value, setValue] = useState('');
    const [error, setError] = useState(null);
    const inputRef = useRef(null);
    const { isSubmitting, submitCount } = useFormikContext(); 
    const emailValidRegex = /^[^\s@.]+(\.[^\s@.]+)*@[^\s@.]+(\.[^\s@.]+)+$/;
    useEffect(() => {
      if (isSubmitting) {
        // Do something when form is submitting
        simulateEnterKeyPress();
      }
    }, [isSubmitting, submitCount]);
  
    useEffect(() => {
      setEmails(field.value)
    }, [field.value]);
  
    const handleKeyDown = (evt) => {
      if (["Enter", "Tab", ","].includes(evt.key)) {
        evt.preventDefault();
        let newValue = value.trim();
        if (newValue && isValid(newValue)) {
          const newEmails = [...emails, newValue];
          setEmails(newEmails);
          setValue('');
          form.setFieldValue(field.name, newEmails);
        }
      }
    };
  
    const simulateEnterKeyPress = () => {
      const event = new KeyboardEvent('keydown', { key: 'Enter', code: 'Enter', keyCode: 13, which: 13, bubbles: true });
      inputRef.current.dispatchEvent(event);
    };
  
    const handleChange = (evt) => {
      setValue(evt.target.value);
      setError(null);
    };
  
    const handleDelete = (index) => {
      const newEmails = emails.filter((email, i) => i !== index);
      setEmails(newEmails);
      form.setFieldValue(field.name, newEmails);
    };
  
    const handlePaste = (evt) => {
      evt.preventDefault();
      let paste = evt.clipboardData.getData("text");
      // let pastedEmails = paste.match(emailValidRegex);
      // const emailValidRegexV2 = /[^\s@.]+(\.[^\s@.]+)*@[^\s@.]+\.[^\s@.]+/g;
      const emailValidRegexV2 = /^[^\s@.]+(\.[^\s@.]+)*@[^\s@.]+(\.[^\s@.]+)+$/g;
      let pastedEmails = paste.match(emailValidRegexV2);
  
      if (pastedEmails) {
        let toBeAdded = pastedEmails.filter(email => !isInList(email));
        const newEmails = [...emails, ...toBeAdded];
        setEmails(newEmails);
        form.setFieldValue(field.name, newEmails);
      }
    };
  
    const isValid = (email) => {
      let error = null;
  
      if (isInList(email)) {
        error = `${email} has already been added.`;
      }
  
      if (!isEmail(email)) {
        error = `${email} is not a valid email address.`;
      }
  
      if (error) {
        setError(error);
        return false;
      }
  
      return true;
    };
  
    const isInList = (email) => {
      return emails.includes(email);
    };
  
    const isEmail = (email) => {
      return  emailValidRegex.test(email);
    };
  

    return (
      <div className="email-wrapper">
        <div className={`email-input ${false&&error ? 'email-has-error' : ''}`} onClick={() => inputRef.current.focus()}>
          {emails ? emails.map((email, index) => (
            <span key={index} className="email-tag-item">
              {email}
              <button
                type="button"
                className="email-button"
                onClick={() => handleDelete(index)}
              >
                &times;
              </button>
            </span>
          )) : ''}
          <input
            ref={inputRef}
            value={value}
            placeholder="Type or paste email addresses and press `Enter`..."
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            onPaste={handlePaste}
            className="input-email"
          />
        </div>
        {error && <p className="email-error">{error}</p>}
      </div>
    );
  };