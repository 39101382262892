import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const axiosConfig = {
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
};

const fileUploadAxiosConfig = {
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'multipart/form-data',
    },
};

const axiosInstance = axios.create(axiosConfig);
const withoutAuthAxiosInstance = axios.create(axiosConfig);

/**file upload */
const fileUploadAxiosInstance = axios.create(fileUploadAxiosConfig);


const handleError = (error) => {
    if (error?.response?.statusText == 'Unauthorized') {
        localStorage.removeItem('token');
        localStorage.removeItem('authToken'); 
        localStorage.removeItem('user-permission'); 
        window.location = '/login'
    } else {
        throw error;
    }
}

// Request interceptor to add Authorization header
axiosInstance.interceptors.request.use(
    (config) => {
        const authTokenString = localStorage.getItem('authToken');
        const authToken = JSON.parse(authTokenString);
        if (authToken) {
            config.headers.Authorization = `${authToken.token}`;
            config.headers['x-refresh-token'] = `${authToken.refreshToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor for common error handling
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Handle common error scenarios
        if (error.response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx
            console.error('API Error:', error.response.data);
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received:', error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error:', error.message);
        }
        return Promise.reject(error);
    }
);

// Request interceptor to add Authorization header
fileUploadAxiosInstance.interceptors.request.use(
    (config) => {
        const authTokenString = localStorage.getItem('authToken');
        const authToken = JSON.parse(authTokenString);
        if (authToken) {
            config.headers.Authorization = `${authToken.token}`;
            config.headers['x-refresh-token'] = `${authToken.refreshToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor for common error handling
fileUploadAxiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Handle common error scenarios
        if (error.response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx
            console.error('API Error:', error.response.data);
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received:', error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error:', error.message);
        }
        return Promise.reject(error);
    }
);

// Authenticated API calls
// GET request
export const get = async (url, params = {}) => {
    try {
        const response = await axiosInstance.get(url, { params });
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

// POST request
export const post = async (url, data, config = {}) => {
    try {
        const response = await axiosInstance.post(url, data, config);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

// PUT request
export const put = async (url, data, config = {}) => {
    try {
        const response = await axiosInstance.put(url, data, config);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

// DELETE request
export const remove = async (url) => {
    try {
        const response = await axiosInstance.delete(url);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

// Auth Verify request
export const authVerify = async (url, data) => {
    try {
        const response = await withoutAuthAxiosInstance.post(url, data);
        return response;
    } catch (error) {
        var isInvalidCredentials = error.response.data.message;
        if(isInvalidCredentials =="Invalid credentials"){
        return error;
        }else{
        handleError(error);
        }
    }
};
//test

export const postForm = async (url, data) => {
    try {
        const response = await fileUploadAxiosInstance.post(url, data);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

export const putForm = async (url, data) => {
    try {
        const response = await fileUploadAxiosInstance.put(url, data);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

export const withoutAuthGet = async (url, params) => { 
    try {
        const config = params && typeof params === 'object' ? { params } : {};
        const response = await withoutAuthAxiosInstance.get(url, config);
        return response;
    } catch (error) {
        var isInvalidCredentials = error.response.data.message;
        if (isInvalidCredentials === "Invalid credentials") {
            return error;
        } else {
            handleError(error);
        }
    }
};

export default axiosInstance;