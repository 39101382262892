import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect, useContext } from 'react';
import * as Yup from 'yup';
import { get, post, put } from '../../api'
import { GET_REMINDER_BY_INV_ID, CREATE_REMINDER, UPDATE_REMINDER } from '../../api/endpoints'
import { DatePickerField, EmailField, FormField, SelectField } from '../Forms/FormFields';
import { Formik, Form } from 'formik';
import { concatUrlWithId, checkPermission } from '../../utils/commonUtils';
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify';
import { reminderOptionType, weeklyOptions, dateOptions, timeOptions } from '../../Data/ReminderOptions';
import moment from 'moment';
import { PageLoader } from '../../Context/PageLoader';

export const investmentSchema = Yup.object().shape({
    reminder_name: Yup.string().label('Reminder Name').trim().required('Reminder Name is required'),
    occurrence_type: Yup.string().label('Occurrence').trim().required(' Occurrence is required'),
    occurrence_on: Yup.string().when('occurrence_type', {
        is: "daily", // If document_type is 'other'
        then: (scheme) => scheme.notRequired(),
        otherwise: (scheme) => scheme.required('Day is required')
    }),
    occurrence_time: Yup.string().label('Time').trim().required(' Time is required'),
    email: Yup.array()
        .of(
            Yup.string()
                .email('Invalid email address')
        )
        .min(1, 'Email is required')
    .required('Required')
});

function ReminderModelForm({ recordId, reminder_id, show, handleClose, mode, fieldValue = {},onSuccess, headerName, entityType, invesmentCat }) {

    const [initialValues, setInitialValues] = useState({
        reminder_name: '',
        occurrence_type: '',
        occurrence_type_label: '',
        occurrence_on: '',
        occurrence_on_label: '',
        occurrence_time: '',
        email: []
    });

    const [editMode, setEditMode] = useState(false);
    const [selectedID, setSelectedID] = useState('');
    const [isFieldClear, setIsFieldClear] = useState(false);
    const [isSaveNew, setIsSaveNew] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [enableSelect, setEnableSelect] = useState(false);
    const [enableDatePicker, setEnableDatePicker] = useState(false);
    const [enableOneTimePicker, setEnableOneTimePicker] = useState(false);
    const [optionArr, setOptionArr] = useState([]);
    const { loading, setLoading } = useContext(PageLoader);

    const notify = (message, type) => toast(message, { type });

    const handleReminderChange = (value) => {
        // False and Empty all values before change the reminder type
        setEnableSelect(false);
        setEnableDatePicker(false);
        setEnableOneTimePicker(false);
        setOptionArr([]);

        switch (value) {
            case 'daily':
                break;
            case 'weekly':
                setEnableSelect(true);
                setOptionArr(weeklyOptions)
                break;
            case 'monthly':
            case 'quarterly':
                setEnableSelect(true);
                setOptionArr(dateOptions);
                // console.log('Coming inside the mothly and quarterly');
                break;
            case 'yearly':
                setEnableDatePicker(true);
                break;
            case 'onetime':
                setEnableOneTimePicker(true);
                break;
            default:
                // console.log('Wrong type');
                break;
        }
    };

    const handleDateFieldChange = (value) => {
        console.log("Value date change :: ", value)
    }

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            // const getReminderData = await get(concatUrlWithId(GET_REMINDER_BY_INV_ID, recordId));
            if (fieldValue?.id) {
                const { id, reminder_name, email, occurrence_on, occurrence_time, occurrence_type } = fieldValue;
                handleReminderChange(occurrence_type)
                setInitialValues({
                    reminder_name,
                    occurrence_type,
                    occurrence_type_label: '',
                    occurrence_on: occurrence_type == "onetime" ? moment(occurrence_on).format("YYYY-MM-DD") :occurrence_on ,
                    occurrence_on_label: '',
                    occurrence_time,
                    email: email || []
                });
                setSelectedID(id);
                setEditMode(true);
            } else {
                setInitialValues({
                    reminder_name: '',
                    occurrence_type: '',
                    occurrence_type_label: '',
                    occurrence_on: '',
                    occurrence_on_label: '',
                    occurrence_time: '',
                    email: []
                });
                handleReminderChange('')
                setEditMode(false);
                setSelectedID('')
            }
        };

        if (show && mode == "edit") {
            fetchData();
        }else {
            setInitialValues({
                reminder_name: '',
                occurrence_type: '',
                occurrence_type_label: '',
                occurrence_on: '',
                occurrence_on_label: '',
                occurrence_time: '',
                email: []
            });
            handleReminderChange('')
            setEditMode(false);
            setSelectedID('')
        }
        setLoading(false);

    }, [show]);


    return (
        <>
            <Modal show={show} onHide={() => handleClose(false)} size="md" centered style={{background:'rgba(240, 240, 240, 0.6)'}}>
                <div style={{border: "1px solid #c5c9ce", boxShadow:"0 4px 8px rgba(0, 0, 0, 0.2)"}}>
                    <Modal.Header closeButton>
                        <Modal.Title>{editMode ? `Update ${headerName}` : `Add ${headerName}`}</Modal.Title>
                    </Modal.Header>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={investmentSchema}
                        enableReinitialize
                        onSubmit={async (values, actions) => {
                            setLoading(true);
                            setIsSubmitting(true);
                            try {
                                let response = [];
                                setEnableSelect(false);
                                setEnableDatePicker(false);
                                handleClose(true);

                                const url = editMode ? concatUrlWithId(UPDATE_REMINDER, selectedID) : CREATE_REMINDER;

                                values['record_id'] = recordId;
                                values['entity_type'] = entityType;
                                values['investment_cat_id'] = invesmentCat || null;
                                const bodyPayload = values;

                                // const data_userId = editMode ? initialValues?.id : editId;
                                // const url = editMode ? concatUrlWithId(UPDATE_USER, data_userId) : CREATE_USER;
                                // const bodyPayload = { };

                                // response = editMode ? await put(url, bodyPayload) : await post(url, bodyPayload);
                                response = editMode ? await put(url, bodyPayload) : await post(url, bodyPayload);

                                if (response) {

                                    notify(`${values.reminder_name} ${editMode ? "Updated" : "Added"} Successfully...`, 'success')

                                    // if (isSaveNew == true) {
                                    //     actions.resetForm();
                                    //     setIsFieldClear(true);
                                    // } else {
                                    // }

                                } else {
                                    editMode ? notify(`Reminder updation failed...`, 'error') : notify(`Creat reminder failed...`, 'error');
                                }
                                onSuccess(values);
                                handleClose()
                                setLoading(false);
                            } catch (error) {
                                notify(error?.response?.data?.error, 'error');
                                setLoading(false);
                                console.error('API Error:', error);
                            }
                            setIsSubmitting(false);
                        }}
                    >
                        {props => (
                            <>
                                <Modal.Body  >
                                    <div>
                                        <Form>
                                            <div className='row'>
                                                <div className={'col-lg-12  col-md-12  col-sm-12 col-12'}>
                                                    <FormField viewMode={false} fieldName="reminder_name" label="Reminder Name" value={initialValues.reminder_name} maxLength={100} />
                                                </div>
                                                <div className='row'>
                                                    <div className={
                                                        (enableSelect || enableDatePicker || enableOneTimePicker) ?
                                                            'col-lg-4  col-md-4  col-sm-4 col-4' :
                                                            'col-lg-8  col-md-8  col-sm-8 col-8'
                                                    }>
                                                        <SelectField fieldName="occurrence_type" label="Occurrence" value={initialValues.occurrence_type} options={reminderOptionType} values={props.values}
                                                            setFieldValue={(field, val, label) => {
                                                                props.setFieldValue('occurrence_type', val);
                                                                props.setFieldValue('occurrence_type_label', label);
                                                                setInitialValues({ ...props.values,occurrence_type: val, occurrence_on: '' })
                                                                props.setFieldValue('occurrence_on', '');
                                                                handleReminderChange(val);
                                                            }} />
                                                    </div>
                                                    {(enableSelect || enableDatePicker || enableOneTimePicker) ? <div className={
                                                        'col-lg-4  col-md-4  col-sm-4 col-4'
                                                    }>
                                                        {enableSelect ? <SelectField fieldName="occurrence_on" label="Day" value={initialValues.occurrence_on} options={optionArr} values={props.values}
                                                            setFieldValue={(field, val, label) => {
                                                                props.setFieldValue('occurrence_on', val);
                                                                props.setFieldValue('occurrence_on_label', label);
                                                                // handleReminderChange(val);
                                                            }} /> : ""}
                                                        {enableDatePicker ? <DatePickerField fieldName="occurrence_on" label="Day" value={initialValues.occurrence_on || 'MM-DD'} setFieldValue={props.setFieldValue} isFieldClear={isFieldClear}
                                                            isDateMonth={true} handleDateFieldChange={(val) => {
                                                                props.setFieldValue('occurrence_on', val);
                                                                props.setFieldValue('occurrence_on_label', moment(val).format('MMM-DD'));
                                                            }} isRequired={true} /> : ''}
                                                        {enableOneTimePicker ? <DatePickerField fieldName="occurrence_on" label="Day" value={initialValues.occurrence_on} setFieldValue={props.setFieldValue} isFieldClear={isFieldClear}
                                                            isDateMonth={false} handleDateFieldChange={(val) => {
                                                                props.setFieldValue('occurrence_on', moment(val).format('YYYY-MM-DD'));
                                                                props.setFieldValue('occurrence_on_label', moment(val).format('YYYY-MM-DD'));
                                                            }} isRequired={true} disablePastDate={true} /> : ''}
                                                    </div> : ''}

                                                    <div className={
                                                        'col-lg-4  col-md-4  col-sm-4 col-4'
                                                    }>
                                                        <SelectField fieldName="occurrence_time" label="Time" value={initialValues.occurrence_time} options={timeOptions} values={props.values}
                                                            setFieldValue={(field, val, label) => {
                                                                props.setFieldValue('occurrence_time', val);
                                                                props.setFieldValue('occurrence_time_label', label);
                                                                // handleReminderChange(val);
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className={'col-lg-12  col-md-12  col-sm-12 col-12'}>
                                                </div>
                                                <div className={'col-lg-12  col-md-12  col-sm-12 col-12'}>
                                                    <EmailField viewMode={false} fieldName="email" label="Email" value={initialValues.email} />
                                                    {/* <Field name="emails" component={EmailInput} /> */}
                                                    {/* <EmailInput  /> */}
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer style={{ justifyContent: 'center' }}>
                                    <Button type="reset" variant="secondary" onClick={() => handleClose(false)}>
                                        Cancel
                                    </Button>
                                    <Button type="submit" variant="primary" disabled={isSubmitting} onClick={() => { props.submitForm(); setIsSaveNew(false); }} className="btn btn-primary w-md">
                                        {editMode ? 'Update' : 'Save'}
                                    </Button>
                                    {/* {modelType === 'document' && <Button type="submit" onClick={() => { props.submitForm(); setIsSaveNew(true); }} className="btn btn-primary w-md">{'Save and New'}</Button>} */}
                                </Modal.Footer>
                            </>
                        )}
                    </Formik>
                </div >
            </Modal>
        </>)
}
export default ReminderModelForm;