import React, { useState, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import SideBar from "../components/Sidebar";
import Header from "../components/Header";

function AdminLayout({children}) {
    const [upadteLogo, setupadteLogo] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [hovered, setHovered] = useState(false);

    // useEffect to handle hover effects
    useEffect(() => {
        const handleHover = () => {
            setHovered(true);
            setupadteLogo(false)
        };

        const handleHoverOut = () => {
            setHovered(false);
            setupadteLogo(true)
        };

        const pageWrapper = document.querySelector('.flapt-sidemenu-wrapper');

        if (pageWrapper) {
            pageWrapper.addEventListener('mouseenter', handleHover);
            pageWrapper.addEventListener('mouseleave', handleHoverOut);

            return () => {
                pageWrapper.removeEventListener('mouseenter', handleHover);
                pageWrapper.removeEventListener('mouseleave', handleHoverOut);
            };
        }
    }, []);

    // Load collapsed state from localStorage on component mount
    useEffect(() => {
        const collapsedState = localStorage.getItem('sidebarCollapsed');
        if (collapsedState !== null) {
            setCollapsed(collapsedState === 'true');
            setupadteLogo(true)
        }
    }, []);

    // Update localStorage when collapsed state changes
    useEffect(() => {
        localStorage.setItem('sidebarCollapsed', collapsed);
    }, [collapsed]);

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };
    return (
        <div className={`flapt-page-wrapper ${collapsed ? 'menu-collasped-active' : ''} ${hovered ? 'sidemenu-hover-active' : 'sidemenu-hover-deactive'}`}>
            <SideBar updateImageFunction={ collapsed && upadteLogo} />
            <div className="flapt-page-content">
                <Header toggleCollapse={toggleCollapse} menuCollapsed={collapsed}/>
                {/* <Outlet /> */}
                <main>{children}</main>
            </div>
        </div>
    );
}

export default AdminLayout;
