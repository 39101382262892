
import { useState, useContext } from "react";

function Loader()
{
    return( <div id="preloader">
        <div className="preloader-book">
            <div className="inner">
                <div className="left"></div>
                <div className="middle"></div>
                <div className="right"></div>
            </div>
            <ul>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div>
    </div>)
}

export default Loader;