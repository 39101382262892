
import { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import Footer from "../../../components/Footer";
import { PageLoader } from '../../../Context/PageLoader';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Datatable from '../../../components/Table/Datatable';
import DataChart from '../../../components/DataChart';
import { get, put, remove } from '../../../api';
import { GET_REAL_ESTATE_INVESTMENTS, DELETE_INVESTMENT_BY_ID, ARCHIVE_INVESTMENT_BY_ID } from '../../../api/endpoints'
import { checkPermission, concatUrlWithId, refreshCount } from '../../../utils/commonUtils';
import { UserPermissionStore } from '../../../Store/UserPermission';
import ReminderTab from '../../../components/Reminder/ReminderTab';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PasswordConfirmation from '../../../components/Forms/PasswordConfirmation';
import SetPassword from '../../SetPassword';

function RealEstateList() {
  const [investmentList, setInvestmentList] = useState([]);
  const [selectedInvList, setSelectedInvList] = useState([]);
  const [selectedChartValue, setSelectedChartValue] = useState("");
  const { loading, setLoading } = useContext(PageLoader);
  const [userPermissions, setUserPermissions] = useState("");
  const [showReminderModel, setShowReminderModel] = useState(false);
  const [selectedInvID, SetSelectedInvID] = useState('')
  const history = useNavigate();
  const notify = (message, type) => toast(message, { type });
  const { checkAndUpdatePermissions } = UserPermissionStore();
  const [showAction, setShowAction] = useState(false);
  const [enablePrint, SetEnablePrint] = useState(false)
  const [reminderActionPermission, setReminderActionPermission] = useState(false);
  const category_id = process.env.REACT_APP_INVESTMENT_REAL_ESTATE_MASTER_ID;

  // Reminder Task 
  const [showReminderTask, setShowReminderTask] = useState(false);
  const [getInvestmentID, setInvestmentID] = useState('');
  const [chartData, setChartData] = useState([]);
  const [showPasswordModel, setShowPasswordModel] = useState(false);
  const [deleteItem, setDeleteItem] = useState({ id: '', name: '' })

  const reminderTaskPopUp = (investment_id, actionPermission) => {
    setInvestmentID(investment_id);
    setReminderActionPermission(actionPermission)
    setShowReminderTask(true);
  };

  const [headerValues, setHeaderValues] = useState({
    totalInvestments: 0,
    totalCurrentValues: 0,
    totalDebt: 0,
  });

  const handleClose = () => setShowReminderTask(false);

  const handelListUpdate = () => fetchInvestmentList();

  useEffect(() => {
    setLoading(true)
    authCheckAndFetch();
  }, [])

  const fetchInvestmentList = async () => {
    try {
      const getInvestments = await get(GET_REAL_ESTATE_INVESTMENTS);
      const investments = getInvestments.investments;
      const totalOfInvestments = getInvestments.totals;
      if (investments) {
        let isAction = investments.some((inv) => inv.permission_keys?.length > 1);
        setShowAction(isAction);

        // Filter out the investments where current_value is not null
        const arrayData = investments.filter(value => value.cal_current_value !== null);

        // Update the equity_debt_invested field to 0 if it is null
        const updatedInvestments = arrayData.map(investment => {
          if (investment.equity_debt_invested === null) {
            return {
              ...investment,
              equity_debt_invested: 0
            };
          }
          return investment;
        });

        /** Header total calculation */
        let total_current_value = 0;
        let total_invested_value = 0;

        const dataFilter = updatedInvestments.map((value) => {

          let currentValue = parseFloat(value['cal_current_value']) || 0;
          let investedValue = parseFloat(value['equity_debt_invested']) || 0;
          total_current_value += currentValue;
          total_invested_value += investedValue;
          return value;
        });
        total_current_value = total_current_value.toFixed(2);

        // Set the updated investments array to investmentsList 
        setInvestmentList(updatedInvestments);
        setChartData(getInvestments?.chartData || [])
        setSelectedInvList(investments)
        setHeaderValues({
          totalInvestments: totalOfInvestments.total_invested,
          totalCurrentValues: totalOfInvestments.total_current_value,
          totalDebt: totalOfInvestments.total_inv_aft_Debt,
        })
      }
      refreshCount();
    } catch (error) {
      console.log('Error while throughing:: ', error);
    } finally {
      setLoading(false);
    }
  };

  const authCheckAndFetch = async () => {
    let tempPermission = await checkAndUpdatePermissions();
    setUserPermissions(tempPermission || []);
    if (checkPermission(tempPermission, 'view-real-estate-invt') || checkPermission(tempPermission, 'isAdmin') || checkPermission(tempPermission, 'isManager')) {
      fetchInvestmentList()
    } else {
      setLoading(false);
    }
  }

  const handleChartChange = (selectedValue) => {
    if (selectedChartValue == selectedValue) {
      setSelectedInvList(investmentList)
      setSelectedChartValue("")
    } else {
      let filterInvesmentList = investmentList.filter((item) => (selectedValue == item['property_type']));
      setSelectedChartValue(selectedValue)
      setSelectedInvList(filterInvesmentList)
    }
  }

  const handleDelete = async () => {
    const {id, name} = deleteItem;
    try {
      setLoading(true)
      let deleteInvestment = await remove(concatUrlWithId(DELETE_INVESTMENT_BY_ID, id));
      
      if (deleteInvestment) {
        setShowPasswordModel(false)
        fetchInvestmentList()
      }
      setShowPasswordModel(false)
      notify(`${name} successfully deleted`, 'success')
    } catch (error) {
      setShowPasswordModel(false)
      notify(`Unable to delete ${name}`, 'warning')
      console.log('Error while deleting the error : ', error)
    }

  };

  const handleEditClick = (investmentId) => {
    setLoading(true);
    history(`/edit-private-equity/${investmentId}`);
  };
  const handleViewClick = (investmentId) => {
    setLoading(true);
    history(`/view-private-equity/${investmentId}`);
  };

  const AddRealEstate = () => {
    setLoading(true);
    history('/add-private-equity', { state: { previousPage: "Private Equity List", previousUrl: "/private-equity-list" } });
  };

  const revertDelete = () => {
    setDeleteItem({ id: '', name: ''})    
  }
  const deleteRealEstate = (investment_id, investment_name) => {
    confirmAlert({
      title: 'Delete',
      message: `Are you sure you want to delete the ${investment_name}?`,
      buttons: [
        {
          label: 'Yes',
          // onClick: () => handleDelete(investment_id, investment_name)
          onClick: () => {
            setDeleteItem({id: investment_id, name: investment_name}); 
            setShowPasswordModel(true)
          }
        },
        {
          label: 'No',
          onClick: revertDelete()
        }
      ]
    });
  }

  const archivedRealEstate = (investment_id, investment_name) => {
    confirmAlert({
      title: 'Archive',
      message: `Are you sure want to Archive ${investment_name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleArchived(investment_id, investment_name)
        },
        {
          label: 'No',
        }
      ]
    });
  }


  const handleArchived = async (investment_id, investment_name) => {
    try {
      setLoading(true)
      const archive = await put(concatUrlWithId(ARCHIVE_INVESTMENT_BY_ID, investment_id), { is_archived: true });
      if (archive) {
        fetchInvestmentList()
      }
      notify(`${investment_name} has been archived.`, 'warning',)
    } catch (error) {
      console.log('Error while deleteing the error : ', error)
      notify(`Unable to archive ${investment_name}.`, 'warning',)
    }

  };

  const handleOnClickAction = (type, id, name, actionPermission) => {
    switch (type) {
      case 'view':
        handleViewClick(id);
        break;
      case 'edit':
        handleEditClick(id);
        break;
      case 'archive':
        archivedRealEstate(id, name);
        break;
      case 'delete':
        deleteRealEstate(id, name);
        break;
      case 'reminderTask':
        reminderTaskPopUp(id, actionPermission);
        break;
      case 'setreminder':
        handleReminderClick(id, name);
        break;
      default:
        break;
    }
  };

  const handleTableChange = (type, value) => {
    try {
      switch (type) {
        case 'print':
          SetEnablePrint(value);
          break;
        default:
          break;
      }
    } catch (error) {
      console.log('Error in the handle table change funciton:: ', error)
    }
  }

  const handleReminderClick = (id, name) => {
    SetSelectedInvID(id)
    setShowReminderModel(!showReminderModel)
  };
  const handleReminderModelClose = () => {
    setShowReminderModel(false);
  };

  // Custom sort function for numeric values
  const numericSort = (a, b, order) => {
    if (order === 'asc') {
      return a - b; // Ascending order
    }
    return b - a; // Descending order
  };

  const columns = [
    { dataField: 'id', text: 'ID', csvExport: false, hidden: true },
    {
      dataField: 'investment_name', text: 'Legal Entity Name', sort: true, isViewField: true, handleOnClick: handleOnClickAction, onClickParams: ['id'],
      wordWarp: true, hMinWidth: '160px', hMaxWidth: "160px", action: true, order: 'asc'
    },
    { dataField: 'property_type', text: 'Investment Category', sort: true, hMinWidth: '180px', hMaxWidth: "135px", },
    { dataField: 'investment_date', text: 'Investment Date', sort: true, isDateField: true, hMinWidth: '135px', hMaxWidth: "140px", },
    { dataField: 'ownership_percentage', text: 'Ownership Percentage(%)', sort: true, isAmountField: true, hMinWidth: '205px', hMaxWidth: "210px", sortFunc: numericSort },
    // { dataField: 'total_purchase_price', text: 'Total Value($)', sort: true, isAmountField: true, hMinWidth: '120px', hMaxWidth: "125px", },
    { dataField: 'equity_debt_invested', text: 'Total Invested($)', sort: true, isAmountField: true, hMinWidth: '160px', hMaxWidth: "165px", sortFunc: numericSort },
    { dataField: 'cal_current_value', text: 'Current Value ($)', sort: true, hMinWidth: '140px', hMaxWidth: "160px", isAmountField: true, sortFunc: numericSort },
    { dataField: 'property_address', text: 'Property Address', sort: true, wordWarpDot: true, hMinWidth: '140px', hMaxWidth: "160px", },

    // { dataField: 'performance', text: 'Performance', sort: false, isPerformanceField: true },
    { dataField: 'projected_annual_income', text: 'Cash Flow($)', sort: true, hMinWidth: '120px', isAmountField: true, sortFunc: numericSort },
  ];
  let tempAction = { dataField: 'Action', text: 'Action', csvExport: false, standardAction: true, hMinWidth: '150px', hMaxWidth: "130px", tooltipSuffixText: " ", handleOnClick: handleOnClickAction, onClickParams: ['id', 'investment_name'], reminderCount: ['active_reminders_count'], setReminder: true }
  if (checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) {
    columns.push(tempAction);
  } else {
    tempAction = { ...tempAction, disableDelete: true, disableEdit: true, disableArchive: true }
    columns.push(tempAction);
  }

  const headerTotalValues = [
    { label: 'Total Investments ($)', value: headerValues.totalInvestments },
    { label: 'Total Current Value ($)', value: headerValues.totalCurrentValues },
    { label: 'Total Inv.Val after Debt ($)', value: headerValues.totalDebt },
  ];

  const handleBackClick = () => {
    history('/portfolio-summary');
  };

  const updateReminderData = async () => {
    await fetchInvestmentList();
  }

  const handlePrintClick = () => {
    confirmAlert({
      title: 'Alert',
      message: `For better viewing, please use landscape mode.`,
      buttons: [
        {
          label: 'Done',
          onClick: () => { SetEnablePrint(true); setLoading(true); }
        },
      ]
    });
  }

  return (
    <div className="main-content introduction-farm">
      <div className="col-12" style={{ padding: '5px 15px 15px 15px' }}>
        <div className="card">
          <button id="update-remainder-data" onClick={() => updateReminderData()} style={{ display: 'none' }}> Click me </button>

          <div className="card-body card-breadcrumb">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <a href="#" onClick={() => handleBackClick()} className="btn btn-light mb-2 me-2" style={{ display: 'flex', alignItems: 'center' }}><i className="arrow_left"></i>
                Back</a>
              {/* <div className="page-title-right title-prefix" >Private Equity</div> */}
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item"><a href="#" onClick={() => handleBackClick()}>Portfolio Summary</a></li>
                  <li className="breadcrumb-item active" >Private Equity</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-wraper-area">
        <div className="dashboard-area">
          <div className="container-fluid">
            <DataChart
              header={"Private Equity"} data={chartData} labelKey={'asset_type'}
              seriesKey={["total_inv_aft_Debt", "total_invested",]} onChange={handleChartChange}
              seriesName={["Current Value", "Total Invested"]} type="bar"
            />
            <br />

            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="card-title" style={{ marginBottom: '5px' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <h4 style={{ flex: 'auto' }}>Private Equity List <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Print</Tooltip>} >
                        <i className="fa fa-print btn btn-circle print-btn" onClick={() => { handlePrintClick() }} ></i>
                      </OverlayTrigger>
                      </h4>
                      {
                        (checkPermission(userPermissions, 'create-real-estate-invt') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) &&
                        <button type="button" onClick={AddRealEstate} className="btn btn-primary mb-2 me-2"><i className="icon_plus"></i> Add New</button>
                      }
                    </div>
                  </div>
                  <div >
                    <Datatable data={selectedInvList} columns={columns} width={'1200px'} csvFileName={'RealEstateList'} actionPermissionPrefix={'real-estate-invt'} isAdminOrManager={checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')} FreezeColumn={true} headerName={'Private Equity List'} enablePrint={enablePrint} handleTableChange={handleTableChange} headerObjValue={headerTotalValues} showHeaderTotal={true} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PasswordConfirmation showViewModal={showPasswordModel} handleDelete={handleDelete} handleCloseViewModal={() => { setShowPasswordModel(false); revertDelete() }} />
      {/* <ReminderTaskModel show={showReminderTask} investmentID={getInvestmentID} handleClose={handleClose} handelListUpdate={handelListUpdate} />
      <ReminderList show={showReminderModel} recordId={selectedInvID} handleClose={handleReminderModelClose} entityType={'investment'} headerName={'Reminder'} invesmentCat={category_id} handelListUpdate={handelListUpdate}/> */}
      <ReminderTab show={showReminderTask} recordId={getInvestmentID} handleClose={() => setShowReminderTask(false)} hangleTabChange={handelListUpdate} invesmentCatId={category_id} entityType={'investment'} isAdminOrManager={checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')} actionPermission={reminderActionPermission} />
      {(userPermissions?.length && checkPermission(userPermissions, 'isManager')) && <Footer PropUserPermissions={userPermissions} />}
    </div>);
}

export default RealEstateList;