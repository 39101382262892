import React, { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { get, post, put, remove } from '../../api/index'
import { GET_ALL_OPERATING_BUSINESS_YEAR, CREATE_OPERATING_BUSINESS_YEAR, UPDATE_OPERATING_BUSINESS_YEAR, GET_OPERATING_BUSINESS_YEAR, GET_OPERATING_BUSINESS, GET_ALL_OPERATING_BUSINESS } from '../../api/endpoints';
import 'react-toastify/dist/ReactToastify.css'
import { concatUrlWithId, notify } from '../../utils/commonUtils';
import { PageLoader } from '../../Context/PageLoader';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormField, DatePickerField, CurrencyField } from '../../components/Forms/FormFields';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';

const OperatingBusinessYearModel = ({ show, mode, businessID, businessYearID = null, handleClose, handleChange, yearArr = [] }) => {
    const validationSchemaYear = Yup.object({
        year: Yup.string()
            .label('Year').trim().test('valid-year', 'Year is required', (value) => {
                return  'YYYY' !== value;
              }).required('Year is required'),
        estimated_value: Yup.string().matches(/^\d{1,13}(\.\d{1,2})?$/, 'Invalid number format. Use up to 13 digits before the decimal and up to 2 digits after.').required('Estimated Value is required'),
        estimated_entry: Yup.date().label('Estimated Entry').required('Estimated Entry is required'),
        number_of_employees: Yup.string().matches(/^\d{1,13}(\.\d{1,2})?$/, 'Invalid number format. Use up to 13 digits before the decimal and up to 2 digits after.').required('Number of employees is required'),
        employees_entry: Yup.date().label('Date of Entry').required('Date of entry is required'),
        // total_assets: Yup.string().matches(/^\d{1,13}(\.\d{1,2})?$/, 'Invalid number format. Use up to 13 digits before the decimal and up to 2 digits after.').required('Total Assets is required'),
        // total_liabilities: Yup.string().matches(/^\d{1,13}(\.\d{1,2})?$/, 'Invalid number format. Use up to 13 digits before the decimal and up to 2 digits after.').required('Total Liabilities is required'),
        total_assets: Yup.string().matches(/^\d{1,13}(\.\d{1,2})?$/, 'Invalid number format. Use up to 13 digits before the decimal and up to 2 digits after.'),
        total_liabilities: Yup.string().matches(/^\d{1,13}(\.\d{1,2})?$/, 'Invalid number format. Use up to 13 digits before the decimal and up to 2 digits after.'),
    });

    const [yearInitialValues, setYearInitialValues] = useState({
        year: 'YYYY',
        estimated_value: '0.00',
        estimated_entry: '',
        number_of_employees: '',
        employees_entry: '',
        total_assets: '0.00',
        total_liabilities: '0.00'
    });
    const [yearErr , setYearErr] = useState({ isCustomErr: false, customErrMsg: '' });

    const fetchOperatingBusinessYear = async () => {
        try {
            const response = await get(concatUrlWithId(GET_OPERATING_BUSINESS_YEAR, businessYearID));
            setLoading(true);
            if (response) {

                setYearInitialValues({...response, 
                    estimated_entry: moment(response.estimated_entry).format('YYYY-MM-DD'), 
                    employees_entry: moment(response.employees_entry).format('YYYY-MM-DD'),
                });
            }
        } catch (error) {
            console.log('Error while fetching user information:', error);
            notify(error?.response?.data?.message, 'error');
        } finally {
            setLoading(false);
        }
    };
    const [fileUploadStatus, setFileUploadStatus] = useState(null);
    const [isFieldClear, setIsFieldClear] = useState(false);
    const [modelShow, setModelShow] = useState('');
    const { loading, setLoading } = useContext(PageLoader);

    useEffect(() => {
        if (businessYearID && show) {
            fetchOperatingBusinessYear()
        }
        setModelShow(show);
    }, [show])

    const handleUpload = async (values) => {
        try {
            if(values.year === 'YYYY'){
                setYearErr({
                    isCustomErr: true,
                    customErrMsg: 'Year is required'
                })
                return;
            }
            setFileUploadStatus(false)
            const payload = {
                year: values.year,
                estimated_value: values.estimated_value,
                estimated_entry: values.estimated_entry,
                number_of_employees: values.number_of_employees,
                employees_entry: values.employees_entry,
                total_assets: values.total_assets,
                total_liabilities: values.total_liabilities,
                business_id: businessID,
                id: businessYearID
            }

            // Send files to server using Axios POST request
            const response = (mode == "edit") ? await put(UPDATE_OPERATING_BUSINESS_YEAR, payload) : await post(CREATE_OPERATING_BUSINESS_YEAR, payload);

            if (response && response.business) {
                setYearErr({
                    isCustomErr: false,
                    customErrMsg: ''
                })
                setModelShow(false);
                notify(`Operating business ${values.year} ${mode == "edit" ? 'Updated' : 'Created'}  Successfully...`, 'success')
                handleChange(mode, response.business[0]);
            }

            if(!response.success && response.message){
                setYearErr({
                    isCustomErr: true,
                    customErrMsg: 'The selected year is already exist. Please choose another year.'
                })
            }

            setFileUploadStatus(true)
        } catch (error) {
            // Handle error if upload fails
            setFileUploadStatus(true)
            console.error('Error uploading files:', error);
            notify(`${error}`, 'error')
        }
    };

    const handleDateFieldChange = (year) => {
        if(yearArr.length && yearArr.some((valYear) => (year == valYear ))){
            setYearErr({
                isCustomErr: true,
                customErrMsg: 'The selected year is already exist. Please choose another year.'
            })
        }else{
            setYearErr({
                isCustomErr: false,
                customErrMsg: ''
            })
        }
    }

    const onHandleClose = (e) => {
        handleClose(e);
        setYearErr({
            isCustomErr: false,
            customErrMsg: ''
        })
    }

    return (
        <Modal show={modelShow} onHide={onHandleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>{mode === "edit" ? "Update " : "Add"} Year</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={yearInitialValues}
                    validationSchema={validationSchemaYear}
                    onSubmit={async (values, actions) => { handleUpload(values) }}
                    enableReinitialize
                >
                    {props => (
                        <>
                            <Modal.Body style={{ paddingTop: '2px' }}>
                                <div className='row'>
                                    <div className='col-6'>
                                        <DatePickerField fieldName="year" label="Year" value={yearInitialValues.year} setFieldValue={props.setFieldValue} isFieldClear={isFieldClear} 
                                        isYear={true} isCustomErr={yearErr.isCustomErr} customErrMsg={yearErr.customErrMsg} handleDateFieldChange={handleDateFieldChange}/>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-6'>
                                        <CurrencyField fieldName="estimated_value" label="Estimated Value" values={props.values} setFieldValue={props.setFieldValue}  />
                                        <FormField fieldName="number_of_employees" label="Number of Employees" value={yearInitialValues.number_of_employees} maxLength={16} type="number" />
                                        <CurrencyField fieldName="total_assets" label="Total Assets" values={props.values} setFieldValue={props.setFieldValue} isRequired={false}/>

                                    </div>
                                    <div className='col-6'>
                                        <DatePickerField fieldName="estimated_entry" label="Estimated Entry" value={yearInitialValues.estimated_entry} setFieldValue={props.setFieldValue} isFieldClear={isFieldClear} />
                                        <DatePickerField fieldName="employees_entry" label="Date of Entry" value={yearInitialValues.employees_entry} setFieldValue={props.setFieldValue} isFieldClear={isFieldClear} />
                                        <CurrencyField fieldName="total_liabilities" label="Total Liabilities" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} />

                                    </div> 
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button type="reset" variant="secondary" onClick={onHandleClose}>
                                    Cancel
                                </Button>
                                <Button disabled={fileUploadStatus === false} type="submit" onClick={() => props.submitForm()} variant="primary">
                                    {mode === "edit" ? "Update" : "Submit"} {fileUploadStatus === false && <Spinner animation="border" size="sm" />}
                                </Button>
                            </Modal.Footer>
                        </>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
}

export default OperatingBusinessYearModel;
