
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from "../../../components/Footer";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useLocation } from 'react-router-dom';
import { PageLoader } from '../../../Context/PageLoader';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { get, post, put, remove } from '../../../api'
import {
    CREATE_REAL_ESTATE_INVESTMENT, GET_REAL_ESTATE_INVESTMENT_BY_ID, UPDATE_REAL_ESTATE_INVESTMENT_BY_ID, ARCHIVE_INVESTMENT_BY_ID,
    DELETE_INVESTMENT_BY_ID, GET_REMINDER_LIST
} from '../../../api/endpoints'
import { checkPermission, concatUrlWithId, TitleTrim, ReminderTaskIcon, formatDateAndTime } from '../../../utils/commonUtils';
import { FormField, SelectField, CurrencyField, TextAreaField, DatePickerField, SystemInfo, ViewDiv, EINField } from '../../../components/Forms/FormFields';
import ImageUpload from '../../../components/DocumentManager/ImageUpload';
import TabComponent from '../../../components/TabComponent';
import { UserPermissionStore } from '../../../Store/UserPermission';
import ProfessionalContacts from '../../../components/ProfessionalContacts';
import CapitalCalls from '../../../components/CapitalCalls';
import ReminderTab from '../../../components/Reminder/ReminderTab';
import { useReactToPrint } from 'react-to-print';
import PasswordConfirmation from '../../../components/Forms/PasswordConfirmation';

export const investmentSchema = Yup.object().shape({
    investment_name: Yup.string()
        .label('Legal Entity Name').trim().required('Legal Entity Name is required'),
    // fixed_income: Yup.boolean().label('Fixed Income').required('Fixed Income is required'),
    // amount_borrowed: Yup.string().matches(/^\d{1,13}(\.\d{1,2})?$/, 'Invalid number format. Use up to 13 digits before the decimal and up to 2 digits after.').required('Amount Borrowed is required'),
    property_type: Yup.string().label('Product Type').required('Product Type is required'),
    amount_invested: Yup.string().matches(/^\d{1,13}(\.\d{1,2})?$/, 'Invalid number format. Use up to 13 digits before the decimal and up to 2 digits after.').required('Total purchase price is required'),
    investment_date: Yup.date().label('Investment Date').required('Investment Date is required'),
    current_value: Yup.string().trim().matches(/^\d{1,13}(\.\d{1,2})?$/, 'Invalid number format. Use up to 13 digits before the decimal and up to 2 digits after.').required('Current Valuation is required'),
    // broker: Yup.string().label('Broker').trim().required('Broker is required'),
    //  investment: Yup.string().label('Investment').trim().required('Investment is required'),
    manager_name: Yup.string().label('Manager Name').trim().required('Manager Name is required'),
    // contact_person_name: Yup.string().label('Contact Person Name').trim().required('Contact Person Name is required'),
    // contact_person_mobile_number: Yup.string().label('Contact Person Mobile Number').trim().required('Contact Person Mobile Number is required').matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid mobile number'),
    // contact_person_address: Yup.string().label('Contact Person Address').trim().required('Contact Person Address is required'),
    property_address: Yup.string().label('Property Address').trim().required('Property Address is required'),
    state_of_formation: Yup.string().label('State of Formation').trim().required('State of Formation is required'),
    ein: Yup.string().matches(/^\d{2}-\d{7}$/, 'EIN must be in the format XX-XXXXXXX').nullable(true)

});


function AddRealEstate() {
    const { investmentID } = useParams();
    const pathname = window.location.pathname;
    const parts = pathname.split('/');
    const urlName = parts[1];
    const [initialValues, setInitialValues] = useState({

        // is_archived,

        investment_name: '',
        is_active: true,
        amount_borrowed: '',
        amount_invested: '',
        investment_category: process.env.REACT_APP_INVESTMENT_REAL_ESTATE_MASTER_ID,
        fixed_income: false,
        note: '',
        is_active: '',
        property_address: '',
        property_type: '',
        investment_date: '',
        investment: '',
        broker: '',
        current_value: '',
        manager_name: '',
        contact_person_name: '',
        contact_person_mobile_number: '',
        contact_person_address: '',
        exit_strategy: '',
        current_value: '',
        image: '',
        property_image_remove: false,
        is_archived: '',
        state_of_formation: '',
        ownership_percentage: '',
        preferred_return_interest: '',
        equity_debt_invested: '',
        url: '',
        state_country_of_incorporation: '',
        ein: '',
        quarter1_pd: '',
        quarter2_pd: '',
        quarter3_pd: '',
        quarter4_pd: '',
        cal_current_value: '',
        contingent_liability: '',
        company_investing: '',
    });
    const [editMode, setEditMode] = useState(false);
    const [resetImageSection, setResetImageSection] = useState(false);
    const [viewMode, setViewMode] = useState(false);
    const history = useNavigate();
    const location = useLocation();
    const { setLoading } = useContext(PageLoader);
    const [isSaveNew, setIsSaveNew] = useState(false);
    const [isFieldClear, setIsFieldClear] = useState(false);
    const [viewAction, setViewAction] = useState(true);
    const [previousPage, setPreviousPage] = useState('');
    const [previousUrl, setPreviousUrl] = useState('');
    const [selectedImage, setSelectedImage] = useState('');
    const [imageRemove, setImageRemove] = useState(false);
    const [userPermissions, setUserPermissions] = useState([]);
    const { checkAndUpdatePermissions } = UserPermissionStore();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isImageFieldClear, setIsImageFieldClear] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [capitalList, setCapitalList] = useState([]);
    const [printMode, setPrintMode] = useState(false);
    const [showReminderModel, setShowReminderModel] = useState(false);
    const [showReminderTask, setShowReminderTask] = useState(false);
    const [reminderTaskCount, setReminderTaskCount] = useState(0);
    const category_id = process.env.REACT_APP_INVESTMENT_REAL_ESTATE_MASTER_ID;
    const formRef = useRef();
    const [showErrorIcon, setShowErrorIcon] = useState(false);
    const [showPasswordModel, setShowPasswordModel] = useState(false);
    const [deleteItem, setDeleteItem] = useState({ id: '', name: '' })

    // Function to format phone number to US format
    function formatPhoneNumber(phoneNumber) {
        if (!phoneNumber) return '';
        const formattedValue = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        return formattedValue;
    }


    function ChangeEditmode(id) {
        setLoading(true);
        history(`/edit-private-equity/${investmentID}`, { state: { previousPage: "Private Equity View", previousUrl: `/view-private-equity/${investmentID}` } });
    }
    const revertDelete = () => {
        setDeleteItem({ id: '', name: '' })
    }

    function deleteStock(id, Name) {
        confirmAlert({
            title: 'Delete',
            message: `Are you sure you want to delete the ${Name}?`,
            buttons: [
                {
                    label: 'Yes',
                    // onClick: () => handleDelete(id, Name)
                    onClick: () => {
                        setDeleteItem({ id: id, name: Name });
                        setShowPasswordModel(true)
                    }
                },
                {
                    label: 'No',
                    onClick: revertDelete()
                }
            ]
        });

    }

    const handleBackClick = (investmentId, pageUrl) => {
        setImageRemove(false);
        setSelectedImage('')
        const previousUrl = document.referrer; // Get the referring URL
        const currentUrl = window.location.pathname; // Get the current URL path

        if (previousUrl.includes('portfolio-summary') && !currentUrl.includes('edit-private-equity')) {
            history('/portfolio-summary');
        } else if (pageUrl) {
            history(pageUrl);
        } else {
            history('/private-equity-list');
        }
    };

    const handleDelete = async () => {
        const { id, name } = deleteItem;
        try {
            setLoading(true)
            const deleteInvestment = await remove(concatUrlWithId(DELETE_INVESTMENT_BY_ID, id));
            if (deleteInvestment) {
                setShowPasswordModel(false);
                history('/private-equity-list')
            }
            setShowPasswordModel(false);
            notify(`${name} successfully deleted`, 'success')
        } catch (error) {
            console.log('Error while deleting the error : ', error)
            setShowPasswordModel(false);
        }
    };

    const archivedStock = (investment_id, investment_name) => {
        confirmAlert({
            title: 'Archive',
            message: `Are you sure want to Archive ${investment_name}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleArchived(investment_id, investment_name)
                },
                {
                    label: 'No',
                }
            ]
        });
    }


    const handleArchived = async (investment_id, investment_name) => {
        try {
            setLoading(true)
            const archive = await put(concatUrlWithId(ARCHIVE_INVESTMENT_BY_ID, investment_id), { is_archived: true })

            if (archive) {
                history('/private-equity-list')
            }
            notify(`${investment_name} has been archived.`, 'warning',)
        } catch (error) {
            console.log('Error while deleteing the error : ', error)
        }

    };

    const notify = (message, type) => toast(message, { type });

    const authCheckAndUpdate = async () => {
        let tempPermission = await checkAndUpdatePermissions();
        setUserPermissions(tempPermission || []);
    };

    useEffect(() => {
        setImageRemove(false);
        setSelectedImage('')
    }, [urlName])


    useEffect(() => {
        // window.scrollTo(0, 0);
        // Check and Update permissions
        authCheckAndUpdate();
        const currentDomain = window.location.hostname;
        const referrer = document.referrer;
        if (urlName == 'view-private-equity') {
            setViewMode(true)
            setEditMode(false);
            setViewAction(true);
            setPreviousPage('Private Equity List')
            setPreviousUrl('/private-equity-list')
        } else if (urlName == 'edit-private-equity') {
            setViewMode(false)
            setEditMode(true)
            setViewAction(false)
            setPreviousPage('Private Equity List')
            setPreviousUrl('/private-equity-list')
        } 
         if (referrer.includes(currentDomain)) {
            const referrerPath = referrer.split('/').filter(Boolean);
            const lastSegment = referrerPath[referrerPath.length - 1];
            setPreviousPage(lastSegment)
            setPreviousUrl('/' + lastSegment)
        }

        const fetchInvestmentById = async () => {
            setLoading(true);
            if (location.state) {
                setPreviousPage(location.state.previousPage)
                setPreviousUrl(location.state.previousUrl)
            } else {
                setLoading(false);
            }
            if ((urlName == 'edit-private-equity') || (urlName == 'view-private-equity')) {

                try {
                    setLoading(true);
                    const response = await get(concatUrlWithId(GET_REAL_ESTATE_INVESTMENT_BY_ID, investmentID));
                    if (response) {
                        let values = {
                            ...response,
                            investment_date: formatDateAndTime(response['real_estate_investment_details.investment_date'], 'YYYY-MM-DD'),
                            current_value_updated_date: moment(response.current_value_updated_date).format('MM/DD/YYYY'),
                            investment_id: response.id,
                            property_type: response['real_estate_investment_details.property_type'],
                            investment_value: response['real_estate_investment_details.investment_value'],
                            current_value: response['real_estate_investment_details.current_value'],
                            broker: response['real_estate_investment_details.broker'],
                            investment_detail_id: response['real_estate_investment_details.id'],
                            property_address: response['real_estate_investment_details.property_address'],
                            investment: response['real_estate_investment_details.investment'],
                            current_value: response['current_value'],
                            manager_name: response['real_estate_investment_details.manager_name'],
                            contact_person_name: response['real_estate_investment_details.contact_person_name'],
                            contact_person_mobile_number: response['real_estate_investment_details.contact_person_mobile_number'],
                            contact_person_address: response['real_estate_investment_details.contact_person_address'],
                            amount_invested: response['amount_invested'],
                            exit_strategy: response['real_estate_investment_details.exit_strategy'],
                            property_image: response['real_estate_investment_details.property_image'],
                            auditLog: response?.investmentAuditLog?.investment_headers?.current_value,
                            investmentAuditLog: response?.investmentAuditLog?.investment_headers?.amount_invested,
                            state_of_formation: response['real_estate_investment_details.state_of_formation'] || '',
                            ownership_percentage: response['real_estate_investment_details.ownership_percentage'],
                            preferred_return_interest: response['real_estate_investment_details.preferred_return_interest'],
                            equity_debt_invested: response['real_estate_investment_details.equity_debt_invested'],
                            url: response['real_estate_investment_details.url'] || '',
                            state_country_of_incorporation: response['real_estate_investment_details.state_country_of_incorporation'] || '',
                            company_investing: response['real_estate_investment_details.company_investing']
                            // cal_current_value: response['real_estate_investment_details.cal_current_value'],
                        };

                        getReminderTaskCount();
                        setContacts(response.professional_contacts);
                        setCapitalList(response.capital_calls);
                        setInitialValues(values);
                        
                        if (response.permission_keys?.length && response.permission_keys?.[0]?.permission_keys) {
                            setUserPermissions(response.permission_keys[0].permission_keys)
                        }
                        if (response.is_archived == true) {
                            setViewAction(false)
                            setPreviousPage('Archived List')
                            setPreviousUrl('/archived-list')
                        }
                    }
                } catch (error) {
                    window.scrollTo(0, 1);
                    console.log('Error while throwing:: ', error);
                    notify(error?.response?.data?.message, 'error');
                } finally {
                    window.scrollTo(0, 1);
                    setLoading(false);
                }
            } else {
                window.scrollTo(0, 1);
                setLoading(false);
            }
        }
        fetchInvestmentById();

    }, [urlName]);


    // Reminder change start

    const handleReminderModelClose = () => {
        setShowReminderModel(false);
    };

    const getReminderTaskCount = async () => {

        let payload = {
            investment_id: investmentID
        };
        const reminderTasks = await post(GET_REMINDER_LIST, payload);

        if (reminderTasks?.data?.reminderList?.length) {
            setReminderTaskCount(reminderTasks?.data?.reminderList?.length)
        } else {
            setReminderTaskCount(0)
        }
    }


    // Reminder change end 

    const propertyTypes = [
        { value: 'Commercial', label: 'Commercial' },
        { value: 'Industrial', label: 'Industrial' },
        { value: 'Land', label: 'Land' },
        { value: 'Mezzanine Debt', label: 'Mezzanine Debt' },
        { value: 'Mixed-Use', label: 'Mixed-Use' },
        { value: 'Multi Family', label: 'Multi Family' },
        { value: 'Office', label: 'Office' },
        { value: 'Private Money Loan', label: 'Private Money Loan' },
        { value: 'Retail', label: 'Retail' },
        { value: 'Residential', label: 'Residential' },
        { value: 'Venture Capital', label: 'Venture Capital' },
    ];

    const handleImageChange = async (image) => {

        setSelectedImage(image);
    }
    const handleImageRemove = async (value) => {
        setImageRemove(value);
        setSelectedImage('');
    }
    const resetImageFlag = async (value) => {
        setResetImageSection(value)
    }

    const handleFieldClear = async (value) => {
        setIsImageFieldClear(value)
    }

    const handleCollectedContacts = (updatedContacts) => {
        setContacts(updatedContacts);
    };

    const handleCollectedCapital = (updatedCapitals) => {
        setCapitalList(updatedCapitals);
    };

    const updateReminderData = async () => {
        await getReminderTaskCount();
    }

    const handleBeforePrint = () => {
        setPrintMode(true);
    };

    const handleAfterPrint = () => {
        setPrintMode(false);
        setLoading(false);
    };

    const handlePrint = useReactToPrint({
        content: () => formRef.current,
        onBeforeGetContent: handleBeforePrint,
        onAfterPrint: handleAfterPrint,
        documentTitle: initialValues.investment_name || 'investment'
    });

    const handleChangePrint = () => {
        confirmAlert({
            title: 'Alert',
            message: `For better viewing, please use landscape mode.`,
            buttons: [
                {
                    label: 'Done',
                    onClick: () => { setPrintMode(true); setLoading(true) }
                },
            ]
        });
    }

    const onDataLoadingComplete = () => {
        setLoading(false);
        handlePrint()
    }

    useEffect(() => {
        if (printMode) {
            setTimeout(() => {
                handlePrint()
            }, 100);
        };
    }, [printMode])

    const checkValidation = () => {
        setShowErrorIcon(true);
    }

    return (

        <>
            <button id="update-remainder-data" onClick={() => updateReminderData()} style={{ display: 'none' }}> Click me </button>
            <div className="container-fluid" style={{ marginTop: '20px' }}>

                <div className="col-12" style={{ padding: '15px' }}>
                    <div className="card">
                        <div className="card-body card-breadcrumb">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <a href="#" onClick={() => handleBackClick(initialValues?.id, previousUrl)} className="btn btn-light mb-2 me-2" style={{ display: 'flex', alignItems: 'center' }}><i className="arrow_left"></i>
                                    Back</a>
                                {<div className="page-title-right title-prefix" >
                                    {`${editMode ? 'Edit -' : (viewMode ? 'View -' : '')}`} <TitleTrim title={initialValues.investment_name} />
                                </div>}
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="#" onClick={() => handleBackClick(initialValues?.id, previousUrl)}>{previousPage}
                                        </a></li>
                                        <li className="breadcrumb-item active" >{editMode ? 'Edit Private Equity' : (viewMode ? 'View Private Equity' : 'Add Private Equity')}</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div ref={formRef}>
                {((editMode && initialValues?.id) || (viewMode && initialValues?.id) || !(viewMode || editMode)) && <Formik

                    initialValues={initialValues}
                    validationSchema={investmentSchema}
                    onSubmit={async (values, actions) => {
                        setIsSubmitting(true);
                        setLoading(true);
                        try {
                            let response = []
                            const url = editMode ? concatUrlWithId(UPDATE_REAL_ESTATE_INVESTMENT_BY_ID, investmentID) : CREATE_REAL_ESTATE_INVESTMENT;
                            const bodyPayload = {
                                investment_name: values.investment_name,
                                // is_active: values.is_active,
                                property_type: values.property_type,
                                amount_borrowed: values.amount_borrowed,
                                amount_invested: values.amount_invested,
                                investment_category: values.investment_category,
                                investment_date: values.investment_date,
                                broker: values.broker,
                                fixed_income: values.fixed_income,
                                note: values.note,
                                property_address: values.property_address,
                                company_investing: values.company_investing.trim(),
                                investment: values.investment,
                                current_valuation: values.current_value,
                                manager_name: values.manager_name,
                                contact_person_name: values.contact_person_name,
                                contact_person_mobile_number: values.contact_person_mobile_number,
                                contact_person_address: values.contact_person_address,
                                total_purchase_price: values.amount_invested,
                                exit_strategy: values.exit_strategy,
                                current_value: values.current_value,
                                property_image: editMode ? initialValues.property_image : '',
                                property_image_remove: editMode && imageRemove,
                                state_of_formation: values.state_of_formation || '',
                                ownership_percentage: values.ownership_percentage,
                                preferred_return_interest: values.preferred_return_interest,
                                professional_contacts: JSON.stringify(contacts),
                                capital_calls: JSON.stringify(capitalList),
                                equity_debt_invested: values.equity_debt_invested,
                                url: values.url,
                                quarter1_pd: values.quarter1_pd,
                                quarter2_pd: values.quarter2_pd,
                                quarter3_pd: values.quarter3_pd,
                                quarter4_pd: values.quarter4_pd,
                                state_country_of_incorporation: values.state_country_of_incorporation || '',
                                ein: values.ein || '',
                                contingent_liability: values.contingent_liability
                            };
                            
                            // Create a new FormData object
                            const formData = new FormData();

                            // Iterate through the keys of the bodyPayload object and append each key-value pair to the FormData object
                            for (const key in bodyPayload) {
                                if (bodyPayload.hasOwnProperty(key)) {
                                    formData.append(key, bodyPayload[key]);
                                }
                            }

                            formData.append('file', selectedImage);
                            response = editMode ? await put(url, formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            }) : await post(url, formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            });

                            if (response) {

                                notify(`Private Equity ${values.investment_name} ${editMode ? "Updated" : "Added"} Successfully...`, 'success')

                                if (isSaveNew == true) {
                                    actions.resetForm();
                                    setIsFieldClear((prevState) => prevState ? prevState + 1 : 1);
                                    setIsImageFieldClear(true)
                                    setContacts([]);
                                    setShowErrorIcon(false)
                                    setCapitalList([]);
                                } else {
                                    setIsImageFieldClear(true)
                                    setImageRemove(false);
                                    if (previousUrl) {
                                        history(previousUrl);
                                    } else {
                                        history('/private-equity-list', { state: { status: editMode ? "update" : "create" } });
                                        setLoading(true);
                                    }
                                }

                            } else {
                                editMode ? notify(`Private Equity update failed...`, 'error') : notify(`Create Stock failed...`, 'error');
                            }
                        } catch (error) {
                            notify(error?.response?.data?.message, 'error');

                            console.error('API Error:', error);
                        }
                        setIsSubmitting(false);
                        setLoading(false);
                    }}
                >
                    {props => (
                        <div className="main-content introduction-farm">
                            <div className="content-wraper-area">
                                <div className="dashboard-area">
                                    <div className="container-fluid">
                                        <div className="card">
                                            <div className={`card-body ${viewMode ? 'view-mode bg-white' : 'add-mode'}`}>
                                                {!viewAction && viewMode && <div className='no-print' style={{ textAlign: 'center' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Print</Tooltip>} >
                                                            <i className="fa fa-print btn-circle print-btn" onClick={() => { handleChangePrint() }} ></i>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                                }
                                                {viewAction && viewMode && <div style={{ textAlign: 'center' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Print</Tooltip>} >
                                                            <i className="fa fa-print btn-circle print-btn no-print" onClick={() => { handleChangePrint() }} ></i>
                                                        </OverlayTrigger>
                                                        {<ReminderTaskIcon
                                                            iconClass="no-print"
                                                            tooltip={`Set/View Reminders `}
                                                            onClick={() => setShowReminderTask(true)}
                                                            backgroundColor="#8098ad"
                                                            count={reminderTaskCount}
                                                        />}
                                                        {/* {<OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Set Reminder</Tooltip>} >
                                                        <i className="zmdi zmdi-alarm-plus btn btn-circle" onClick={() => setShowReminderModel(true)} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: '#359bff', cursor: 'pointer' }} ></i>
                                                    </OverlayTrigger>} */}
                                                        {(checkPermission(userPermissions, 'edit-real-estate-invt') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Edit Private Equity</Tooltip>} >
                                                            <i className="bx bxs-edit btn btn-circle no-print" onClick={() => ChangeEditmode(initialValues?.id)} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: '#58ae25', cursor: 'pointer' }} ></i>
                                                        </OverlayTrigger>}
                                                        {(checkPermission(userPermissions, 'archive-real-estate-invt') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Archive Private Equity</Tooltip>} >
                                                            <i className="zmdi zmdi-archive btn btn-circle no-print" onClick={() => archivedStock(initialValues?.id, initialValues?.investment_name)} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: '#74788d', cursor: 'pointer' }} ></i>
                                                        </OverlayTrigger>}
                                                        {(checkPermission(userPermissions, 'delete-real-estate-invt') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Delete Private Equity</Tooltip>} >
                                                            <i className="bx bx-trash btn btn-circle no-print" onClick={() => deleteStock(initialValues?.id, initialValues?.investment_name)} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: '#dd4a57', cursor: 'pointer' }}></i>
                                                        </OverlayTrigger>}
                                                    </div>
                                                </div>
                                                }
                                                <Form>
                                                    {printMode && <div className="card-title only-visible-in-print" style={{ marginBottom: '5px' }}>
                                                        <div style={{ display: 'flex', justifyContent: 'center', }}>
                                                            <h4 className='heading-print text-bold' style={{ wordWrap: 'break-word', maxWidth: '300px' }}>Private Equity - {initialValues.investment_name}</h4>
                                                        </div>
                                                    </div>}
                                                    <div className='row print-font-header'>
                                                        <div className='col-6'>
                                                            {viewMode && <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                                                {initialValues.property_image && <div className='mb-4'>
                                                                    <legend className='text-bold lenged-view'> Property Image</legend>
                                                                    <div className='img-container'>
                                                                        <img src={initialValues.property_image} className="centered-image inv-image-height" />
                                                                    </div>
                                                                </div>}
                                                                <div >
                                                                    {(!initialValues.property_image) && <> <legend className='text-bold lenged-view'> Investment Details </legend>
                                                                        <ViewDiv fieldLabel={"Company Investing"} fieldValue={initialValues.company_investing} type={"text"} />
                                                                        <ViewDiv fieldLabel={"Total Purchase Price($)"} fieldValue={initialValues.amount_invested} type={"currency"} />
                                                                        <ViewDiv fieldLabel={"Current Valuation($)"} fieldValue={initialValues.current_value} type={"currency"} />
                                                                        <ViewDiv fieldLabel={"Mortgage/Bank Debt($)"} fieldValue={initialValues.amount_borrowed} type={"currency"} extra={true} depValue={initialValues.contingent_liability} />
                                                                        <ViewDiv fieldLabel={"Ownership Percentage(%)"} fieldValue={initialValues.ownership_percentage || ""} type={"text"} />
                                                                        <ViewDiv fieldLabel={"Investment Value after Debt($)"} fieldValue={initialValues.cal_current_value} type={"currency"} />
                                                                        <ViewDiv fieldLabel={"Preferred Return/Interest(%)"} fieldValue={initialValues.preferred_return_interest || ""} type={"text"} />
                                                                        <ViewDiv fieldLabel={"Equity/Debt Invested($)"} fieldValue={initialValues.equity_debt_invested || ""} type={"currency"} /></>}

                                                                    <legend className='text-bold lenged-view'> Financial Entities </legend>
                                                                    <ViewDiv fieldLabel={"Broker"} fieldValue={initialValues.broker} type={"text"} />
                                                                    <ViewDiv fieldLabel={"Manager Name"} fieldValue={initialValues.manager_name} type={"text"} />
                                                                    <ViewDiv fieldLabel={"Contact Person Name"} fieldValue={initialValues.contact_person_name} type={"text"} />
                                                                    <ViewDiv fieldLabel={"Contact Person Mobile Number"} fieldValue={formatPhoneNumber(initialValues.contact_person_mobile_number)} type={"text"} />
                                                                    <ViewDiv fieldLabel={"Contact Person Address"} fieldValue={initialValues.contact_person_address} type={"text"} />
                                                                    <ViewDiv fieldLabel={"Property Address"} fieldValue={initialValues.property_address} type={"text"} />
                                                                    <ViewDiv fieldLabel={"URL"} fieldValue={initialValues.url} type={"url"} />



                                                                    {<> <legend className='text-bold lenged-view'> System Information</legend>
                                                                        <ViewDiv fieldLabel={"Created At"} fieldValue={initialValues.createdAt} type={"date"} dateFormat={'MM/DD/YYYY hh:mm a'} />
                                                                        <ViewDiv fieldLabel={"Last Modified At"} fieldValue={initialValues.updatedAt} type={"date"} dateFormat={'MM/DD/YYYY hh:mm a'} />
                                                                        <ViewDiv fieldLabel={"Created By"} fieldValue={initialValues['investment_headers_created_by.full_name']} type={"text"} />
                                                                        <ViewDiv fieldLabel={"Last Modified By"} fieldValue={initialValues['investment_headers_modified_by.full_name']} type={"text"} /></>}
                                                                </div>
                                                            </div>}

                                                            {(editMode || (!viewMode && !editMode)) &&
                                                                <fieldset>
                                                                    <legend className='text-bold'>Property Image</legend>
                                                                    {/* <FileUploadField viewMode={viewMode} fieldName="image" label="Property Image"   imageName={initialValues?.image_name} imageType={initialValues?.image_type} imageData={initialValues?.image_data} /> */}
                                                                    <ImageUpload viewMode={viewMode} editMode={editMode} fieldName="property_image" handleImageChange={handleImageChange} handleImageRemove={handleImageRemove} setFieldValue={props.setFieldValue} value={initialValues.property_image} resetImage={resetImageSection} resetImageFlag={resetImageFlag} isImageFieldClear={isImageFieldClear} handleFieldClear={handleFieldClear} />
                                                                </fieldset>
                                                            }


                                                            {!viewMode &&

                                                                <fieldset>
                                                                    <legend className='text-bold'> Private Equity Information </legend>
                                                                    <FormField viewMode={viewMode} fieldName="investment_name" label="Legal Entity Name" value={initialValues.investment_name} maxLength={200} />

                                                                    <EINField viewMode={viewMode} fieldName="ein" label="EIN" value={initialValues.ein} maxLength={10} isRequired={false} />

                                                                    <SelectField viewMode={viewMode} fieldName="property_type" label="Product Type" value={initialValues.property_type} options={propertyTypes} values={props.values} setFieldValue={props.setFieldValue} />

                                                                    <FormField viewMode={viewMode} fieldName="state_country_of_incorporation" label="State/Country of Incorporation" value={initialValues.state_country_of_incorporation} maxLength={200} isRequired={false} />

                                                                    <DatePickerField viewMode={viewMode} fieldName="investment_date" label="Investment Date" value={initialValues.investment_date} setFieldValue={props.setFieldValue} isFieldClear={isFieldClear} />

                                                                    <FormField viewMode={viewMode} fieldName="state_of_formation" label="State of Formation" value={initialValues.state_of_formation} maxLength={200} />

                                                                    <TextAreaField viewMode={viewMode} fieldName="url" label="URL" value={initialValues.url} maxLength={2000} textAlign={true} />

                                                                </fieldset>
                                                            }

                                                            {!viewMode && <fieldset>
                                                                <legend className='text-bold'> Additional Information </legend>
                                                                <TextAreaField viewMode={viewMode} fieldName="exit_strategy" label="Exit Strategy/Payout Details" value={initialValues.exit_strategy} maxLength={500} />
                                                                <TextAreaField viewMode={viewMode} fieldName="note" label="Notes" value={initialValues.note} maxLength={500} />
                                                            </fieldset>}

                                                        </div>

                                                        {viewMode && <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                                            <div >
                                                                <legend className='text-bold lenged-view'> Private Equity Information</legend>
                                                                <ViewDiv fieldLabel={"Legal Entity Name"} fieldValue={initialValues.investment_name} type={"text"} />
                                                                <ViewDiv fieldLabel={"EIN"} fieldValue={initialValues.ein} type={"text"} />
                                                                <ViewDiv fieldLabel={"Product Type"} fieldValue={initialValues.property_type} type={"text"} />
                                                                <ViewDiv fieldLabel={"State/Country of Incorporation"} fieldValue={initialValues.state_country_of_incorporation} type={"text"} />
                                                                <ViewDiv fieldLabel={"Investment Date"} fieldValue={initialValues.investment_date} type={"date"} />
                                                                <ViewDiv fieldLabel={"State of Formation"} fieldValue={initialValues.state_of_formation} type={"text"} />


                                                                {(initialValues.property_image) && <>  <legend className='text-bold lenged-view'> Investment Details </legend>
                                                                    <ViewDiv fieldLabel={"Total Purchase Price($)"} fieldValue={initialValues.amount_invested} type={"currency"} />
                                                                    <ViewDiv fieldLabel={"Current Valuation($)"} fieldValue={initialValues.current_value} type={"currency"} />
                                                                    <ViewDiv fieldLabel={"Mortgage/Bank Debt($)"} fieldValue={initialValues.amount_borrowed} type={"currency"} extra={true} depValue={initialValues.contingent_liability} />
                                                                    <ViewDiv fieldLabel={"Investment(%)"} fieldValue={initialValues.investment || ""} type={"text"} />
                                                                    <ViewDiv fieldLabel={"Ownership Percentage(%)"} fieldValue={initialValues.ownership_percentage || ""} type={"text"} />
                                                                    <ViewDiv fieldLabel={"Investment Value after Debt($)"} fieldValue={initialValues.cal_current_value} type={"currency"} />
                                                                    <ViewDiv fieldLabel={"Preferred Return/Interest(%)"} fieldValue={initialValues.preferred_return_interest || ""} type={"text"} />
                                                                    <ViewDiv fieldLabel={"Equity/Debt Invested($)"} fieldValue={initialValues.equity_debt_invested || ""} type={"currency"} /> </>}

                                                                <legend className='text-bold lenged-view'>Cash Flow</legend>
                                                                <ViewDiv fieldLabel={"1 st Quarter Projected Distributions($)"} fieldValue={initialValues.quarter1_pd} type={'currency'} />
                                                                <ViewDiv fieldLabel={"2 st Quarter Projected Distributions($)"} fieldValue={initialValues.quarter2_pd} type={'currency'} />
                                                                <ViewDiv fieldLabel={"3 st Quarter Projected Distributions($)"} fieldValue={initialValues.quarter3_pd} type={'currency'} />
                                                                <ViewDiv fieldLabel={"4 st Quarter Projected Distributions($)"} fieldValue={initialValues.quarter4_pd} type={'currency'} />

                                                                <legend className='text-bold lenged-view'> Additional Information </legend>
                                                                <ViewDiv fieldLabel={"Exit Strategy/Payout Details"} fieldValue={initialValues.exit_strategy} type={"text-area"} />
                                                                <ViewDiv fieldLabel={"Notes"} fieldValue={initialValues.note} type={"text-area"} />

                                                                {/* {!printMode && <>
                                                                    <legend className="text-bold lenged-view">Professional Contacts</legend>
                                                                    <div className="mb-2">
                                                                        <ProfessionalContacts viewAction={viewMode} contactList={contacts} CollectedContact={handleCollectedContacts} />
                                                                    </div>


                                                                    <legend className="text-bold lenged-view">Capital Calls</legend>
                                                                    <div className="mb-2">
                                                                        <CapitalCalls viewAction={viewMode} capitalList={capitalList} CollectedCapital={handleCollectedCapital} />
                                                                    </div>

                                                                </>} */}

                                                            </div>
                                                        </div>}
                                                        {(viewMode) ? <>
                                                            <div className='col-12 only-visible-in-print'>
                                                                <legend className="text-bold lenged-view">Professional Contacts</legend>
                                                                <div className="mb-2">
                                                                    <ProfessionalContacts viewAction={viewMode} contactList={contacts} CollectedContact={handleCollectedContacts} />
                                                                </div>
                                                            </div>
                                                            <div className='col-12 only-visible-in-print'>
                                                                <legend className="text-bold lenged-view">Capital Calls</legend>
                                                                <div className="mb-2">
                                                                    <CapitalCalls viewAction={viewMode} capitalList={capitalList} CollectedCapital={handleCollectedCapital} />
                                                                </div>
                                                            </div>
                                                        </> : ''}
                                                        {(viewMode && !printMode) ? <>
                                                            <div className='col-12'>
                                                                <legend className="text-bold lenged-view">Professional Contacts</legend>
                                                                <div className="mb-2">
                                                                    <ProfessionalContacts viewAction={viewMode} contactList={contacts} CollectedContact={handleCollectedContacts} />
                                                                </div>
                                                            </div>
                                                            <div className='col-8'>
                                                                <legend className="text-bold lenged-view">Capital Calls</legend>
                                                                <div className="mb-2">
                                                                    <CapitalCalls viewAction={viewMode} capitalList={capitalList} CollectedCapital={handleCollectedCapital} />
                                                                </div>
                                                            </div>
                                                        </> : ''}

                                                        {!viewMode && <div className='col-lg-6'>
                                                            <fieldset>
                                                                <legend className='text-bold'> Investment Details </legend>
                                                                <FormField viewMode={viewMode} fieldName="company_investing" label="Company Investing" value={initialValues.company_investing} maxLength={200} isRequired={false} />

                                                                <CurrencyField viewMode={viewMode} fieldName="amount_invested" label="Total Purchase Price" values={props.values} setFieldValue={props.setFieldValue} auditLog={initialValues.investmentAuditLog ? true : false} auditValue={initialValues.investmentAuditLog} />

                                                                <CurrencyField viewMode={viewMode} fieldName="current_value" label="Current Valuation" values={props.values} setFieldValue={props.setFieldValue} auditLog={initialValues.auditLog ? true : false} auditValue={initialValues.auditLog} />

                                                                <div className='row'>
                                                                    <div className='col-lg-6'>
                                                                        <CurrencyField viewMode={viewMode} fieldName="amount_borrowed" label="Mortgage/Bank Debt" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} />
                                                                    </div>
                                                                    <div className='col-lg-6'>
                                                                        <CurrencyField viewMode={viewMode} fieldName="contingent_liability" label="Contingent Liability(%)" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} prefix='' />
                                                                    </div>
                                                                </div>

                                                                <CurrencyField viewMode={viewMode} fieldName="ownership_percentage" label="Ownership Percentage(%)" values={props.values} setFieldValue={props.setFieldValue} prefix={''} isRequired={false} disableNegative={true} />

                                                                <CurrencyField viewMode={viewMode} fieldName="preferred_return_interest" label="Preferred Return/Interest(%)" values={props.values} setFieldValue={props.setFieldValue} prefix={''} isRequired={false} disableNegative={true} />

                                                                <CurrencyField viewMode={viewMode} fieldName="equity_debt_invested" label="Equity/Debt Invested" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} />

                                                            </fieldset>

                                                            <fieldset>
                                                                <legend className='text-bold'> Financial Entities</legend>

                                                                <FormField viewMode={viewMode} fieldName="broker" label="Broker" value={initialValues.broker} maxLength={200} isRequired={false} />

                                                                <FormField viewMode={viewMode} fieldName="manager_name" label="Manager Name" value={initialValues.manager_name} maxLength={200} />

                                                                <FormField viewMode={viewMode} fieldName="contact_person_name" label="Contact Person Name" value={initialValues.contact_person_name} maxLength={200} isRequired={false} />

                                                                <div className="mb-3">
                                                                    <label className="form-label" htmlFor="formrow-holding-company-input">Contact Person Mobile Number</label>
                                                                    {!viewMode && (
                                                                        <Field className="form-control" maxLength={14} readOnly={viewMode}
                                                                            type="tel" id="formrow-holding-company-input" name="contact_person_mobile_number"
                                                                            onInput={(e) => {
                                                                                const inputValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                                                                                const formattedValue = inputValue.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                                                                                e.target.value = formattedValue;
                                                                            }}
                                                                        />
                                                                    )}
                                                                    {viewMode && <div className='view-label'>{formatPhoneNumber(initialValues.contact_person_mobile_number)}</div>}
                                                                    <ErrorMessage name="contact_person_mobile_number" component="div" className="error-message" />
                                                                </div>


                                                                <TextAreaField viewMode={viewMode} fieldName="contact_person_address" label="Contact Person Address" value={initialValues.contact_person_address} maxLength={200} />

                                                                <TextAreaField viewMode={viewMode} fieldName="property_address" label="Property Address" value={initialValues.property_address} maxLength={200} isRequired={true} />
                                                            </fieldset>


                                                            {(initialValues.property_image && viewMode) && <SystemInfo
                                                                createdAt={initialValues.createdAt}
                                                                updatedAt={initialValues.updatedAt}
                                                                createdBy={initialValues['investment_headers_created_by.full_name']}
                                                                updatedBy={initialValues['investment_headers_modified_by.full_name']}
                                                                columnclassName="col-md-6"
                                                            />}
                                                        </div>}

                                                    </div>

                                                    {!viewMode && <div className='col-lg-12'>

                                                        <fieldset>
                                                            <legend className='text-bold'> Cash Flow </legend>
                                                            <div className='row'>
                                                                <div className='col-lg-6'>
                                                                    <CurrencyField viewMode={viewMode} fieldName="quarter1_pd" label="1st Quarter Projected Distributions" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} disableNegative={true} />
                                                                    <CurrencyField viewMode={viewMode} fieldName="quarter3_pd" label="3rd Quarter Projected Distributions" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} disableNegative={true} />
                                                                </div>
                                                                <div className='col-lg-6'>
                                                                    <CurrencyField viewMode={viewMode} fieldName="quarter2_pd" label="2nd Quarter Projected Distributions" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} disableNegative={true} />
                                                                    <CurrencyField viewMode={viewMode} fieldName="quarter4_pd" label="4th Quarter Projected Distributions" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} disableNegative={true} />
                                                                </div>
                                                            </div>
                                                        </fieldset>


                                                        {(initialValues.property_image && viewMode) && <SystemInfo
                                                            createdAt={initialValues.createdAt}
                                                            updatedAt={initialValues.updatedAt}
                                                            createdBy={initialValues['investment_headers_created_by.full_name']}
                                                            updatedBy={initialValues['investment_headers_modified_by.full_name']}
                                                            columnclassName="col-md-6"
                                                        />}
                                                    </div>}


                                                    {(viewMode) && <div className='only-visible-in-print'>
                                                        <div className='col-12'>
                                                            <legend className="text-bold lenged-view">Professional Contacts</legend>
                                                            <div className="mb-2">
                                                                <ProfessionalContacts viewAction={viewMode} contactList={contacts} CollectedContact={handleCollectedContacts} />
                                                            </div>

                                                            <legend className="text-bold lenged-view">Capital Calls</legend>
                                                            <div className="mb-2">
                                                                <CapitalCalls viewAction={viewMode} capitalList={capitalList} CollectedCapital={handleCollectedCapital} />
                                                            </div>
                                                        </div>
                                                    </div>}
                                                    {!viewMode && <div className="col-lg-8 col-md-8 col-sm-8 col-12">
                                                        <fieldset>
                                                            <legend className="text-bold">Professional Contacts</legend>
                                                            <div className="mb-2">
                                                                <ProfessionalContacts contactList={contacts} CollectedContact={handleCollectedContacts} />
                                                            </div>
                                                        </fieldset>
                                                    </div>}

                                                    {!viewMode && <div className="col-lg-8 col-md-8 col-sm-8 col-12">
                                                        <fieldset>
                                                            <legend className="text-bold">Capital Calls</legend>
                                                            <div className="mb-2">
                                                                <CapitalCalls capitalList={capitalList} CollectedCapital={handleCollectedCapital} />
                                                            </div>
                                                        </fieldset>
                                                    </div>}
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                gap: '10px'
                                                            }}>
                                                                {!viewMode && showErrorIcon && !props.isValid && <><OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Please find the page error</Tooltip>} >
                                                                    <i className="zmdi zmdi-block-alt" style={{ fontSize: '19px', margin: '0px 5px', padding: '8px 0px 0px 0px', color: 'red', cursor: 'pointer' }} ></i>
                                                                </OverlayTrigger></>}
                                                                {/* {!viewMode && <button type="reset" onClick={() => history('/stock-list')} className="btn btn-secondary w-md">{viewMode ? 'Back' : 'Cancel'}</button>} */
                                                                    !viewMode && <button type="reset" onClick={() => handleBackClick(initialValues?.id, previousUrl)} className="btn btn-secondary w-md">{viewMode ? 'Back' : 'Cancel'}</button>}

                                                                {!viewMode && <button type="submit" onClick={() => { setIsSaveNew(false); checkValidation(); }} disabled={isSubmitting} className="btn btn-primary w-md">{editMode ? 'Update' : 'Save'}</button>}
                                                                {editMode != true && (
                                                                    !viewMode && <button type="submit" onClick={() => { setIsSaveNew(true); checkValidation(); }} className="btn btn-primary w-md">{editMode ? 'create' : 'Save and New'}</button>)}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <PasswordConfirmation showViewModal={showPasswordModel} handleDelete={handleDelete} handleCloseViewModal={() => { setShowPasswordModel(false); revertDelete() }} />
                            {/* <ReminderTaskModel show={showReminderTask} handleClose={() => setShowReminderTask(false)} investmentID={investmentID} handelListUpdate={getReminderTaskCount} />
                        <ReminderList show={showReminderModel} recordId={investmentID} handleClose={handleReminderModelClose} entityType={'investment'} headerName={'Reminder'} invesmentCat={category_id} handelListUpdate={getReminderTaskCount} /> */}
                            <ReminderTab show={showReminderTask} recordId={investmentID} handleClose={() => setShowReminderTask(false)} hangleTabChange={getReminderTaskCount}
                                invesmentCatId={category_id} entityType={'investment'} isAdminOrManager={(checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager'))} />
                            <div className="card-body">
                                {viewMode && userPermissions?.length && !printMode && <TabComponent investmentId={initialValues?.id} permissions={userPermissions} viewAction={viewAction}
                                    uploadPermission={checkPermission(userPermissions, 'upload-real-estate-invt') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')} isArchived={!viewAction} documentTypeKey={'Private Equity'} />}
                            </div>
                            {(userPermissions?.length && checkPermission(userPermissions, 'isManager') && (editMode || viewMode)) && <Footer PropUserPermissions={userPermissions} />}
                        </div>
                    )}
                </Formik >
                }
            </div>
        </>
    );
}
export default AddRealEstate;