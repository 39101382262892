import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import Spinner from 'react-bootstrap/Spinner';
import { get, post, put, remove } from '../../api'
import { ESTATE_PLANNING_DOCUMENT_UPLOAD, ESTATE_PLANNING_DOCUMENT_GET_BY_ID, ESTATE_PLANNING_TYPES } from '../../api/endpoints'
import { FormField, SelectField, TextAreaField } from '../Forms/FormFields';
import { Formik } from 'formik';
import { useDropzone } from 'react-dropzone';
import { formatDateAndTime, getFileNameFromS3Url, notify } from '../../utils/commonUtils';

function EstatePlanningUploader({ show, handleClose, mode, editId, onSuccess, ownerId }) {

    const [initialValues, setInitialValues] = useState({ document_name: '', document_type: '', document_url: '', file_versions: [], extension: '', other: '', created_by: '', updated_by: '' });

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileUploadStatus, setFileUploadStatus] = useState(null);
    const [noFileError, setNoFileError] = useState(false)
    const [estatePlanningTypes, setEstatePlanningTypes] = useState([]);
    const [selectedDocType, setSelectedDocType] = useState("");
    const { getRootProps, getInputProps } = useDropzone({
        // accept: '.pdf, image/*',// Define the file types you want to accept
        accept: {
            'image/*': ['.jpeg', '.png', '.jpg', '.pdf', '.csv', '.docx'],
        },
        onDropAccepted,
        onDropRejected,
        multiple: false
    });

    function onDropAccepted(acceptedFiles) {
        const filesWithPreview = acceptedFiles.map(file => Object.assign(file, {
            objectURL: URL.createObjectURL(file)
        }));
        setSelectedFiles(prevFiles => [...prevFiles, ...filesWithPreview]);
    }

    function onDropRejected(rejectedFiles) {
        // Handle rejected files if needed
    }

    const handleRemoveFile = (indexToRemove) => {
        setSelectedFiles(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));
    };


    const handleDocType = async (val) => {
        setSelectedDocType(val);
    };


    const handleUpload = async (values) => {
        if (mode == "edit") {
            setFileUploadStatus(false)
            // Iterate over selected files

            const formData = new FormData();
            formData.append('file', selectedFiles[0]);
            formData.append('type', values.document_type)
            formData.append('id', editId)
            formData.append('description', values.description)
            formData.append('other', selectedDocType === "Other" ? values.other : "")
            // Send files to server using Axios POST request
            const response = await put(ESTATE_PLANNING_DOCUMENT_UPLOAD, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setFileUploadStatus(true)
            onSuccess(true, 'Document')

        } else {
            if (selectedFiles.length > 0) {
                setNoFileError(false)
                try {
                    setFileUploadStatus(false)
                    // Iterate over selected files
                    const formData = new FormData();
                    formData.append('file', selectedFiles[0]);
                    formData.append('type', values.document_type)
                    formData.append('owner_id', ownerId)
                    formData.append('description', values.description)
                    formData.append('other', selectedDocType === "Other" ? values.other : "")
                    // Send files to server using Axios POST request
                    const response = await post(ESTATE_PLANNING_DOCUMENT_UPLOAD, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    setFileUploadStatus(true)
                    onSuccess(true, 'Document')
                } catch (error) {
                    // Handle error if upload fails
                    console.error('Error uploading files:', error);
                    notify(`Failed to upload. Please ensure that the file format is supported and try again later.`, 'error')
                }
            } else {
                setNoFileError(true)
            }
        }
    };

    const handlePopuClose = () => {
        setInitialValues({ document_name: '', document_type: '', document_url: '', file_versions: [], extension: '', other: '', created_by: '', updated_by: '' })
        setFileUploadStatus(null)
        setSelectedFiles([])
        handleClose();
    }

    const files = selectedFiles.map((file, index) => (
        <li key={index}>
            <div className='file_list_container'>
                <div className='drop_image_container' style={{ maxWidth: '80px' }}><img src={file.objectURL} alt={file.name} /></div>
                <div>
                    <div className='drop_file_name'>
                        {file.name.length > 50 ? `${file.name.substring(0, 50)}...` : file.name}
                    </div>
                    <div className='upload-progress'>
                        {fileUploadStatus ? 'File uploaded...' : fileUploadStatus == null ? 'Ready to upload.' : 'File uploading..'}
                    </div>
                </div>
                <div style={{ width: '60px' }}>  {fileUploadStatus == null && <div className='remove-drop' onClick={() => handleRemoveFile(index)}>Remove</div>}</div>
            </div>
        </li>
    ));

    useEffect(() => {
        // Cleanup function to revoke object URLs when component unmounts or when selectedFiles change
        return () => {
            selectedFiles.forEach(file => {
                URL.revokeObjectURL(file.objectURL);
            });
        };
    }, [selectedFiles]);


    const uploadDocsSchema = Yup.object().shape({
        document_type: Yup.string()
            .label('Document Type').trim().required('Type is required'),
        other: Yup.string().trim().when('document_type', {
            is: "11451b05-100f-4dc7-93ab-0a174e25e559", // If document_type is 'other'
            then: (scheme) => scheme.required('Other description is required'),
            otherwise: (scheme) => scheme.notRequired()
        })
    });

    const fetchData = async () => {
        if (mode === 'edit' && editId !== '') {
            try {
                const editData = await get(ESTATE_PLANNING_DOCUMENT_GET_BY_ID, { id: editId });
                // Process the fetched data here
                setSelectedDocType(editData?.data?.get_type?.name || "")
                setInitialValues({
                    document_type: editData?.data?.type_id,
                    description: editData?.data?.description,
                    document_url: editData?.data?.document_url,
                    extension: editData?.data?.extension,
                    other: editData?.data?.other || "",
                    created_by: editData?.data?.estate_planning_created_by?.full_name,
                    updated_at: editData?.data?.updatedAt,
                    updated_by: editData?.data?.estate_planning_modified_by?.full_name
                })
            } catch (error) {
                // Handle any errors that occur during the API call
                console.error('Error fetching data:', error);
                notify(`Failed to data.`, 'error')
            }
        }
    };

    const fetchType = async () => {
        try {
            const editData = await get(ESTATE_PLANNING_TYPES);

            const estateType = editData.data.map(value => ({
                label: value.name,
                value: value.id
            }));

            setEstatePlanningTypes(estateType);
        } catch (error) {
            // Handle any errors that occur during the API call
            console.error('Error fetching data:', error);
            notify(`Failed to data.`, 'error')
        }
    };

    // useEffect(() => {
    //     fetchData();
    //     fetchType();
    // }, [mode, editId]);

    useEffect(() => {
        setFileUploadStatus(null);
        setSelectedFiles([])
        if (mode != "edit" && show) {
            setInitialValues({
                document_type: '',
                document_url: '',
                extension: '',
                description: '',
                other: ''
            })
            setSelectedDocType("")
            fetchType();
        } else if (mode == "edit" && show) {
            fetchData();
            fetchType();
        }
    }, [show, mode, editId])

    return (
        <Modal show={show} onHide={handlePopuClose} size="medium" centered>
            <Modal.Header closeButton>
                <Modal.Title>{mode == "edit" ? "Update Document Details" : "Upload Document"}</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={initialValues}
                validationSchema={uploadDocsSchema}
                onSubmit={async (values, actions) => { handleUpload(values) }}
                enableReinitialize
            >
                {props => (
                    <>
                        <Modal.Body>
                            <div>
                                <SelectField options={estatePlanningTypes} fieldName='document_type' label=" Type" value={initialValues.document_type} values={props.values} setFieldValue={(field, val, label) => {
                                    props.setFieldValue('document_type', val);
                                    handleDocType(label);
                                }} />
                                {(selectedDocType === "Other") && <FormField fieldName="other" label="Other" value={initialValues.other} maxLength={100} type="text" />}
                                <TextAreaField fieldName="description" label="Description" value={initialValues.description} maxLength={500} textAlign={true} />
                                {files.length == 0 && <div {...getRootProps({ className: 'drop_area' })}>
                                    <input {...getInputProps()} />
                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                </div>}
                                {noFileError && <span style={{ color: 'red', fontSize: '12px' }}> Select file for upload </span>}
                                {files.length > 0 && <aside style={{ marginTop: '10px' }}>
                                    <ul>{files}</ul>
                                </aside>}

                               {mode == "edit" && <div style={{ display: 'flex', marginTop:'10px' }}>
                                    <div style={{ width: `${['pdf', 'csv', 'file', 'docx'].includes(initialValues.extension) ? '20%' : '100%'}`}}>
                                        <img
                                            src={
                                                initialValues.extension === 'pdf' ? 'img/pdf-download.png' :
                                                initialValues.extension === 'csv' ? 'img/csv-download.png' :
                                                initialValues.extension === 'file' ? 'img/file-download.jpeg' :
                                                initialValues.extension === 'docx' ? 'img/docs-download.png' :
                                                initialValues.document_url
                                            } width={
                                                `${['pdf', 'csv', 'file', 'docx'].includes(initialValues.extension) ? '50px' : '100%'}`}
                                            style={{
                                                height:`${['pdf', 'csv', 'file', 'docx'].includes(initialValues.extension) ? '50px' : '150px'}`,
                                                border:`${['pdf', 'csv', 'file', 'docx'].includes(initialValues.extension) ? '' : '1px solid'}` 
                                            }}
                                             />
                                    </div>
                                    {(['pdf', 'csv', 'file', 'docx'].includes(initialValues.extension)) && <div style={{ width: '80%', display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'center'  }}>
                                        <span><span className='fw-bold'>Name : </span> {getFileNameFromS3Url(initialValues.document_url)}</span>
                                        <span><span className='fw-bold'>Updated At : </span> {formatDateAndTime(initialValues?.updated_at, "MM/DD/YYYY")} <span className='fw-bold'>By : </span> {initialValues?.updated_by || initialValues?.created_by || ''}</span>
                                    </div>}
                                </div>}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="reset" variant="secondary" onClick={handlePopuClose}>
                                Cancel
                            </Button>
                            <Button disabled={fileUploadStatus == false} type="submit" onClick={() => props.submitForm()} variant="primary">
                                {mode == "edit" ? "Update" : "Upload"} {fileUploadStatus == false && <Spinner animation="border" size="sm" />}
                            </Button>
                        </Modal.Footer>
                    </>
                )}
            </Formik>
        </Modal>)
}
export default EstatePlanningUploader;