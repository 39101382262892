import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { get, remove } from '../../api/index'
import { GET_ALL_OPERATING_BUSINESS_YEAR, GET_OPERATING_BUSINESS, OPERATING_BUSINESS_DELETE } from '../../api/endpoints';
import DynamicTab from '../../components/DynamicTab';
import 'react-toastify/dist/ReactToastify.css'
import { HomeNavigate, concatUrlWithId, notify, refreshCount } from '../../utils/commonUtils';
import { PageLoader } from '../../Context/PageLoader';
import {  ViewOnlyFormField } from '../../components/Forms/FormFields';
import OperatingBusinessYearModel from './OperatingBusinessYearModel';
import OperatingBusinessModel from './OperatingBusinessModel';
import { confirmAlert } from 'react-confirm-alert';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function OperatingBusinessView() {

    const location = useLocation();
    const { loading, setLoading } = useContext(PageLoader);
    const { businessID } = useParams();
    const [initialValues, setInitialValues] = useState({ business_name: '', primary_product_service: '', operating_business_id: '' });
    const [yearsData, setYearsData] = useState([])
    const history = useNavigate()
    const [previousPage, setPreviousPage] = useState('Operating Business');
    const [yearArr, setYearArr] = useState([]);

    const fetchOperatingBusiness = async () => {
        try {
            const response = await get(concatUrlWithId(GET_OPERATING_BUSINESS, businessID));
            setLoading(true);
            if (response) {
                let values = {
                    primary_product_service: response?.primary_product_service,
                    business_name: response?.business_name,
                };
                setInitialValues(values);
                await fetchOperatingBusinessYears()
            }
            refreshCount();
        } catch (error) {
            console.log('Error while fetching user information:', error,error?.response?.status);
            notify(error?.response?.data?.message, 'error'); 
             HomeNavigate(history, error?.response);
        } finally {
            setLoading(false);
        }
    };

    const fetchOperatingBusinessYears = async () => {
        try {
            const response = await get(concatUrlWithId(GET_ALL_OPERATING_BUSINESS_YEAR, businessID));
            setYearsData(response)
        } catch (error) {
            console.log('Error in the fetchOperatingBusinessYears function', error)
        }
    }

    useEffect(() => {
        fetchOperatingBusiness();
         history(location.pathname, { replace: true, state: null });
         setShow(location.state?.isOpenModel || false)
    }, [])

    /* Upload Modal  */
    const [show, setShow] = useState(false);
    const [mode, setMode] = useState('add');
    const [showBusiness, setShowBusiness] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (type = 'add') => {
        let tempYearArr = [];
        yearsData.forEach(obj => {
            tempYearArr.push(obj['year'])
        });
        setYearArr(tempYearArr)
        setShow(true);
        setMode(type)
    }

    const handleShowBusiness = (type = 'add') => {
        setShowBusiness(true);
        setMode(type)
    }
    const handleCloseBusiness = () => {
        setShowBusiness(false)
    };

    useEffect(() => {
        setLoading(false);

        if (mode != "edit" && show) {
            // setInitialValues({
            //     business_name: '',
            //     primary_product_service: '',
            //     operating_business_id: '',
            // })
        }
    }, [show, mode]);



    const handleChange = () => {
        fetchOperatingBusinessYears();
        setShow(false);
        setMode('')
    };

    const handleBackClick = () => {
        history('/operating-business');
    };

    function deleteBusiness(id, Name) {
        confirmAlert({
            title: 'Delete document',
            message: `Are you sure you want to delete the Operating Business ${Name} ?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleDelete(id, Name)
                },
                {
                    label: 'No',
                    // onClick: () => alert('Click No')
                }
            ]
        });
    }

    const handleDelete = async (id, name) => {
        try {
            const deleteInvestment = await remove(concatUrlWithId(OPERATING_BUSINESS_DELETE, id));
            if (deleteInvestment) {
                // history('/stock-list')
            }
            handleBackClick();
            notify(`Operating Business successfully deleted`, 'success')
        } catch (error) {
            notify(`Unable to delete ${name}`, 'error')
            console.log('Error while deleting the error : ', error)
        }

    };
    const handleBusinessEdit = (id) => { 
        handleShowBusiness('edit');
    };

    return (
        <>
            <div className="container-fluid" style={{ marginTop: '20px' }}>
                <div className="col-12" style={{ padding: '5px 15px 0px 15px' }}>
                    <div className="card">
                        <div className="card-body card-breadcrumb">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <a href="#" onClick={() => handleBackClick()} className="btn btn-light mb-2 me-2" style={{ display: 'flex', alignItems: 'center' }}><i className="arrow_left"></i>Back</a>
                                    <legend className='text-bold'>Operating Business View</legend>
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="#" onClick={() => handleBackClick()}>{previousPage}</a></li>
                                        <li className="breadcrumb-item active" >{'Operating Business view'}</li>
                                    </ol>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>

            <div className="main-content introduction-farm" style={{paddingTop:'0px'}}>
                <div className="content-wraper-area">
                    <div className="dashboard-area">
                        <div className="container-fluid">
                            <br></br>
                            <div className='card'>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-6'>
                                            {/* <legend className='text-bold'>Operating Business View</legend> */}
                                        </div>
                                        <div className='col-6'>
                                            <div style={{ textAlign: 'center' }}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    {/* {(checkPermission(userPermissions, 'edit-stock') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) &&  */}
                                                    <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Edit Year</Tooltip>} >
                                                        <i className="bx bxs-edit btn btn-circle" onClick={() => handleBusinessEdit(businessID)} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: '#58ae25', cursor: 'pointer' }} ></i>
                                                    </OverlayTrigger>
                                                    {/* } */}
                                                    {/* {(checkPermission(userPermissions, 'delete-stock') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) &&  */}
                                                    <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Delete Year</Tooltip>} >
                                                        <i className="bx bx-trash btn btn-circle" onClick={() => deleteBusiness(businessID, initialValues?.business_name)} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: '#dd4a57', cursor: 'pointer' }}></i>
                                                    </OverlayTrigger>
                                                    {/* } */}
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                    <div className="col-lg-12">
                                        <fieldset>
                                            <legend className='text-bold'> Business Details </legend>
                                            <div className='row'>
                                                <div className='col-lg-6'>
                                                    <ViewOnlyFormField fieldName="business_name" label="Business Name" value={initialValues.business_name} />
                                                </div>
                                                <div className='col-lg-6'>
                                                    <ViewOnlyFormField fieldName="primary_product_service" label="Primary Product Service" value={initialValues.primary_product_service} />
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <OperatingBusinessYearModel show={show} mode={mode} businessID={businessID} handleClose={handleClose} handleChange={handleChange} yearArr={yearArr}/>
                                 
                                    <OperatingBusinessModel show={showBusiness} mode={mode} handleClose={handleCloseBusiness} businessID={businessID} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '20px', paddingTop:'10px' }}>
                            <button type='button' onClick={handleShow} className='btn btn-primary'> <i className="icon_plus"></i> Add Year</button>
                        </div>
                    </div>
                </div>
               
                <div className="card-body">
                    <DynamicTab businessID={businessID} data={yearsData} tabKey={'year'} />
                </div>
            </div>
        </>
    );
}

export default OperatingBusinessView;